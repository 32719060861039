import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { loadStripe } from '@stripe/stripe-js';
import Navigation from '../components/Navigation';
import { PageContainer, Container, Header, Button } from '../components/SharedStyles';

import EntryFeeManagement from '../components/EntryFeeManagement';
import PrizePoolManagement from '../components/PrizePoolManagement';
import PayoutDistribution from '../components/PayoutDistribution';
import FinancialDashboard from '../components/FinancialDashboard';

const stripePromise = loadStripe('your-publishable-key');

const StyledTabs = styled(Tabs)`
  .react-tabs__tab-list {
    border-bottom: 1px solid ${props => props.theme.border};
    margin: 0 0 10px;
    padding: 0;
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    color: ${props => props.theme.text};
  }

  .react-tabs__tab--selected {
    background: ${props => props.theme.background};
    border-color: ${props => props.theme.border};
    color: ${props => props.theme.primary};
    border-radius: 5px 5px 0 0;
  }
`;

const TabContent = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 0 0 5px 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

function FinancialsAndPayoutManagement() {
  const { theme } = useTheme();
  const [financialData, setFinancialData] = useState({
    totalFees: 0,
    totalPrizePool: 0,
    payouts: [],
  });

  useEffect(() => {
    // Fetch initial financial data
    // This is a placeholder and should be replaced with actual API calls
    setFinancialData({
      totalFees: 10000,
      totalPrizePool: 8000,
      payouts: [
        { driver: 'Driver A', amount: 4000 },
        { driver: 'Driver B', amount: 2400 },
        { driver: 'Driver C', amount: 1600 },
      ],
    });
  }, []);

  const handlePaymentIntent = async (amount) => {
    const stripe = await stripePromise;
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });
    const { clientSecret } = await response.json();
    const result = await stripe.confirmCardPayment(clientSecret);
    if (result.error) {
      console.error(result.error.message);
    } else {
      console.log('Payment succeeded');
      // Update financial data after successful payment
    }
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Financials and Payout Management</Header>
        <StyledTabs theme={theme}>
          <TabList>
            <Tab>Entry Fee Management</Tab>
            <Tab>Prize Pool Management</Tab>
            <Tab>Payout Distribution</Tab>
            <Tab>Financial Dashboard</Tab>
          </TabList>

          <TabPanel>
            <TabContent theme={theme}>
              <EntryFeeManagement onPaymentIntent={handlePaymentIntent} theme={theme} />
            </TabContent>
          </TabPanel>
          <TabPanel>
            <TabContent theme={theme}>
              <PrizePoolManagement
                totalPrizePool={financialData.totalPrizePool}
                onUpdatePrizePool={(newTotal) => setFinancialData({ ...financialData, totalPrizePool: newTotal })}
                theme={theme}
              />
            </TabContent>
          </TabPanel>
          <TabPanel>
            <TabContent theme={theme}>
              <PayoutDistribution
                payouts={financialData.payouts}
                onUpdatePayouts={(newPayouts) => setFinancialData({ ...financialData, payouts: newPayouts })}
                theme={theme}
              />
            </TabContent>
          </TabPanel>
          <TabPanel>
            <TabContent theme={theme}>
              <FinancialDashboard data={financialData} theme={theme} />
            </TabContent>
          </TabPanel>
        </StyledTabs>
      </Container>
    </PageContainer>
  );
}

export default FinancialsAndPayoutManagement;