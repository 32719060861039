import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore } from '../firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import styled from 'styled-components';

const ProfileContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 1rem auto;
    padding: 1.5rem;
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${props => props.theme.text};
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.disabledBackground};
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1rem;
  background-color: ${props => props.theme.buttonBackground};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }

  &:active {
    transform: translateY(1px);
  }
`;

const Message = styled.p`
  color: ${props => props.error ? props.theme.error : props.theme.success};
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
`;

const Section = styled.section`
  margin-top: 2rem;
`;

const SectionTitle = styled.h3`
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

function UserProfile() {
  console.log("UserProfile component rendered");
  const { currentUser } = useAuth();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const db = await getFirestore();
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setDisplayName(userData.displayName || '');
            setEmail(userData.email || currentUser.email);
            setProfileData(userData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load profile data. Please check your internet connection and try again.');
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const db = await getFirestore();
      await updateDoc(doc(db, 'users', currentUser.uid), {
        displayName,
        email
      });
      setMessage('Profile updated successfully!');
    } catch (err) {
      setError('Failed to update profile. Please try again.');
      console.error(err);
    }
  };

  if (!currentUser) {
    return <ProfileContainer>Please log in to view your profile.</ProfileContainer>;
  }

  return (
    <ProfileContainer>
      <Title>Event Holder/Track Owner Profile</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="displayName">Display Name</Label>
          <Input
            id="displayName"
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter your display name"
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email address"
            disabled
          />
        </InputGroup>
        <Button type="submit">Update Profile</Button>
      </Form>
      {message && <Message>{message}</Message>}
      {error && <Message error>{error}</Message>}

      <Section>
        <SectionTitle>Profile Overview</SectionTitle>
        <p>Name: {profileData.displayName}</p>
        <p>Track Name: {profileData.trackName}</p>
        <p>Location: {profileData.location}</p>
        <p>Contact: {profileData.phone}</p>
      </Section>

      <Section>
        <SectionTitle>Event Overview</SectionTitle>
        <h4>Active Events</h4>
        <List>
          {profileData.activeEvents?.map((event, index) => (
            <ListItem key={index}>{event.name} - <a href={`/manage-event/${event.id}`}>Manage</a></ListItem>
          ))}
        </List>
        <h4>Past Events</h4>
        <List>
          {profileData.pastEvents?.map((event, index) => (
            <ListItem key={index}>{event.name} - <a href={`/event-stats/${event.id}`}>View Stats</a></ListItem>
          ))}
        </List>
      </Section>

      <Section>
        <SectionTitle>Track Information</SectionTitle>
        <p>Track Type: {profileData.trackType}</p>
        <p>Surface Type: {profileData.surfaceType}</p>
        <h4>Track Prep Logs</h4>
        <List>
          {profileData.trackPrepLogs?.map((log, index) => (
            <ListItem key={index}>{log.date}: {log.description}</ListItem>
          ))}
        </List>
      </Section>

      <Section>
        <SectionTitle>Performance Stats</SectionTitle>
        <p>Total Participants: {profileData.totalParticipants}</p>
        <p>Total Spectators: {profileData.totalSpectators}</p>
        <p>Total Revenue: ${profileData.totalRevenue}</p>
      </Section>

      <Section>
        <SectionTitle>Team and Racer Overview</SectionTitle>
        <List>
          {profileData.teams?.map((team, index) => (
            <ListItem key={index}>
              {team.name} - <a href={`/message/${team.id}`}>Message</a>
            </ListItem>
          ))}
        </List>
      </Section>
    </ProfileContainer>
  );
}

export default UserProfile;
