import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue, off } from "firebase/database";
import { database } from '../firebase';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import BracketVisualization from './BracketVisualization';

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Tab = styled(Button)`
  margin: 0 0.5rem;
`;

const ContentContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const SharedBracketView = () => {
  const { eventId, shareId } = useParams();
  const [sharedData, setSharedData] = useState(null);
  const [error, setError] = useState(null);
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState('qualifying');
  const [loading, setLoading] = useState(true);
  const [eliminationsData, setEliminationsData] = useState(null);

  useEffect(() => {
    const sharedDataRef = ref(database, `sharedBracket/${eventId}/${shareId}`);
    const eliminationsRef = ref(database, `events/${eventId}/eliminations`);

    const handleSharedDataChange = (snapshot) => {
      setLoading(false);
      const data = snapshot.val();
      if (data) {
        console.log('Received shared data:', data);
        setSharedData(data);
        setError(null);
      } else {
        console.log('No data found for this link');
        setError(`No shared data found for eventId: ${eventId}, shareId: ${shareId}`);
        setSharedData(null);
      }
    };

    const handleEliminationsDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log('Received eliminations data:', data);
        setEliminationsData(data);
      }
    };

    onValue(sharedDataRef, handleSharedDataChange);
    onValue(eliminationsRef, handleEliminationsDataChange);

    return () => {
      off(sharedDataRef);
      off(eliminationsRef);
    };
  }, [eventId, shareId]);

  if (loading) {
    return (
      <PageContainer theme={theme}>
        <Navigation />
        <Container>
          <Header>Loading...</Header>
        </Container>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer theme={theme}>
        <Navigation />
        <Container>
          <Header>Error</Header>
          <p>{error}</p>
        </Container>
      </PageContainer>
    );
  }

  const renderQualifying = () => {
    if (!sharedData || !sharedData.qualifying) {
      return <p>Loading qualifying data...</p>;
    }

    const { qualifyingResults, reactionTimes, topSpeeds } = sharedData.qualifying;

    return (
      <ContentContainer theme={theme}>
        <h3>Qualifying Results</h3>
        <table>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Best ET</th>
              <th>Reaction Time</th>
              <th>Top Speed</th>
            </tr>
          </thead>
          <tbody>
            {qualifyingResults.map((racer, index) => (
              <tr key={racer.id}>
                <td>{index + 1}</td>
                <td>{racer.name}</td>
                <td>{typeof racer.bestTime === 'number' ? racer.bestTime.toFixed(3) : '-'}</td>
                <td>{reactionTimes && reactionTimes[racer.id] ? reactionTimes[racer.id] : '-'}</td>
                <td>{topSpeeds && topSpeeds[racer.id] ? topSpeeds[racer.id] : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentContainer>
    );
  };

  const renderResults = () => (
    <ContentContainer theme={theme}>
      <h3>Event Results</h3>
      {sharedData.results && Object.keys(sharedData.results).length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Best ET</th>
              <th>Reaction Time</th>
              <th>Top Speed</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(sharedData.results).map(([position, racer], index) => (
              <tr key={racer.id || index}>
                <td>{position}</td>
                <td>{racer.name}</td>
                <td>{racer.bestTime?.toFixed(3) || '-'}</td>
                <td>{racer.reactionTime?.toFixed(3) || '-'}</td>
                <td>{racer.topSpeed || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No results available yet.</p>
      )}
    </ContentContainer>
  );

  const renderLiveTiming = () => (
    <ContentContainer theme={theme}>
      <h3>Live Timing</h3>
      {/* Display live timing information */}
      {/* This will depend on how you're storing live timing data */}
    </ContentContainer>
  );

  const renderEliminations = () => {
    if (!eliminationsData) {
      return <p>Loading eliminations data...</p>;
    }

    return (
      <ContentContainer theme={theme}>
        <BracketVisualization
          eventId={eventId}
          qualifyingResults={sharedData.qualifying.qualifyingResults}
          eventDate={sharedData.eventDate}
          carField={sharedData.carField}
          roundNumber={eliminationsData.roundNumber}
          topSpeeds={sharedData.qualifying.topSpeeds}
          initialBracket={eliminationsData.bracket}
          isViewOnly={true}
          isDoubleElimination={sharedData.isDoubleElimination}
          showDoubleElimination={sharedData.showDoubleElimination}
          losersBracket={eliminationsData.losersBracket}
        />
      </ContentContainer>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'qualifying':
        return renderQualifying();
      case 'eliminations':
        return renderEliminations();
      case 'results':
        return renderResults();
      case 'liveTiming':
        return renderLiveTiming();
      default:
        return null;
    }
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>{sharedData.eventName || 'Bracket View'}</Header>
        <TabContainer>
          <Tab onClick={() => setActiveTab('qualifying')} theme={theme}>Qualifying</Tab>
          <Tab onClick={() => setActiveTab('eliminations')} theme={theme}>Eliminations</Tab>
          <Tab onClick={() => setActiveTab('results')} theme={theme}>Results</Tab>
          <Tab onClick={() => setActiveTab('liveTiming')} theme={theme}>Live Timing</Tab>
        </TabContainer>
        {renderContent()}
      </Container>
    </PageContainer>
  );
};

export default SharedBracketView;
