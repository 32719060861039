import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import Navigation from './Navigation';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import { FaUsers, FaList, FaDice, FaFlag, FaPlus, FaTrash, FaEdit, FaUsers as FaTeam, FaCar, FaCloudSun, FaHandshake, FaMoneyBillWave, FaCalendarAlt } from 'react-icons/fa';

const CenteredContainer = styled(Container)`
  text-align: center;
`;

const CenteredHeader = styled(Header)`
  text-align: center;
`;

const EventGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  justify-items: center;
`;

const EventCard = styled.div`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`;

const EventName = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;
`;

const EventDate = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const EventType = styled.span`
  font-size: 0.8rem;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.mode === 'dark' ? 'white' : 'black'};
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
`;

const TeamBasedInfo = styled.p`
  font-size: 0.8rem;
  color: ${props => props.theme.textSecondary};
  margin-top: 0.5rem;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: auto;
  width: 100%;
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const AddEventButton = styled(Button)`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const DeleteEventButton = styled(Button)`
  background-color: ${props => props.theme.danger};
  color: white;
  margin-top: 1rem; // Add this line to create more space
  width: 100%; // Make the button full width
  &:hover {
    background-color: ${props => props.theme.dangerHover};
  }
`;

const EventNameInput = styled.input`
  width: calc(100% - 2rem); // Subtracting padding
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.mode === 'dark' ? 'black' : props.theme.text};

  &::placeholder {
    color: ${props => props.theme.textSecondary};
  }
`;

const ActionButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const EditEventButton = styled(Button)`
  background-color: ${props => props.theme.secondary};
  color: white;
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.secondaryHover};
  }
`;

const EventTypeSelect = styled.select`
  width: calc(100% - 2rem); // Subtracting padding
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.mode === 'dark' ? 'black' : props.theme.text};

  option {
    background-color: ${props => props.theme.inputBackground};
    color: ${props => props.theme.mode === 'dark' ? 'black' : props.theme.text};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.text};
`;

const ToggleSwitch = styled.input`
  margin-left: 0.5rem;
`;

const EditEventModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.background};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const ModalButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ModalButton = styled(Button)`
  width: 48%;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const EventCreationSection = styled.div`
  max-width: 400px;
  margin: 0 auto 2rem;
  padding: 1.5rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.border};
`;

const EventCreationTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: ${props => props.theme.text};
`;

function EventManagement() {
  const [events, setEvents] = useState([]);
  const [newEventName, setNewEventName] = useState('');
  const [newEventType, setNewEventType] = useState('No Prep');
  const [isTeamBased, setIsTeamBased] = useState(false);
  const { theme } = useTheme();
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [editingEvent, setEditingEvent] = useState(null);

  useEffect(() => {
    const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
    setEvents(storedEvents);
  }, []);

  const addEvent = () => {
    const newEvent = {
      id: Date.now(),
      name: newEventName || `New Event ${events.length + 1}`,
      date: new Date().toISOString().split('T')[0],
      type: newEventType,
      isTeamBased,
    };
    const updatedEvents = [...events, newEvent];
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
    setNewEventName('');
    setNewEventType('No Prep');
    setIsTeamBased(false);
  };

  const editEventName = (eventId, newName) => {
    const updatedEvents = events.map(event => 
      event.id === eventId ? { ...event, name: newName } : event
    );
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
  };

  const deleteEvent = (eventId) => {
    const updatedEvents = events.filter(event => event.id !== eventId);
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
  };

  const saveChipDrawingResults = (eventId, results) => {
    localStorage.setItem(`chipDrawing_${eventId}`, JSON.stringify(results));
  };

  const editEvent = (eventId) => {
    const eventToEdit = events.find(event => event.id === eventId);
    if (eventToEdit) {
      setEditingEvent(eventToEdit);
    }
  };

  const saveEditedEvent = (editedEvent) => {
    const updatedEvents = events.map(event =>
      event.id === editedEvent.id ? editedEvent : event
    );
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));
    setEditingEvent(null);
  };

  const handleRacersClick = (event) => {
    if (event.type === "No Prep") {
      navigate(`/events/${event.id}/racers`);
    } else {
      navigate(`/bracket-builder`, { state: { eventId: event.id, initialStep: 'registration' } });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <CenteredContainer>
        <CenteredHeader>Event Management</CenteredHeader>
        {currentUser ? (
          <>
            <EventCreationSection>
              <EventCreationTitle>Create New Event</EventCreationTitle>
              <EventNameInput
                type="text"
                placeholder="Enter new event name"
                value={newEventName}
                onChange={(e) => setNewEventName(e.target.value)}
                theme={theme}
              />
              <EventTypeSelect
                value={newEventType}
                onChange={(e) => setNewEventType(e.target.value)}
                theme={theme}
              >
                <option value="No Prep">No Prep</option>
                <option value="Grudge Racing">Grudge Racing</option>
                <option value="Test & Tune">Test & Tune</option>
                <option value="Official Drag Racing">Official Drag Racing</option>
                <option value="Roll Racing">Roll Racing</option>
              </EventTypeSelect>
              <ToggleContainer>
                <ToggleLabel>
                  <FaTeam />
                  Team-based
                </ToggleLabel>
                <ToggleSwitch type="checkbox" checked={isTeamBased} onChange={(e) => setIsTeamBased(e.target.checked)} />
              </ToggleContainer>
              <AddEventButton onClick={addEvent} theme={theme}>
                <FaPlus /> Add New Event
              </AddEventButton>
            </EventCreationSection>
            <EventGrid>
              {events.map((event) => (
                <EventCard key={event.id} theme={theme}>
                  <EventName>{event.name}</EventName>
                  <EventInfo>
                    <EventDate>{new Date(event.date).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</EventDate>
                    <EventType theme={theme}>{event.type}</EventType>
                  </EventInfo>
                  <TeamBasedInfo>Team-based: {event.isTeamBased ? 'Yes' : 'No'}</TeamBasedInfo>
                  <ButtonGroup>
                    <EventButton as={Link} to={`/events/${event.id}/teams`} theme={theme}>
                      <FaCar /> Teams
                    </EventButton>
                    <EventButton as={Link} to={`/events/${event.id}/classes`} theme={theme}>
                      <FaList /> Classes
                    </EventButton>
                    {event.type === "No Prep" ? (
                      <>
                        <EventButton as={Link} to={`/events/${event.id}/racers`} theme={theme}>
                          <FaUsers /> Racers
                        </EventButton>
                        <EventButton as={Link} to={`/events/${event.id}/draw`} theme={theme}>
                          <FaDice /> Draw
                        </EventButton>
                      </>
                    ) : (
                      <>
                        <EventButton onClick={() => handleRacersClick(event)} theme={theme}>
                          <FaUsers /> Racers
                        </EventButton>
                        <EventButton as={Link} to={`/bracket-builder?eventId=${event.id}#qualifying`} theme={theme}>
                          <FaDice /> Qualify
                        </EventButton>
                      </>
                    )}
                    <EventButton as={Link} to={`/events/${event.id}/schedule`} theme={theme}>
                      <FaCalendarAlt /> Schedule
                    </EventButton>
                    <EventButton as={Link} to={`/events/${event.id}/sponsors-vendors`} theme={theme}>
                      <FaHandshake /> Sponsors
                    </EventButton>
                    <EventButton as={Link} to={`/events/${event.id}/financials`} theme={theme}>
                      <FaMoneyBillWave /> Finance
                    </EventButton>
                    <EventButton as={Link} to={`/weather-track-conditions`} theme={theme}>
                      <FaCloudSun /> Weather
                    </EventButton>
                    <EventButton as={Link} to={`/events/${event.id}/results`} theme={theme}>
                      <FaFlag /> Results
                    </EventButton>
                    <EventButton onClick={() => editEvent(event.id)} theme={theme}>
                      <FaEdit /> Edit
                    </EventButton>
                  </ButtonGroup>
                  <ActionButtonGroup>
                    <DeleteEventButton onClick={() => deleteEvent(event.id)} theme={theme}>
                      <FaTrash /> Delete Event
                    </DeleteEventButton>
                  </ActionButtonGroup>
                </EventCard>
              ))}
            </EventGrid>
          </>
        ) : (
          <div>
            <p>Please log in to view and manage events.</p>
            <Button onClick={() => navigate('/login')} theme={theme}>
              Log In
            </Button>
          </div>
        )}
      </CenteredContainer>
      {editingEvent && (
        <>
          <Overlay onClick={() => setEditingEvent(null)} />
          <EditEventModal theme={theme}>
            <h2>Edit Event</h2>
            <EventNameInput
              type="text"
              value={editingEvent.name}
              onChange={(e) => setEditingEvent({...editingEvent, name: e.target.value})}
              theme={theme}
            />
            <EventTypeSelect
              value={editingEvent.type}
              onChange={(e) => setEditingEvent({...editingEvent, type: e.target.value})}
              theme={theme}
            >
              <option value="No Prep">No Prep</option>
              <option value="Grudge Racing">Grudge Racing</option>
              <option value="Test & Tune">Test & Tune</option>
              <option value="Official Drag Racing">Official Drag Racing</option>
            </EventTypeSelect>
            <ToggleContainer>
              <ToggleLabel>
                <FaTeam />
                Team-based
              </ToggleLabel>
              <ToggleSwitch
                type="checkbox"
                checked={editingEvent.isTeamBased}
                onChange={(e) => setEditingEvent({...editingEvent, isTeamBased: e.target.checked})}
              />
            </ToggleContainer>
            <ModalButtonGroup>
              <ModalButton onClick={() => saveEditedEvent(editingEvent)} theme={theme}>
                Save Changes
              </ModalButton>
              <ModalButton onClick={() => setEditingEvent(null)} theme={theme}>
                Cancel
              </ModalButton>
            </ModalButtonGroup>
          </EditEventModal>
        </>
      )}
    </PageContainer>
  );
}

export default EventManagement;
