import React from 'react';
import styled from 'styled-components';
import { FaDollarSign, FaTrophy, FaUserFriends, FaChartLine, FaMoneyBillWave } from 'react-icons/fa';
import { Button } from './SharedStyles';

const DashboardContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DashboardHeader = styled.h2`
  font-size: 1.8rem;
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const OverviewItem = styled.div`
  background-color: ${props => props.theme.surface};
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const OverviewLabel = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 0.5rem;
`;

const OverviewValue = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${props => props.theme.text};
`;

const SectionHeader = styled.h3`
  font-size: 1.4rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PayoutList = styled.ul`
  list-style-type: none;
  padding: 0;
  background-color: ${props => props.theme.surface};
  border-radius: 10px;
  overflow: hidden;
`;

const PayoutItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${props => props.theme.border};
  &:last-child {
    border-bottom: none;
  }
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.onPrimary};
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
`;

function FinancialDashboard({ data, theme }) {
  return (
    <DashboardContainer theme={theme}>
      <DashboardHeader>
        <FaChartLine /> Financial Dashboard
      </DashboardHeader>
      <OverviewGrid>
        <OverviewItem theme={theme}>
          <OverviewLabel>Total Entry Fees</OverviewLabel>
          <OverviewValue>${data.totalFees.toLocaleString()} USD</OverviewValue>
        </OverviewItem>
        <OverviewItem theme={theme}>
          <OverviewLabel>Total Prize Pool</OverviewLabel>
          <OverviewValue>${data.totalPrizePool.toLocaleString()} USD</OverviewValue>
        </OverviewItem>
        <OverviewItem theme={theme}>
          <OverviewLabel>Number of Participants</OverviewLabel>
          <OverviewValue>{data.participantCount}</OverviewValue>
        </OverviewItem>
        <OverviewItem theme={theme}>
          <OverviewLabel>Average Entry Fee</OverviewLabel>
          <OverviewValue>${(data.totalFees / data.participantCount).toFixed(2)} USD</OverviewValue>
        </OverviewItem>
      </OverviewGrid>
      <SectionHeader>
        <FaTrophy /> Payout Breakdown
      </SectionHeader>
      <PayoutList theme={theme}>
        {data.payouts.map((payout, index) => (
          <PayoutItem key={index} theme={theme}>
            <span>{payout.driver}</span>
            <span>${payout.amount.toLocaleString()} USD</span>
          </PayoutItem>
        ))}
      </PayoutList>
      <ButtonGroup>
        <EventButton theme={theme}>
          <FaMoneyBillWave /> Manage Entry Fees
        </EventButton>
        <EventButton theme={theme}>
          <FaDollarSign /> Adjust Prize Pool
        </EventButton>
        <EventButton theme={theme}>
          <FaUserFriends /> View Participant List
        </EventButton>
        <EventButton theme={theme}>
          <FaTrophy /> Finalize Payouts
        </EventButton>
      </ButtonGroup>
    </DashboardContainer>
  );
}

export default FinancialDashboard;