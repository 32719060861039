import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const carMakes = [
  "Acura", "Alfa Romeo", "Aston Martin", "Audi", "Bentley", "BMW", "Bugatti",
  "Buick", "Cadillac", "Chevrolet", "Chrysler", "Dodge", "Ferrari", "Fiat",
  "Ford", "Genesis", "GMC", "Honda", "Hyundai", "Infiniti", "Jaguar", "Jeep",
  "Kia", "Lamborghini", "Land Rover", "Lexus", "Lincoln", "Maserati", "Mazda",
  "McLaren", "Mercedes-Benz", "Mini", "Mitsubishi", "Nissan", "Porsche", "Ram",
  "Rolls-Royce", "Subaru", "Tesla", "Toyota", "Volkswagen", "Volvo"
];

const carModels = {
  "Acura": ["ILX", "Integra", "MDX", "RDX", "RLX", "TLX", "NSX"],
  "Alfa Romeo": ["Giulia", "Stelvio", "4C"],
  "Aston Martin": ["DB11", "DBS", "Vantage", "DBX"],
  "Audi": ["A3", "A4", "A5", "A6", "A7", "A8", "Q3", "Q5", "Q7", "Q8", "R8", "TT"],
  "Bentley": ["Bentayga", "Continental GT", "Flying Spur"],
  "BMW": ["1 Series", "2 Series", "3 Series", "4 Series", "5 Series", "6 Series", "7 Series", "8 Series", "X1", "X2", "X3", "X4", "X5", "X6", "X7", "Z4", "i3", "i8"],
  "Bugatti": ["Chiron", "Veyron"],
  "Buick": ["Enclave", "Encore", "Envision", "LaCrosse", "Regal"],
  "Cadillac": ["CT4", "CT5", "Escalade", "XT4", "XT5", "XT6"],
  "Chevrolet": ["Bolt", "Camaro", "Colorado", "Corvette", "Equinox", "Malibu", "Silverado", "Suburban", "Tahoe", "Traverse"],
  "Chrysler": ["300", "Pacifica"],
  "Dodge": ["Challenger", "Charger", "Durango", "Grand Caravan", "Journey"],
  "Ferrari": ["488", "F8 Tributo", "Portofino", "Roma", "SF90 Stradale"],
  "Fiat": ["500", "500X"],
  "Ford": ["EcoSport", "Edge", "Escape", "Expedition", "Explorer", "F-150", "Mustang", "Ranger"],
  "Genesis": ["G70", "G80", "G90", "GV80"],
  "GMC": ["Acadia", "Canyon", "Sierra", "Terrain", "Yukon"],
  "Honda": [
    "Accord", "Civic", "CR-V", "HR-V", "Odyssey", "Pilot", "Ridgeline",
    "CRX", "Fit", "Insight", "Element", "S2000", "Prelude", "Passport",
    "Clarity", "CR-Z", "del Sol", "Crosstour", "Integra", "Legend",
    "NSX", "Vigor", "City", "Jazz", "Shuttle", "Stream", "Acty", "Beat",
    "Logo", "That's", "Zest", "Life", "Mobilio", "Freed", "Elysion",
    "Jade", "Avancier", "Vezel", "N-Box", "N-One", "S660", "e"
  ],
  "Hyundai": ["Accent", "Elantra", "Kona", "Palisade", "Santa Fe", "Sonata", "Tucson", "Veloster"],
  "Infiniti": ["Q50", "Q60", "QX50", "QX60", "QX80"],
  "Jaguar": ["E-PACE", "F-PACE", "F-TYPE", "I-PACE", "XE", "XF"],
  "Jeep": ["Cherokee", "Compass", "Gladiator", "Grand Cherokee", "Renegade", "Wrangler"],
  "Kia": ["Forte", "K5", "Niro", "Optima", "Seltos", "Sorento", "Soul", "Sportage", "Stinger", "Telluride"],
  "Lamborghini": ["Aventador", "Huracan", "Urus"],
  "Land Rover": ["Defender", "Discovery", "Range Rover", "Range Rover Evoque", "Range Rover Sport", "Range Rover Velar"],
  "Lexus": ["ES", "GS", "GX", "IS", "LC", "LS", "LX", "NX", "RC", "RX", "UX"],
  "Lincoln": ["Aviator", "Corsair", "Nautilus", "Navigator"],
  "Maserati": ["Ghibli", "Levante", "Quattroporte"],
  "Mazda": ["CX-3", "CX-30", "CX-5", "CX-9", "Mazda3", "Mazda6", "MX-5 Miata"],
  "McLaren": ["570S", "720S", "GT"],
  "Mercedes-Benz": ["A-Class", "C-Class", "E-Class", "S-Class", "GLA", "GLB", "GLC", "GLE", "GLS", "G-Class"],
  "Mini": ["Clubman", "Countryman", "Hardtop"],
  "Mitsubishi": ["Eclipse Cross", "Mirage", "Outlander", "Outlander Sport"],
  "Nissan": ["Altima", "Armada", "Frontier", "GT-R", "Kicks", "Leaf", "Maxima", "Murano", "Pathfinder", "Rogue", "Sentra", "Titan", "Versa"],
  "Porsche": ["911", "Boxster", "Cayenne", "Macan", "Panamera", "Taycan"],
  "Ram": ["1500", "2500", "3500", "ProMaster"],
  "Rolls-Royce": ["Cullinan", "Dawn", "Ghost", "Phantom", "Wraith"],
  "Subaru": ["Ascent", "Crosstrek", "Forester", "Impreza", "Legacy", "Outback", "WRX"],
  "Tesla": ["Model 3", "Model S", "Model X", "Model Y"],
  "Toyota": [
    "4Runner", "Avalon", "Camry", "Corolla", "Highlander", "Prius", "RAV4",
    "Sienna", "Tacoma", "Tundra", "Supra", "Celica", "MR2", "Land Cruiser",
    "Sequoia", "Yaris", "Matrix", "Venza", "C-HR", "86", "GR86", "Mirai",
    "Cressida", "Tercel", "Paseo", "Echo", "FJ Cruiser", "Venza", "iQ",
    "Previa", "T100", "Solara", "Crown", "Century", "Carina", "Chaser",
    "Hilux", "Fortuner", "Innova", "Alphard", "Vellfire", "Avanza",
    "Raize", "Agya", "Etios", "Glanza", "Urban Cruiser", "Probox", "Succeed",
    "Aqua", "Harrier", "Noah", "Voxy", "Esquire", "Wish", "Allion", "Premio",
    "Mark X", "Belta", "Porte", "Spade", "Roomy", "Tank", "Pixis"
  ],
  "Volkswagen": ["Atlas", "Golf", "Jetta", "Passat", "Tiguan", "Beetle", "Bug", "New Beetle", "ID.4", "Arteon", "Touareg", "CC", "Eos", "Routan", "Taos", "GTI", "e-Golf"],
  "Volvo": ["S60", "S90", "V60", "V90", "XC40", "XC60", "XC90"]
};

const currentYear = new Date().getFullYear();
const years = Array.from({length: 50}, (_, i) => currentYear - i);

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.mode === 'dark' ? '#000' : props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.primary}33;
  }

  option {
    background-color: ${props => props.theme.mode === 'dark' ? '#fff' : props.theme.inputBackground};
    color: #000;
  }
`;

function CarSelector({ onSelect, initialMake = '', initialModel = '', initialYear = '' }) {
  const { theme } = useTheme();
  const [selectedMake, setSelectedMake] = useState(initialMake);
  const [selectedModel, setSelectedModel] = useState(initialModel);
  const [selectedYear, setSelectedYear] = useState(initialYear);

  useEffect(() => {
    setSelectedMake(initialMake);
    setSelectedModel(initialModel);
    setSelectedYear(initialYear);
  }, [initialMake, initialModel, initialYear]);

  const handleMakeChange = (e) => {
    const make = e.target.value;
    setSelectedMake(make);
    setSelectedModel('');
    onSelect(`${selectedYear} ${make}`);
  };

  const handleModelChange = (e) => {
    const model = e.target.value;
    setSelectedModel(model);
    onSelect(`${selectedYear} ${selectedMake} ${model}`);
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    onSelect(`${year} ${selectedMake} ${selectedModel}`);
  };

  // Add a check before accessing carModels[selectedMake]
  const modelOptions = selectedMake && carModels[selectedMake] 
    ? carModels[selectedMake].map(model => (
        <option key={model} value={model}>{model}</option>
      ))
    : null;

  return (
    <FormGrid>
      <StyledSelect
        value={selectedMake}
        onChange={handleMakeChange}
        required
      >
        <option value="">Select Make</option>
        {carMakes.map((make) => (
          <option key={make} value={make}>{make}</option>
        ))}
      </StyledSelect>
      <StyledSelect
        value={selectedModel}
        onChange={handleModelChange}
        required
      >
        <option value="">Select Model</option>
        {modelOptions}
      </StyledSelect>
      <StyledSelect
        value={selectedYear}
        onChange={handleYearChange}
        required
      >
        <option value="">Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </StyledSelect>
    </FormGrid>
  );
}

export default CarSelector;
