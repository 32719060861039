import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext'; // Add this import
import CreateSchedule from '../components/CreateSchedule';
import ViewSchedule from '../components/ViewSchedule';
import EditSchedule from '../components/EditSchedule'; // Add this import
import { PageContainer, Container, Header, Button } from '../components/SharedStyles';
import { FaPlus, FaList } from 'react-icons/fa';
import axios from 'axios'; // Add this import

const CenteredContainer = styled(Container)`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Tab = styled(Button)`
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
  background-color: ${props => props.active ? props.theme.primary : 'transparent'};
  color: ${props => props.active ? props.theme.buttonText : props.theme.text};
  border: 2px solid ${props => props.theme.primary};
  width: 200px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.active ? props.theme.primaryHover : props.theme.primaryLight};
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

const ContentContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

function ScheduleManagement() {
  const [activeTab, setActiveTab] = useState('view');
  const [scheduleItems, setScheduleItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const { eventId } = useParams();
  const { theme } = useTheme();
  const { currentUser } = useAuth(); // Add this line

  useEffect(() => {
    loadScheduleItems();
  }, [eventId, currentUser]);

  const loadScheduleItems = () => {
    const storedItems = JSON.parse(localStorage.getItem(`scheduleItems_${eventId}`) || '[]');
    setScheduleItems(storedItems);
  };

  const saveScheduleItems = (updatedItems) => {
    setScheduleItems(updatedItems);
    localStorage.setItem(`scheduleItems_${eventId}`, JSON.stringify(updatedItems));
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setActiveTab('edit');
  };

  const handleSave = (updatedItem) => {
    const updatedItems = scheduleItems.map(item =>
      item.id === updatedItem.id ? updatedItem : item
    );
    saveScheduleItems(updatedItems);
    setEditingItem(null);
    setActiveTab('view');
  };

  const handleDelete = (itemId) => {
    console.log('Deleting item with id:', itemId);
    const updatedItems = scheduleItems.filter(item => item.id !== itemId);
    saveScheduleItems(updatedItems);
  };

  const handleCreateSuccess = (newItem) => {
    const updatedItems = [...scheduleItems, newItem];
    saveScheduleItems(updatedItems);
    setActiveTab('view');
  };

  const handleCancel = () => {
    setActiveTab('view');
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
    setActiveTab('view');
  };

  return (
    <PageContainer theme={theme}>
      <CenteredContainer>
        <Header>Schedule Management</Header>
        <TabContainer>
          <Tab
            active={activeTab === 'view'}
            onClick={() => setActiveTab('view')}
            theme={theme}
          >
            <FaList /> View Schedule
          </Tab>
          <Tab
            active={activeTab === 'create'}
            onClick={() => setActiveTab('create')}
            theme={theme}
          >
            <FaPlus /> Create Schedule Item
          </Tab>
        </TabContainer>
        <ContentContainer theme={theme}>
          {activeTab === 'view' ? (
            <ViewSchedule 
              eventId={eventId} 
              scheduleItems={scheduleItems} 
              onEdit={handleEdit}
              onDelete={handleDelete}
              theme={theme}
            />
          ) : activeTab === 'edit' ? (
            <EditSchedule 
              item={editingItem}
              onSave={handleSave}
              onCancel={handleCancelEdit}
            />
          ) : (
            <CreateSchedule 
              eventId={eventId} 
              onCreateSuccess={handleCreateSuccess} 
              onCancel={handleCancel}
            />
          )}
        </ContentContainer>
      </CenteredContainer>
    </PageContainer>
  );
}

export default ScheduleManagement;