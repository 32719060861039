import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container } from './SharedStyles';
import { FaRocket, FaHistory, FaBalanceScale, FaUsers } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from './Navigation'; // Import the Navigation component

const PageTitle = styled(motion.h1)`
  font-size: 2.5rem;
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

const Section = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.primary};
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.75rem;
  color: ${props => props.theme.text};
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ValueCard = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.hover};
  }
`;

const ValueTitle = styled.h3`
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const PageContent = styled.div`
  padding-top: 80px; // Add padding to account for the fixed navigation bar
`;

const AboutPage = () => {
  const { theme } = useTheme();

  return (
    <>
      <Navigation /> {/* Add the Navigation component here */}
      <PageContent>
        <Container>
          <PageTitle
            theme={theme}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            About RaceGrid Master
          </PageTitle>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <SubTitle theme={theme}><FaRocket /> Our Mission</SubTitle>
            <Paragraph theme={theme}>To revolutionize no prep racing events with cutting-edge chip drawing software, empowering organizers and enhancing the thrill for racers and fans alike.</Paragraph>
          </Section>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <SubTitle theme={theme}><FaHistory /> Our Story</SubTitle>
            <Paragraph theme={theme}>RaceGrid Master was born from the adrenaline-fueled world of street racing. As passionate fans and participants, we witnessed firsthand the challenges faced by event organizers. The traditional methods of managing race brackets and chip drawings were time-consuming and prone to errors, often leading to delays and disputes. We knew there had to be a better way.</Paragraph>
            <Paragraph theme={theme}>Driven by a vision to streamline the process and elevate the racing experience, we set out to create a solution that would revolutionize how no prep events are managed. Our journey began with countless conversations with racers, organizers, and fans, understanding their pain points and wishes. This invaluable input shaped the foundation of our app, ensuring that every feature serves a real need in the racing community.</Paragraph>
          </Section>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <SubTitle theme={theme}><FaBalanceScale /> Our Values</SubTitle>
            <List>
              {[
                { icon: FaRocket, title: "Innovation", description: "We're constantly pushing the boundaries of what's possible in race management software, always seeking new ways to improve the experience for organizers, racers, and fans." },
                { icon: FaBalanceScale, title: "Integrity", description: "Fair play is at the heart of racing, and it's at the core of everything we do. Our transparent and secure chip drawing process ensures every racer gets a fair shot." },
                { icon: FaUsers, title: "Community", description: "We're more than just a software company; we're part of the racing family. We're committed to supporting and growing the no prep racing community." },
                { icon: FaRocket, title: "Excellence", description: "From our code to our customer support, we strive for excellence in every aspect of our business, ensuring a premium experience for all our users." }
              ].map((value, index) => (
                <ValueCard key={index} theme={theme} whileHover={{ scale: 1.03 }}>
                  <ValueTitle theme={theme}><value.icon /> {value.title}</ValueTitle>
                  <Paragraph theme={theme}>{value.description}</Paragraph>
                </ValueCard>
              ))}
            </List>
          </Section>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <SubTitle theme={theme}><FaUsers /> Join the Revolution</SubTitle>
            <Paragraph theme={theme}>Whether you're an event organizer looking to take your races to the next level, a racer seeking fairer competitions, or a fan wanting more engagement with your favorite sport, RaceGrid Master is here to enhance your racing experience. Join us in revolutionizing the world of no prep racing, one chip at a time.</Paragraph>
          </Section>
        </Container>
      </PageContent>
    </>
  );
};

export default AboutPage;