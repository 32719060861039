import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
`;

const FormTitle = styled.h2`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  color: #34495e;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #3498db;
  }

  &:read-only {
    background-color: #ecf0f1;
  }
`;

const Textarea = styled.textarea`
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #3498db;
  }

  &:read-only {
    background-color: #ecf0f1;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.buttonBackground};
  color: white; // This ensures the text is always white
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }
`;

const TrackConditionsForm = ({ initialConditions, onSubmit, isOrganizer }) => {
  const [conditions, setConditions] = useState(initialConditions || {});
  const { theme } = useTheme();

  const handleChange = (e) => {
    setConditions({ ...conditions, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(conditions);
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormTitle>Track Conditions</FormTitle>
      <FormGroup>
        <Label htmlFor="surfaceTreatment">Surface Treatment:</Label>
        <Input
          type="text"
          id="surfaceTreatment"
          name="surfaceTreatment"
          value={conditions.surfaceTreatment || ''}
          onChange={handleChange}
          readOnly={!isOrganizer}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="prepDate">Prep Date:</Label>
        <Input
          type="datetime-local"
          id="prepDate"
          name="prepDate"
          value={conditions.prepDate || ''}
          onChange={handleChange}
          readOnly={!isOrganizer}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="gripNotes">Grip Notes:</Label>
        <Textarea
          id="gripNotes"
          name="gripNotes"
          value={conditions.gripNotes || ''}
          onChange={handleChange}
          readOnly={!isOrganizer}
        />
      </FormGroup>
      {isOrganizer && (
        <SubmitButton type="submit" theme={theme}>
          Update Track Conditions
        </SubmitButton>
      )}
    </FormContainer>
  );
};

export default TrackConditionsForm;