import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Input, Table, TableHeader, TableRow as SharedTableRow, TableCell, Select } from './SharedStyles';
import { FaSort, FaSync, FaChartLine, FaTrash, FaSave, FaClock } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import { debounce } from 'lodash';
import { ref, set } from "firebase/database";
import { database } from '../firebase';

const QualifyingTable = styled(Table)`
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: separate;
  border-spacing: 0 8px;
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    .hide-xs {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .hide-sm {
      display: none;
    }
  }
`;

const StyledTableRow = styled(SharedTableRow)`
  background-color: ${props => props.theme.cardBackground};
`;

const StyledTableCell = styled(TableCell)`
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid ${props => props.theme.border};
  border-bottom: 1px solid ${props => props.theme.border};

  &:first-child {
    border-left: 1px solid ${props => props.theme.border};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right: 1px solid ${props => props.theme.border};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media (max-width: 768px) {
    padding: 8px 4px;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  padding: 12px;
  text-align: center;
  font-weight: bold;
  background-color: ${props => props.theme.headerBackground};
  color: ${props => props.theme.headerText};
`;

const QualifyingInput = styled(Input)`
  width: 80px;
  text-align: center;

  @media (max-width: 768px) {
    width: 60px;
    font-size: 0.8rem;
  }
`;

const TimingSystemSelect = styled(Select)`
  margin-bottom: 1rem;
  width: 200px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ImportButton = styled(Button)`
  margin-top: 0.5rem;
  
  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 1rem;
  }
`;

const SortButton = styled(Button)`
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  background-color: white;
  color: black;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const FinalizeButton = styled(Button)`
  background-color: ${props => props.theme.mode === 'dark' ? props.theme.primary : props.theme.accent};
  color: ${props => props.theme.mode === 'dark' ? props.theme.text : props.theme.background};
  &:hover {
    background-color: ${props => props.theme.mode === 'dark' ? props.theme.primaryHover : props.theme.accentHover};
  }
  &:disabled {
    background-color: ${props => props.theme.disabled};
    color: ${props => props.theme.disabledText};
    cursor: not-allowed;
  }
`;

const AnalyticsContainer = styled.div`
  margin-top: 1rem;
`;

const LeaderboardContainer = styled.div`
  margin-top: 1rem;
`;

const ManualEntryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ManualEntryInput = styled(QualifyingInput)`
  width: 100%;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const ExpandableRow = styled.tr`
  cursor: pointer;
`;

const DetailRow = styled.tr`
  display: ${props => props.isOpen ? 'table-row' : 'none'};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const StepHeader = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const QualifyingRounds = ({
  eventId,
  registeredRacers = [],
  qualifyingResults,
  setQualifyingResults,
  reactionTimes,
  setReactionTimes,
  topSpeeds,
  setTopSpeeds,
  onComplete,
  numQualifyingRounds,
  importTimesFromSystem,
  isViewOnly = false,
  onQualifyingUpdate,
  onReactionTimesUpdate,
  onTopSpeedsUpdate,
}) => {
  const { colors, theme } = useTheme();
  const [timingSystem, setTimingSystem] = useState('manual');
  const [expandedRows, setExpandedRows] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const initializeQualifyingResults = useCallback(() => {
    const initialResults = registeredRacers.map(racer => ({
      ...racer,
      qualifyingTimes: Array(numQualifyingRounds).fill(null),
      bestTime: null,
      reactionTime: null,
      topSpeed: null
    }));
    setQualifyingResults(initialResults);
    setReactionTimes({});
    setTopSpeeds({});
  }, [registeredRacers, numQualifyingRounds, setQualifyingResults, setReactionTimes, setTopSpeeds]);

  const loadQualifyingData = useCallback(() => {
    const storedData = JSON.parse(localStorage.getItem(`qualifyingData_${eventId}`) || '{}');
    if (storedData.qualifyingResults && storedData.qualifyingResults.length > 0) {
      // Merge qualifying results with registered racers to ensure all data is present
      const mergedResults = registeredRacers.map(registeredRacer => {
        const qualifyingResult = storedData.qualifyingResults.find(qr => qr.id === registeredRacer.id);
        return {
          ...registeredRacer,
          ...qualifyingResult,
          qualifyingTimes: qualifyingResult?.qualifyingTimes || Array(numQualifyingRounds).fill(null)
        };
      });
      setQualifyingResults(mergedResults);
      setReactionTimes(storedData.reactionTimes || {});
      setTopSpeeds(storedData.topSpeeds || {});
    } else {
      initializeQualifyingResults();
    }
  }, [eventId, registeredRacers, numQualifyingRounds, setQualifyingResults, setReactionTimes, setTopSpeeds, initializeQualifyingResults]);

  useEffect(() => {
    if (eventId) {
      loadQualifyingData();
    }
  }, [eventId, loadQualifyingData]);

  const recordQualifyingTime = (racerId, time, roundIndex) => {
    const updatedResults = qualifyingResults.map(racer => {
      if (racer.id === racerId) {
        const updatedTimes = [...racer.qualifyingTimes];
        updatedTimes[roundIndex] = parseFloat(time);

        // Filter out null values and calculate the best time
        const validTimes = updatedTimes.filter(t => t !== null);
        const bestTime = validTimes.length > 0 ? Math.min(...validTimes) : null;

        return {
          ...racer,
          qualifyingTimes: updatedTimes,
          bestTime: bestTime
        };
      }
      return racer;
    });
    setQualifyingResults(updatedResults);
  };

  const saveQualifyingData = useCallback(() => {
    setIsSaving(true);
    try {
      const dataToSave = {
        qualifyingResults,
        reactionTimes,
        topSpeeds: Object.fromEntries(
          Object.entries(topSpeeds).map(([id, speed]) => [id, parseFloat(speed) || 0])
        ),
      };
      localStorage.setItem(`qualifyingData_${eventId}`, JSON.stringify(dataToSave));
      setLastSaved(new Date());
    } catch (error) {
      console.error('Error saving qualifying data:', error);
      alert('Failed to save qualifying data. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }, [eventId, qualifyingResults, reactionTimes, topSpeeds]);

  const debouncedSaveQualifyingData = useCallback(debounce(saveQualifyingData, 1000), [saveQualifyingData]);

  const handleTimeSubmit = (racerId, time, roundIndex) => {
    if (!isViewOnly) {
      recordQualifyingTime(racerId, time, roundIndex);
    }
  };

  const handleInputChange = (racerId, field, value) => {
    if (value === '' || !isNaN(parseFloat(value))) {
      if (field === 'topSpeed') {
        const numericValue = parseFloat(value) || 0;
        setTopSpeeds(prev => ({ ...prev, [racerId]: numericValue }));
        // Also update the qualifyingResults to include the top speed
        setQualifyingResults(prev => prev.map(racer => 
          racer.id === racerId ? { ...racer, topSpeed: numericValue } : racer
        ));
      }
      // ... handle other fields ...
      
      debouncedSaveQualifyingData();
    }
    if (field === 'reactionTime') {
      const updatedReactionTimes = { ...reactionTimes, [racerId]: value };
      setReactionTimes(updatedReactionTimes);
      onReactionTimesUpdate(updatedReactionTimes);
      
      // Update Firebase
      const reactionTimeRef = ref(database, `events/${eventId}/qualifying/reactionTimes/${racerId}`);
      set(reactionTimeRef, value);
    }
  };

  const handleDeleteAllData = () => {
    if (window.confirm('Are you sure you want to delete all qualifying data? This action cannot be undone.')) {
      localStorage.removeItem(`qualifyingData_${eventId}`);
      initializeQualifyingResults();
      alert('All qualifying data has been deleted.');
    }
  };

  const sortResults = () => {
    const sorted = [...qualifyingResults].sort((a, b) => {
      if (a.bestTime === null) return 1;
      if (b.bestTime === null) return -1;
      return sortOrder === 'asc' ? a.bestTime - b.bestTime : b.bestTime - a.bestTime;
    });
    setQualifyingResults(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const isQualifyingComplete = () => {
    return qualifyingResults.every(racer => racer.qualifyingTimes.every(time => time !== null));
  };

  const handleTimingSystemChange = (e) => {
    setTimingSystem(e.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const renderLeaderboard = () => {
    const sortedResults = [...qualifyingResults].sort((a, b) => a.bestTime - b.bestTime);
    return (
      <LeaderboardContainer>
        <h3>Leaderboard</h3>
        <QualifyingTable>
          <thead>
            <StyledTableRow>
              <StyledTableHeader>Position</StyledTableHeader>
              <StyledTableHeader>Racer</StyledTableHeader>
              <StyledTableHeader>Best ET</StyledTableHeader>
              <StyledTableHeader>Reaction Time</StyledTableHeader>
              <StyledTableHeader>Top Speed</StyledTableHeader>
            </StyledTableRow>
          </thead>
          <tbody>
            {sortedResults.map((racer, index) => (
              <StyledTableRow key={racer.id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{racer.name}</StyledTableCell>
                <StyledTableCell>{racer.bestTime ? racer.bestTime.toFixed(3) : '-'}</StyledTableCell>
                <StyledTableCell>{reactionTimes[racer.id] || '-'}</StyledTableCell>
                <StyledTableCell>{topSpeeds[racer.id] ? `${topSpeeds[racer.id]} mph` : '-'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </tbody>
        </QualifyingTable>
      </LeaderboardContainer>
    );
  };

  const toggleRowExpansion = (racerId) => {
    setExpandedRows(prev => ({...prev, [racerId]: !prev[racerId]}));
  };

  const handleQualifyingComplete = () => {
    const updatedResults = qualifyingResults.map(racer => ({
      ...racer,
      carNumber: racer.carNumber, // Ensure carNumber is included
      // ... other properties
    }));
    setQualifyingResults(updatedResults);
    // ... rest of the function
  };

  const handleTimeUpdate = (racerId, round, time) => {
    const updatedResults = qualifyingResults.map(racer => {
      if (racer.id === racerId) {
        const updatedTimes = [...racer.qualifyingTimes];
        updatedTimes[round] = parseFloat(time);
        return { ...racer, qualifyingTimes: updatedTimes };
      }
      return racer;
    });
    setQualifyingResults(updatedResults);
    // Notify parent component
    onQualifyingUpdate(updatedResults);
  };

  const handleReactionTimeUpdate = (racerId, time) => {
    const updatedReactionTimes = { ...reactionTimes, [racerId]: parseFloat(time) };
    setReactionTimes(updatedReactionTimes);
    // Notify parent component
    onReactionTimesUpdate(updatedReactionTimes);
  };

  const handleTopSpeedUpdate = (racerId, speed) => {
    const updatedTopSpeeds = { ...topSpeeds, [racerId]: parseFloat(speed) };
    setTopSpeeds(updatedTopSpeeds);
    // Notify parent component
    onTopSpeedsUpdate(updatedTopSpeeds);
  };

  return (
    <div>
      <StepHeader theme={theme}><FaClock /> Qualifying Rounds</StepHeader>
      
      {!isViewOnly && (
        <ControlsContainer>
          <TimingSystemSelect
            value={timingSystem}
            onChange={(e) => setTimingSystem(e.target.value)}
            theme={theme}
          >
            <option value="manual">Manual Entry</option>
            <option value="trackmate">Trackmate</option>
            <option value="accutime">Accutime</option>
            <option value="raceamerica">Race America</option>
          </TimingSystemSelect>
          
          {timingSystem !== 'manual' && (
            <ImportButton onClick={importTimesFromSystem} theme={theme}>
              <FaSync /> Import Times
            </ImportButton>
          )}
        </ControlsContainer>
      )}

      <div style={{ overflowX: 'auto' }}>
        <QualifyingTable>
          <thead>
            <StyledTableRow>
              <StyledTableHeader>Racer</StyledTableHeader>
              <StyledTableHeader className="hide-xs hide-sm">Team</StyledTableHeader>
              <StyledTableHeader className="hide-xs">Car</StyledTableHeader>
              <StyledTableHeader>Class</StyledTableHeader>
              {Array.from({ length: numQualifyingRounds }, (_, i) => (
                <StyledTableHeader key={i}>R{i + 1}</StyledTableHeader>
              ))}
              <StyledTableHeader>Best ET</StyledTableHeader>
              <StyledTableHeader>RT</StyledTableHeader>
              <StyledTableHeader>Speed</StyledTableHeader>
            </StyledTableRow>
          </thead>
          <tbody>
            {qualifyingResults.map((racer, index) => (
              <React.Fragment key={racer.id || index}>
                <ExpandableRow onClick={() => !isViewOnly && toggleRowExpansion(racer.id)} theme={theme}>
                  <StyledTableCell><strong>{racer.name}</strong></StyledTableCell>
                  <StyledTableCell className="hide-xs hide-sm">{racer.team}</StyledTableCell>
                  <StyledTableCell className="hide-xs">{racer.carInfo}</StyledTableCell>
                  <StyledTableCell>{racer.class}</StyledTableCell>
                  {(racer.qualifyingTimes || []).map((time, i) => (
                    <StyledTableCell key={i}>
                      {isViewOnly ? (
                        time || '-'
                      ) : (
                        <QualifyingInput
                          type="number"
                          step="0.001"
                          min="0"
                          value={time || ''}
                          onChange={(e) => handleTimeSubmit(racer.id, e.target.value, i)}
                          placeholder="Time"
                        />
                      )}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell>
                    <QualifyingInput
                      type="number"
                      step="0.001"
                      min="0"
                      value={racer.bestTime || ''}
                      onChange={(e) => handleInputChange(racer.id, 'bestTime', e.target.value)}
                      placeholder="Best"
                      disabled={timingSystem !== 'manual'}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <QualifyingInput
                      type="text"
                      value={reactionTimes[racer.id] || ''}
                      onChange={(e) => handleInputChange(racer.id, 'reactionTime', e.target.value)}
                      placeholder="RT"
                      disabled={timingSystem !== 'manual'}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <QualifyingInput
                      type="text"
                      value={topSpeeds[racer.id] || ''}
                      onChange={(e) => handleInputChange(racer.id, 'topSpeed', e.target.value)}
                      placeholder="Speed"
                      disabled={timingSystem !== 'manual'}
                    />
                  </StyledTableCell>
                </ExpandableRow>
                <DetailRow isOpen={expandedRows[racer.id]}>
                  <td colSpan="9">
                    {/* Detailed information */}
                  </td>
                </DetailRow>
              </React.Fragment>
            ))}
          </tbody>
        </QualifyingTable>
      </div>

      <AnalyticsContainer>
        <h3><FaChartLine /> Race Analytics</h3>
        {/* Add more detailed analytics here */}
      </AnalyticsContainer>

      {renderLeaderboard()}

      {!isViewOnly && (
        <FinalizeButton onClick={() => onComplete(qualifyingResults)} disabled={!isQualifyingComplete()} theme={theme}>
          Finalize Qualifying
        </FinalizeButton>
      )}
    </div>
  );
};

export default QualifyingRounds;
