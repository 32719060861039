import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button, Input, List, ListItem, Select } from './SharedStyles';
import Navigation from './Navigation';
import CarSelector from './CarSelector';
import { useTheme } from '../contexts/ThemeContext';
import { FaUserPlus, FaEdit, FaTrash, FaDice, FaDatabase, FaPlus } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';

const FormSection = styled.div`
  margin-bottom: 1rem;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FullWidthInput = styled(Input)`
  grid-column: 1 / -1;
`;

const ActionButton = styled(Button)`
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.3rem;
  }
`;

const RacerItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  border-bottom: 1px solid ${props => props.theme.border};
`;

const RacerInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  margin-bottom: 10px;
`;

const RacerInfoItem = styled.span`
  font-size: 0.9em;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const NavigateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const DatabaseButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.background};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

// Add this new styled component for the race number
const RaceNumber = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

function RacerRegistration({ onComplete, addRacer: propAddRacer, teams: propTeams, eventId: propEventId }) {
  const { eventId: urlEventId } = useParams();
  const navigate = useNavigate();
  const [racer, setRacer] = useState({ name: '', team: '', carInfo: '', class: '', induction: '', carNumber: '' });
  const [racers, setRacers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [savedClasses, setSavedClasses] = useState([]);
  const [teams, setTeams] = useState(propTeams || []); // Initialize teams state with propTeams
  const { theme } = useTheme();
  const [globalRacers, setGlobalRacers] = useState([]);
  const [showRacerDatabase, setShowRacerDatabase] = useState(false);
  const { currentUser } = useAuth();
  const [carMake, setCarMake] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carYear, setCarYear] = useState('');

  const effectiveEventId = propEventId || urlEventId;

  useEffect(() => {
    const storedRacers = JSON.parse(localStorage.getItem(`racers_${effectiveEventId}`) || '[]');
    setRacers(storedRacers);

    const storedTeams = JSON.parse(localStorage.getItem(`teams_${effectiveEventId}`) || '[]');
    setTeams(storedTeams);

    const storedClasses = JSON.parse(localStorage.getItem('classes') || '[]');
    setSavedClasses(storedClasses);

    const storedGlobalRacers = JSON.parse(localStorage.getItem(`globalRacers_${currentUser.uid}`) || '[]');
    setGlobalRacers(storedGlobalRacers);
  }, [effectiveEventId, currentUser]);

  const updateGlobalRacer = useCallback((updatedRacer) => {
    setGlobalRacers(prevGlobalRacers => {
      const updatedGlobalRacers = prevGlobalRacers.map(racer => 
        racer.id === updatedRacer.id ? { ...racer, ...updatedRacer } : racer
      );
      localStorage.setItem(`globalRacers_${currentUser.uid}`, JSON.stringify(updatedGlobalRacers));
      return updatedGlobalRacers;
    });
  }, [currentUser]);

  const addRacer = useCallback((newRacer) => {
    setRacers(prevRacers => {
      const updatedRacers = [...prevRacers, newRacer];
      localStorage.setItem(`racers_${effectiveEventId}`, JSON.stringify(updatedRacers));
      return updatedRacers;
    });

    // Update global racers
    updateGlobalRacer(newRacer);

    // Call the prop function if it exists
    if (propAddRacer) {
      propAddRacer(newRacer);
    }
  }, [effectiveEventId, updateGlobalRacer, propAddRacer]);

  const addRacerFromDatabase = (selectedRacer) => {
    const newRacer = { ...selectedRacer, id: selectedRacer.id || Date.now() };
    addRacer(newRacer);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRacer = {
      ...racer,
      id: racer.id || Date.now(),
      carNumber: racer.carNumber,
    };
    console.log('Submitting racer with car number:', newRacer.carNumber);
    if (editingIndex !== null) {
      setRacers(prevRacers => {
        const updatedRacers = [...prevRacers];
        updatedRacers[editingIndex] = newRacer;
        console.log('Updated racers:', updatedRacers);
        localStorage.setItem(`racers_${effectiveEventId}`, JSON.stringify(updatedRacers));
        return updatedRacers;
      });
      // Update the global racer database
      updateGlobalRacer(newRacer);
    } else {
      addRacer(newRacer);
    }
    
    setRacer({ name: '', team: '', carInfo: '', class: '', induction: '', carNumber: '' });
    setEditingIndex(null);
  };

  const handleEdit = (index) => {
    const racerToEdit = racers[index];
    setRacer(racerToEdit);
    // Parse carInfo to set make, model, and year
    const [year, make, model] = racerToEdit.carInfo.split(' ');
    setCarYear(year);
    setCarMake(make);
    setCarModel(model);
    setEditingIndex(index);

    // Ensure the racer's team is in the teams list
    if (racerToEdit.team && !teams.some(team => team.name === racerToEdit.team)) {
      setTeams(prevTeams => [...prevTeams, { id: Date.now(), name: racerToEdit.team }]);
    }
  };

  const editRacerFromDatabase = (selectedRacer) => {
    setRacer(selectedRacer);
    // Parse carInfo to set make, model, and year
    const [year, make, model] = selectedRacer.carInfo.split(' ');
    setCarYear(year);
    setCarMake(make);
    setCarModel(model);
    setEditingIndex(null);
    setShowRacerDatabase(false);

    // Ensure the racer's team is in the teams list
    if (selectedRacer.team && !teams.some(team => team.name === selectedRacer.team)) {
      setTeams(prevTeams => [...prevTeams, { id: Date.now(), name: selectedRacer.team }]);
    }
  };

  const handleDelete = (index) => {
    const updatedRacers = racers.filter((_, i) => i !== index);
    setRacers(updatedRacers);
    localStorage.setItem(`racers_${effectiveEventId}`, JSON.stringify(updatedRacers));
  };

  const handleCarSelect = useCallback((selectedCar) => {
    const [year, make, model] = selectedCar.split(' ');
    setCarYear(year);
    setCarMake(make);
    setCarModel(model);
    setRacer(prev => ({ ...prev, carInfo: `${selectedCar} ${prev.induction}`.trim() }));
  }, []);

  const handleInductionChange = (e) => {
    const newInduction = e.target.value;
    setRacer(prev => ({
      ...prev,
      induction: newInduction,
      carInfo: `${prev.carInfo.split(' ').slice(0, 3).join(' ')} ${newInduction}`.trim()
    }));
  };

  const navigateToChipDrawing = () => {
    navigate(`/events/${effectiveEventId}/draw`);
  };

  const saveCurrentRacersToDatabase = () => {
    const updatedGlobalRacers = [...globalRacers]; // Start with existing global racers
    
    racers.forEach(racer => {
      const existingIndex = updatedGlobalRacers.findIndex(r => r.id === racer.id);
      if (existingIndex !== -1) {
        // Update existing racer
        updatedGlobalRacers[existingIndex] = { ...updatedGlobalRacers[existingIndex], ...racer };
      } else {
        // Add new racer
        updatedGlobalRacers.push(racer);
      }
    });

    setGlobalRacers(updatedGlobalRacers);
    localStorage.setItem(`globalRacers_${currentUser.uid}`, JSON.stringify(updatedGlobalRacers));
    alert('Current racers saved to your database');
  };

  const deleteRacerFromDatabase = (racerId) => {
    const updatedGlobalRacers = globalRacers.filter(r => r.id !== racerId);
    setGlobalRacers(updatedGlobalRacers);
    localStorage.setItem(`globalRacers_${currentUser.uid}`, JSON.stringify(updatedGlobalRacers));
  };

  return (
    <PageContainer theme={theme}>
      {!onComplete && <Navigation />}
      <Container>
        {!onComplete && <Header>Racer Registration</Header>}
        <ButtonContainer>
          <DatabaseButton onClick={() => setShowRacerDatabase(true)} theme={theme}>
            <FaDatabase /> View Racer Database
          </DatabaseButton>
          <DatabaseButton onClick={saveCurrentRacersToDatabase} theme={theme}>
            <FaPlus /> Save Racers to Database
          </DatabaseButton>
        </ButtonContainer>
        <form onSubmit={handleSubmit}>
          <FormSection>
            <FormGrid>
              <Input
                type="text"
                value={racer.name}
                onChange={(e) => setRacer({ ...racer, name: e.target.value })}
                placeholder="Racer Name"
                required
              />
              <Input
                type="text"
                value={racer.carNumber}
                onChange={(e) => setRacer({ ...racer, carNumber: e.target.value })}
                placeholder="Car Number"
                required
              />
              <Select
                value={racer.team}
                onChange={(e) => setRacer({ ...racer, team: e.target.value })}
                required
              >
                <option value="">Select Team</option>
                {teams.map((team) => (
                  <option key={team.id} value={team.name}>{team.name}</option>
                ))}
              </Select>
            </FormGrid>
          </FormSection>
          
          <FormSection>
            <CarSelector 
              onSelect={handleCarSelect} 
              initialMake={carMake}
              initialModel={carModel}
              initialYear={carYear}
            />
          </FormSection>
          
          <FormSection>
            <FormGrid>
              <Select
                value={racer.induction}
                onChange={handleInductionChange}
                required
              >
                <option value="">Select Induction</option>
                <option value="All Motor">All Motor</option>
                <option value="Turbo">Turbo</option>
                <option value="Nitrous">Nitrous</option>
              </Select>
              <Select
                value={racer.class}
                onChange={(e) => setRacer({ ...racer, class: e.target.value })}
                required
              >
                <option value="">Select Class</option>
                {savedClasses.map((className, index) => (
                  <option key={index} value={className}>{className}</option>
                ))}
              </Select>
            </FormGrid>
          </FormSection>
          
          <FormSection>
            <FullWidthInput
              type="text"
              value={racer.carInfo}
              onChange={(e) => setRacer({ ...racer, carInfo: e.target.value })}
              placeholder="Car Info"
            />
          </FormSection>
          
          <SubmitButton type="submit" theme={theme}>
            <FaUserPlus />
            {editingIndex !== null ? 'Update Racer' : 'Register Racer'}
          </SubmitButton>
        </form>
        <List>
          {racers.map((r, index) => (
            <RacerItem key={r.id || index} theme={theme}>
              <RacerInfo>
                <RacerInfoItem>
                  {r.carNumber && <RaceNumber>#{r.carNumber}</RaceNumber>}
                  <strong>{r.name}</strong>
                </RacerInfoItem>
                <RacerInfoItem>{r.team}</RacerInfoItem>
                <RacerInfoItem>{r.carInfo}</RacerInfoItem>
                <RacerInfoItem>{r.class}</RacerInfoItem>
              </RacerInfo>
              <ActionButtons>
                <ActionButton onClick={() => handleEdit(index)} theme={theme}>
                  <FaEdit /> Edit
                </ActionButton>
                <ActionButton onClick={() => handleDelete(index)} theme={theme}>
                  <FaTrash /> Delete
                </ActionButton>
              </ActionButtons>
            </RacerItem>
          ))}
        </List>
        {!onComplete && (
          <ButtonContainer>
            <NavigateButton onClick={navigateToChipDrawing} theme={theme}>
              <FaDice /> Go to Chip Drawing
            </NavigateButton>
          </ButtonContainer>
        )}
      </Container>
      {showRacerDatabase && (
        <>
          <Overlay onClick={() => setShowRacerDatabase(false)} />
          <Modal theme={theme}>
            <Header>Racer Database</Header>
            <List>
              {globalRacers.map((r) => (
                <RacerItem key={r.id} theme={theme}>
                  <RacerInfo>
                    <RacerInfoItem>
                      {r.carNumber && <RaceNumber>#{r.carNumber}</RaceNumber>}
                      <strong>{r.name}</strong>
                    </RacerInfoItem>
                    <RacerInfoItem><strong>Team:</strong> {r.team}</RacerInfoItem>
                    <RacerInfoItem><strong>Vehicle:</strong> {r.carInfo}</RacerInfoItem>
                    <RacerInfoItem><strong>Class:</strong> {r.class}</RacerInfoItem>
                  </RacerInfo>
                  <ActionButtons>
                    <ActionButton onClick={() => addRacerFromDatabase(r)} theme={theme}>
                      <FaPlus /> Add to Event
                    </ActionButton>
                    <ActionButton onClick={() => deleteRacerFromDatabase(r.id)} theme={theme}>
                      <FaTrash /> Remove from Database
                    </ActionButton>
                  </ActionButtons>
                </RacerItem>
              ))}
            </List>
            <ButtonContainer>
              <Button onClick={() => setShowRacerDatabase(false)} theme={theme}>Close Database</Button>
            </ButtonContainer>
          </Modal>
        </>
      )}
    </PageContainer>
  );
}

export default RacerRegistration;
