import React, { useEffect, useState } from 'react';
import axios from 'axios';

const WeatherDisplay = ({ lat, lon }) => {
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeather = async () => {
      console.log('Fetching weather for:', { lat, lon });
      const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
      console.log('API Key:', apiKey);
      
      if (!apiKey) {
        setError('OpenWeatherMap API key is missing. Please check your environment variables.');
        return;
      }

      try {
        const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather`, {
          params: {
            lat,
            lon,
            units: 'imperial', // Changed to imperial for Fahrenheit
            appid: apiKey,
          },
        });

        console.log('API Response:', response.data);

        const tempKelvin = (response.data.main.temp - 32) * 5/9 + 273.15;
        const pressurePa = response.data.main.pressure * 3386.39; // Convert inHg to Pa
        const densityAltitude = calculateDensityAltitude(tempKelvin, pressurePa, response.data.main.sea_level);

        setWeather({
          temperature: response.data.main.temp,
          humidity: response.data.main.humidity,
          windSpeed: response.data.wind.speed,
          windDirection: response.data.wind.deg,
          precipitation: response.data.rain?.['1h'] || 0,
          pressure: response.data.main.pressure,
          dewPoint: calculateDewPoint(response.data.main.temp, response.data.main.humidity),
          densityAltitude: densityAltitude,
        });
      } catch (error) {
        console.error('Error fetching weather data:', error.response || error);
        let errorMessage = `Failed to fetch weather data: ${error.message}`;
        if (error.response) {
          console.error('Error response:', error.response.data);
          errorMessage += ` (Status: ${error.response.status}, Message: ${JSON.stringify(error.response.data)})`;
        }
        setError(errorMessage);
      }
    };

    if (lat && lon) {
      fetchWeather();
    }
  }, [lat, lon]);

  const calculateDewPoint = (tempF, humidity) => {
    const tempC = (tempF - 32) * 5/9;
    const a = 17.27;
    const b = 237.7;
    const alpha = ((a * tempC) / (b + tempC)) + Math.log(humidity / 100);
    const dewPointC = (b * alpha) / (a - alpha);
    return (dewPointC * 9/5) + 32; // Convert back to Fahrenheit
  };

  const calculateDensityAltitude = (tempK, pressurePa, elevation) => {
    const R = 287.05; // Gas constant for dry air
    const g = 9.80665; // Gravitational acceleration
    const P0 = 101325; // Standard sea-level pressure in Pa
    const T0 = 288.15; // Standard sea-level temperature in K
    const L = 0.0065; // Temperature lapse rate

    const H = (T0 / L) * (1 - Math.pow(pressurePa / P0, (R * L) / g));
    const virtualTemp = tempK * (1 + 0.61 * (pressurePa / (461.5 * tempK)));
    
    return (H + (virtualTemp - T0) / L) * 3.28084; // Convert to feet
  };

  const getWindDirection = (degrees) => {
    const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    const index = Math.round(degrees / 22.5) % 16;
    return directions[index];
  };

  if (error) return <p>{error}</p>;
  if (!weather) return <p>Loading weather data...</p>;

  return (
    <div>
      <h2>Current Weather</h2>
      <p>Temperature: {weather.temperature.toFixed(1)}°F</p>
      <p>Humidity: {weather.humidity}%</p>
      <p>Wind: {weather.windSpeed.toFixed(1)} mph {getWindDirection(weather.windDirection)} ({weather.windDirection}°)</p>
      <p>Precipitation: {(weather.precipitation * 0.0393701).toFixed(2)} in</p>
      <p>Barometric Pressure: {weather.pressure.toFixed(2)} inHg</p>
      {weather.dewPoint !== undefined && <p>Dew Point: {weather.dewPoint.toFixed(1)}°F</p>}
      {weather.densityAltitude !== undefined && <p>Density Altitude: {weather.densityAltitude.toFixed(0)} ft</p>}
    </div>
  );
};

export default WeatherDisplay;