import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Button } from './SharedStyles';

const ListBoxContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const EntryList = styled.ul`
  list-style: none;
  padding: 0;
`;

const EntryItem = styled.li`
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${props => props.theme.background};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

const ListBoxComponent = ({ entries, onEdit }) => {
  const { theme } = useTheme();
  const [sortOrder, setSortOrder] = useState('asc');

  const sortedEntries = [...entries]
    .filter(entry => entry.entryNumber !== undefined) // Filter out entries without entryNumber
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.entryNumber.localeCompare(b.entryNumber);
      } else {
        return b.entryNumber.localeCompare(a.entryNumber);
      }
    });

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <ListBoxContainer theme={theme}>
      <Button onClick={toggleSortOrder} theme={theme}>
        Sort {sortOrder === 'asc' ? 'Descending' : 'Ascending'}
      </Button>
      <EntryList>
        {sortedEntries.map(entry => (
          <EntryItem key={entry.id} theme={theme}>
            {entry.entryNumber}
            <Button onClick={() => onEdit(entry)} theme={theme}>
              Edit
            </Button>
          </EntryItem>
        ))}
      </EntryList>
    </ListBoxContainer>
  );
};

export default ListBoxComponent;
