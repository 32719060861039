import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { FaFlag, FaUsers, FaClock, FaChevronRight, FaCheckCircle } from 'react-icons/fa';
import Navigation from './Navigation';

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const HeroSection = styled.section`
  background-image: linear-gradient(
    ${props => props.theme.mode === 'light' ? '#0070f3' : '#6200ee'},
    ${props => props.theme.mode === 'light' ? '#0070f3' : '#6200ee'}
  ), url('/images/hero-background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 2rem;
`;

const H1 = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const SubHeading = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const Button = styled.button`
  background-color: ${props => props.primary 
    ? (props.theme.mode === 'light' ? '#6200ee' : '#0070f3') // Purple in light mode, Blue in dark mode for primary buttons
    : 'transparent'};
  color: ${props => props.primary 
    ? 'white' 
    : (props.theme.mode === 'light' ? '#0070f3' : '#6200ee')};
  border: 2px solid ${props => props.theme.mode === 'light' ? '#6200ee' : '#0070f3'};
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.mode === 'light' ? '#3700b3' : '#0060df'};
    color: white;
    border-color: ${props => props.theme.mode === 'light' ? '#3700b3' : '#0060df'};
  }
`;

const ButtonIcon = styled.span`
  margin-left: 0.5rem;
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background-color: ${props => props.alternate ? props.theme.cardBackground : props.theme.background};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${props => props.theme.text};
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: ${props => props.theme.mode === 'light' ? '#0070f3' : '#6200ee'};
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.textSecondary};
`;

const CTASection = styled(Section)`
  background-color: ${props => props.theme.ctaSectionBackground};
  color: white;
  text-align: center;
`;

const CTATitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const CTADescription = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const TestimonialCard = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TestimonialText = styled.p`
  font-style: italic;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const TestimonialAuthor = styled.p`
  font-weight: bold;
  color: ${props => props.theme.textSecondary};
`;

const TestimonialImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 2rem;
  text-align: center;
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme.primary};
  text-decoration: none;
  margin: 0 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const CTAButton = styled(Button)`
  background-color: ${props => props.theme.mode === 'light' ? '#6200ee' : '#0070f3'}; // Purple in light mode, Blue in dark mode
  color: white;
  &:hover {
    background-color: ${props => props.theme.mode === 'light' ? '#3700b3' : '#0060df'}; // Darker purple in light mode, Darker blue in dark mode
  }
`;

function LandingPage() {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const handleGetStarted = () => {
    navigate('/events');
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <HeroSection theme={theme}>
        <HeroContent>
          <H1>Revolutionize Your Racing Experience</H1>
          <SubHeading>Manage events, track chip drawings, and stay ahead of the competition with RaceGrid Master.</SubHeading>
          <Button primary onClick={handleGetStarted} theme={theme}>
            Get Started <ButtonIcon><FaChevronRight /></ButtonIcon>
          </Button>
        </HeroContent>
      </HeroSection>

      <Section theme={theme}>
        <SectionTitle>Key Features</SectionTitle>
        <FeatureGrid>
          <FeatureCard theme={theme}>
            <FeatureIcon theme={theme}><FaFlag /></FeatureIcon>
            <FeatureTitle>Seamless Event Management</FeatureTitle>
            <FeatureDescription>Create and manage racing events effortlessly with our intuitive interface.</FeatureDescription>
          </FeatureCard>
          <FeatureCard theme={theme}>
            <FeatureIcon theme={theme}><FaUsers /></FeatureIcon>
            <FeatureTitle>Streamlined Racer Registration</FeatureTitle>
            <FeatureDescription>Simplify the registration process for racers and teams with our user-friendly system.</FeatureDescription>
          </FeatureCard>
          <FeatureCard theme={theme}>
            <FeatureIcon theme={theme}><FaClock /></FeatureIcon>
            <FeatureTitle>Real-time Results & Analytics</FeatureTitle>
            <FeatureDescription>Track race results, standings, and performance metrics as they happen.</FeatureDescription>
          </FeatureCard>
        </FeatureGrid>
      </Section>

      <Section alternate theme={theme}>
        <SectionTitle>What Our Users Say</SectionTitle>
        <TestimonialGrid>
          <TestimonialCard theme={theme}>
            <TestimonialText>"RaceGrid Master has transformed how we manage our events. It's a game-changer!"</TestimonialText>
            <TestimonialAuthor>- John D., Event Organizer</TestimonialAuthor>
          </TestimonialCard>
          <TestimonialCard theme={theme}>
            <TestimonialText>"The real-time results feature is incredible. Our fans love it!"</TestimonialText>
            <TestimonialAuthor>- Sarah L., Racing Team Manager</TestimonialAuthor>
          </TestimonialCard>
        </TestimonialGrid>
      </Section>

      <CTASection theme={theme}>
        <CTATitle>Ready to Elevate Your Racing Experience?</CTATitle>
        <CTADescription>Join thousands of satisfied users and take your racing events to the next level with RaceGrid Master.</CTADescription>
        <CTAButton onClick={handleGetStarted} theme={theme}>Start Your Free Trial</CTAButton>
      </CTASection>

      <Footer theme={theme}>
        <p>&copy; 2024 RaceGrid Master. All rights reserved.</p>
        <nav>
          <FooterLink to="/about" theme={theme}>About</FooterLink>
          <FooterLink to="/contact" theme={theme}>Contact</FooterLink>
          <FooterLink to="/privacy" theme={theme}>Privacy Policy</FooterLink>
        </nav>
      </Footer>
    </PageContainer>
  );
}

export default LandingPage;