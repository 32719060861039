import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button, Input, List, ListItem } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaPlus, FaTrash, FaUsers } from 'react-icons/fa';

const FormContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
`;

const ClassItem = styled(ListItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

const ClassName = styled.span`
  flex-grow: 1;
`;

const ActionButton = styled(Button)`
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.3rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const NavigateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

function ClassManagement() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [newClass, setNewClass] = useState('');
  const { theme } = useTheme();
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    const storedClasses = JSON.parse(localStorage.getItem('classes') || '[]');
    setClasses(storedClasses);

    // Fetch the event type
    const events = JSON.parse(localStorage.getItem('events') || '[]');
    const currentEvent = events.find(event => event.id === parseInt(eventId));
    if (currentEvent) {
      setEventType(currentEvent.type);
    }
  }, [eventId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newClass && !classes.includes(newClass)) {
      const updatedClasses = [...classes, newClass];
      setClasses(updatedClasses);
      localStorage.setItem('classes', JSON.stringify(updatedClasses));
      setNewClass('');
    }
  };

  const handleDelete = (index) => {
    const updatedClasses = classes.filter((_, i) => i !== index);
    setClasses(updatedClasses);
    localStorage.setItem('classes', JSON.stringify(updatedClasses));
  };

  const navigateToRacerRegistration = () => {
    if (eventType === 'No Prep') {
      navigate(`/events/${eventId}/racers`);
    } else {
      navigate(`/bracket-builder`, { 
        state: { 
          eventId, 
          initialStep: 'registration'  // This ensures we start at the registration tab
        } 
      });
    }
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Class Management</Header>
        <FormContainer>
          <StyledInput
            type="text"
            value={newClass}
            onChange={(e) => setNewClass(e.target.value)}
            placeholder="New Class Name"
            theme={theme}
          />
          <ActionButton onClick={handleSubmit} theme={theme}>
            <FaPlus /> Add Class
          </ActionButton>
        </FormContainer>
        <List>
          {classes.map((className, index) => (
            <ClassItem key={index} theme={theme}>
              <ClassName>{className}</ClassName>
              <ActionButton onClick={() => handleDelete(index)} theme={theme}>
                <FaTrash /> Delete
              </ActionButton>
            </ClassItem>
          ))}
        </List>
        <ButtonContainer>
          <NavigateButton onClick={navigateToRacerRegistration} theme={theme}>
            <FaUsers /> Go to Racer Registration
          </NavigateButton>
        </ButtonContainer>
      </Container>
    </PageContainer>
  );
}

export default ClassManagement;