import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Input, Button } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';

const FormContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const QuickAddForm = ({ onSave }) => {
  const { theme } = useTheme();
  const { control, handleSubmit } = useForm();

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <FormContainer theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input {...field} placeholder="Name" theme={theme} />}
        />
        <Controller
          name="entryNumber"
          control={control}
          render={({ field }) => <Input {...field} placeholder="Entry Number" theme={theme} />}
        />
        <Button type="submit" theme={theme}>Quick Add</Button>
      </form>
    </FormContainer>
  );
};

export default QuickAddForm;
