import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QRCodeSVG } from 'qrcode.react';
import { useTheme } from '../contexts/ThemeContext';

const stripePromise = loadStripe('your_stripe_publishable_key');

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
`;

const SubTitle = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 20px;
  font-size: 1.5rem;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormElement = styled.div`
  width: 100%;
  margin-bottom: 20px; // Add some bottom margin for spacing
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 16px;
  background-color: ${props => props.theme.inputBackground};
  color: black !important; // Force black color
  transition: border-color 0.3s ease;
  box-sizing: border-box; // Ensure padding is included in width calculation

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }

  &::placeholder {
    color: ${props => props.theme.textSecondary};
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 16px;
  background-color: ${props => props.theme.inputBackground};
  color: black; // Changed to black
  cursor: pointer;
  transition: border-color 0.3s ease;
  box-sizing: border-box; // Ensure padding is included in width calculation

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const TicketOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const TicketOption = styled.div`
  border: 2px solid ${props => props.selected ? props.theme.primary : props.theme.border};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const TicketName = styled.h3`
  margin-bottom: 10px;
  color: ${props => props.theme.text};
`;

const TicketPrice = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.theme.primary};
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${props => props.theme.buttonBackground};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }

  &:disabled {
    background-color: ${props => props.theme.disabled};
    cursor: not-allowed;
  }
`;

const TicketContainer = styled.div`
  margin-top: 30px;
  text-align: center;
  background-color: ${props => props.theme.cardBackground};
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

function SpectatorRegistration() {
  const { eventId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    eventId: eventId || '',
  });
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [events, setEvents] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [ticket, setTicket] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const { theme } = useTheme();

  useEffect(() => {
    const fetchEvents = () => {
      const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
      console.log('Fetched events:', storedEvents);
      // Convert event IDs to strings
      const eventsWithStringIds = storedEvents.map(event => ({
        ...event,
        id: event.id.toString()
      }));
      setEvents(eventsWithStringIds);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchTickets = () => {
      console.log('Current formData.eventId:', formData.eventId);
      if (formData.eventId) {
        const selectedEvent = events.find(event => event.id === formData.eventId);
        console.log('Selected event:', selectedEvent);
        if (selectedEvent && selectedEvent.tickets) {
          const spectatorTickets = selectedEvent.tickets.filter(ticket => ticket.type === 'Spectator');
          console.log('Filtered spectator tickets:', spectatorTickets);
          setTicketOptions(spectatorTickets);
        } else {
          console.log('No tickets found for the selected event');
          setTicketOptions([]);
        }
      } else {
        console.log('No event selected');
        setTicketOptions([]);
      }
    };

    fetchTickets();
  }, [formData.eventId, events]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    console.log('Form data updated:', { ...formData, [name]: value });
  };

  const handleTicketSelect = (ticket) => {
    setSelectedTicket(ticket);
    console.log('Selected ticket:', ticket);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || !selectedTicket) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // Send formData, selectedTicket, and paymentMethod.id to your server
      // Your server should create a charge using the payment method ID
      // and generate a ticket

      // This is a placeholder for the ticket generation
      setTicket({
        id: 'TICKET-' + Math.random().toString(36).substr(2, 9),
        name: formData.name,
        email: formData.email,
        eventId: formData.eventId,
        ticketType: selectedTicket.name,
        price: selectedTicket.price,
      });
    }
  };

  return (
    <PageContainer>
      <Title theme={theme}>Spectator Registration</Title>
      <FormContainer onSubmit={handleSubmit}>
        <FormElement>
          <StyledSelect 
            name="eventId" 
            value={formData.eventId} 
            onChange={handleChange} 
            required
            theme={theme}
          >
            <option value="">Select Event</option>
            {events.map((event) => (
              <option key={event.id} value={event.id}>{event.name}</option>
            ))}
          </StyledSelect>
        </FormElement>
        {formData.eventId && (
          <>
            {ticketOptions.length > 0 ? (
              <>
                <SubTitle theme={theme}>Available Tickets</SubTitle>
                <TicketOptionsContainer>
                  {ticketOptions.map((ticket) => (
                    <TicketOption 
                      key={ticket.id} 
                      theme={theme} 
                      selected={selectedTicket?.id === ticket.id} 
                      onClick={() => handleTicketSelect(ticket)}
                    >
                      <TicketName theme={theme}>{ticket.name}</TicketName>
                      <TicketPrice theme={theme}>${ticket.price}</TicketPrice>
                    </TicketOption>
                  ))}
                </TicketOptionsContainer>
              </>
            ) : (
              <SubTitle theme={theme}>No tickets available for this event</SubTitle>
            )}
          </>
        )}
        {selectedTicket && (
          <>
            <FormElement>
              <StyledInput name="name" placeholder="Name" onChange={handleChange} required theme={theme} />
            </FormElement>
            <FormElement>
              <StyledInput name="email" type="email" placeholder="Email" onChange={handleChange} required theme={theme} />
            </FormElement>
            <SubTitle theme={theme}>Selected Ticket: {selectedTicket.name} - ${selectedTicket.price}</SubTitle>
            <FormElement>
              <CardElement options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: 'black',
                    '::placeholder': {
                      color: theme.textSecondary,
                    },
                  },
                },
              }} />
            </FormElement>
            <StyledButton type="submit" theme={theme}>Purchase Ticket</StyledButton>
          </>
        )}
        {ticket && (
          <TicketContainer theme={theme}>
            <SubTitle theme={theme}>Your Ticket</SubTitle>
            <p>Ticket ID: {ticket.id}</p>
            <p>Name: {ticket.name}</p>
            <p>Email: {ticket.email}</p>
            <p>Event: {events.find(e => e.id === ticket.eventId)?.name || 'Unknown Event'}</p>
            <p>Ticket Type: {ticket.ticketType}</p>
            <p>Price: ${ticket.price}</p>
            <QRCodeSVG value={JSON.stringify(ticket)} />
          </TicketContainer>
        )}
      </FormContainer>
    </PageContainer>
  );
}

function WrappedSpectatorRegistration() {
  return (
    <Elements stripe={stripePromise}>
      <SpectatorRegistration />
    </Elements>
  );
}

export default WrappedSpectatorRegistration;