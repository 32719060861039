import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaFlag, FaCar, FaTrophy } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';

const SimulationContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: ${props => props.theme.background};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: ${props => props.theme.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const MatchupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.cardBackground};
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
`;

const RacerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
`;

const RacerName = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text};
`;

const RaceTrack = styled.div`
  width: 50%;
  height: 80px;
  background-color: #333;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
`;

const RaceCar = styled.div`
  width: 60px;
  height: 30px;
  background-color: ${props => props.color};
  position: absolute;
  bottom: 10px;
  left: ${props => props.position}%;
  transition: left 0.5s linear;
  border-radius: 5px;
`;

const ResultsContainer = styled.div`
  margin-top: 2rem;
  background-color: ${props => props.theme.cardBackground};
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResultTitle = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.primary};
  margin-bottom: 1rem;
`;

const ResultItem = styled.div`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text};
`;

const Button = styled.button`
  background-color: ${props => props.theme.buttonBackground};
  color: ${props => props.theme.mode === 'light' ? props.theme.onPrimary : props.theme.text};
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const NoMatchupsMessage = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.text};
  text-align: center;
  margin: 2rem 0;
`;

function RaceSimulation({ onRaceComplete, currentMatchups = [] }) {
  const { theme } = useTheme();
  const [raceInProgress, setRaceInProgress] = useState(false);
  const [raceResults, setRaceResults] = useState([]);
  const [carPositions, setCarPositions] = useState({});

  useEffect(() => {
    if (raceInProgress) {
      const interval = setInterval(() => {
        setCarPositions(prevPositions => {
          const newPositions = { ...prevPositions };
          Object.keys(newPositions).forEach(racerId => {
            newPositions[racerId] = Math.min(100, newPositions[racerId] + Math.random() * 5);
          });
          return newPositions;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [raceInProgress]);

  const startRace = () => {
    if (currentMatchups.length === 0) {
      alert("No matchups available. Please set up matchups before starting the race.");
      return;
    }

    setRaceInProgress(true);
    setRaceResults([]);
    setCarPositions(
      currentMatchups.reduce((acc, matchup) => ({
        ...acc,
        [matchup.racer1.id]: 0,
        [matchup.racer2.id]: 0,
      }), {})
    );

    setTimeout(() => {
      const results = currentMatchups.map(matchup => {
        const winner = Math.random() < 0.5 ? matchup.racer1 : matchup.racer2;
        return { ...matchup, winner };
      });
      setRaceResults(results);
      setRaceInProgress(false);
    }, 5000);
  };

  const completeRace = () => {
    if (onRaceComplete) {
      raceResults.forEach(result => onRaceComplete(result.winner.name));
    }
  };

  return (
    <SimulationContainer>
      <Title>Race Simulation</Title>
      {currentMatchups.length === 0 ? (
        <NoMatchupsMessage>No matchups available. Please set up matchups before starting the race.</NoMatchupsMessage>
      ) : (
        currentMatchups.map((matchup, index) => (
          <MatchupContainer key={index}>
            <RacerInfo>
              <RacerName>{matchup.racer1.name}</RacerName>
              <FaCar />
            </RacerInfo>
            <RaceTrack>
              <RaceCar color="red" position={carPositions[matchup.racer1.id] || 0} />
              <RaceCar color="blue" position={carPositions[matchup.racer2.id] || 0} />
            </RaceTrack>
            <RacerInfo>
              <RacerName>{matchup.racer2.name}</RacerName>
              <FaCar />
            </RacerInfo>
          </MatchupContainer>
        ))
      )}
      {!raceInProgress && raceResults.length === 0 && (
        <Button onClick={startRace} theme={theme}>
          <FaFlag /> Start Race
        </Button>
      )}
      {raceResults.length > 0 && (
        <ResultsContainer>
          <ResultTitle>Race Results</ResultTitle>
          {raceResults.map((result, index) => (
            <ResultItem key={index}>
              {result.racer1.name} vs {result.racer2.name}: Winner - {result.winner.name}
            </ResultItem>
          ))}
          <Button onClick={completeRace}>
            <FaTrophy /> Complete Race
          </Button>
        </ResultsContainer>
      )}
    </SimulationContainer>
  );
}

export default RaceSimulation;