import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { PageContainer, Header, Button, Select, Input, StyledButton } from './SharedStyles';

const CategoryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CategoryList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); // Adjusted for smaller cards
  gap: 1rem;
  justify-items: center;
`;

const CategoryItem = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.border};
`;

const CategoryName = styled.h3`
  margin: 0;
  color: ${props => props.theme.textPrimary};
`;

const CategoryInitials = styled.span`
  font-weight: bold;
  color: ${props => props.theme.textSecondary};
`;

const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldLabel = styled.span`
  font-size: 0.9em;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 2px;
  &::after {
    content: ':';
    margin-left: 2px;
  }
`;

const FieldValue = styled.span`
  font-weight: 500;
  color: ${props => props.theme.textPrimary};
  margin-top: 2px;
`;

const FieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const FieldContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.span`
  font-weight: bold;
  color: ${props => props.theme.textSecondary};
  margin-right: 5px;
`;

const CategoryForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: ${props => props.theme.cardBackground};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ChicagoShootoutContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const SmallButton = styled(Button)`
  padding: 0.6rem 1.2rem; // Increased padding for larger buttons
  font-size: 1rem; // Slightly larger font size
  width: auto;
  align-self: flex-start;
`;

const EditButton = styled(SmallButton)`
  width: 100%; // Make the Edit button fill the card width
  margin-top: 0.5rem; // Add some space above the button
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const ClassContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
`;

const ClassItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const CategoryDatabase = () => {
  const { theme } = useTheme();
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [newClass, setNewClass] = useState({ name: '', index: '' });

  useEffect(() => {
    // Fetch categories from the database
    // For example, using axios:
    // axios.get('/api/categories').then(response => setCategories(response.data));
  }, []);

  const handleCategoryChange = (id, field, value) => {
    setCategories(categories.map(category =>
      category.id === id ? { ...category, [field]: value } : category
    ));
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (editingCategory) {
      setCategories(categories.map(category =>
        category.id === editingCategory.id ? editingCategory : category
      ));
      setEditingCategory(null);
    } else {
      const newCategory = {
        id: categories.length + 1,
        name: `New Category`,
        initials: '',
        qualifyMode: 'Best Against Index',
        treeSpeed: 500,
        typeFinish: 'Breakout',
        bulbMode: 'Normal',
        finishLine: 1320,
        autoStart: 'ON',
        stageOnTime: 900,
        typeEliminator: 'Normal Eliminations',
        typeLadder: 'Sportsman',
        entryFee: 0,
        indexValue: 0,
        penalty: 0,
        classes: [{ name: 'Class A', performance: 0 }],
        currentRound: 1,
        isChicagoShootout: false,
        chicagoShootoutRounds: 3,
      };
      setCategories([...categories, newCategory]);
    }
  };

  const applyPenalties = () => {
    setCategories(categories.map(category => {
      const updatedClasses = category.classes.map(cls => {
        if (cls.performance > category.indexValue) {
          cls.penalty = (cls.penalty || 0) + 1;
          category.indexValue += cls.penalty;
        }
        return cls;
      });
      return { ...category, classes: updatedClasses };
    }));
  };

  const nextRound = () => {
    applyPenalties();
    setCategories(categories.map(category => ({
      ...category,
      currentRound: category.currentRound + 1,
    })));
  };

  const handleChicagoShootoutToggle = (id) => {
    setCategories(categories.map(category =>
      category.id === id ? { ...category, isChicagoShootout: !category.isChicagoShootout } : category
    ));
  };

  const handleChicagoShootoutRoundsChange = (id, rounds) => {
    setCategories(categories.map(category =>
      category.id === id ? { ...category, chicagoShootoutRounds: rounds } : category
    ));
  };

  const handleAddClass = () => {
    if (editingCategory && newClass.name && newClass.index) {
      const updatedClasses = [...(editingCategory.classes || []), newClass];
      setEditingCategory({ ...editingCategory, classes: updatedClasses });
      setNewClass({ name: '', index: '' });
    }
  };

  const handleRemoveClass = (className) => {
    if (editingCategory) {
      const updatedClasses = editingCategory.classes.filter(c => c.name !== className);
      setEditingCategory({ ...editingCategory, classes: updatedClasses });
    }
  };

  const handleClassEliminationToggle = () => {
    if (editingCategory) {
      setEditingCategory({
        ...editingCategory,
        hasClassEliminations: !editingCategory.hasClassEliminations
      });
    }
  };

  const handleRemoveCategory = (id) => {
    setCategories(categories.filter(category => category.id !== id));
  };

  // Add preset categories
  const presetCategories = [
    { name: "Pro Mod", initials: "PM", qualifyMode: "Best Against Index", treeSpeed: 400 },
    { name: "Top Sportsman", initials: "TS", qualifyMode: "Best Against Index", treeSpeed: 500 },
    { name: "Super Pro", initials: "SP", qualifyMode: "Best Against Index", treeSpeed: 500 },
    { name: "Pro", initials: "PR", qualifyMode: "Best Against Index", treeSpeed: 500 },
    { name: "Sportsman", initials: "SM", qualifyMode: "Best Against Index", treeSpeed: 500 },
  ];

  const handleAddPreset = (preset) => {
    setCategories([...categories, { ...preset, id: Date.now() }]);
  };

  return (
    <PageContainer theme={theme}>
      <CategoryContainer>
        <Header>Category Database</Header>
        <CategoryForm onSubmit={handleSave}>
          <FieldContainer>
            <SectionLabel>Name:</SectionLabel>
            <Input
              value={editingCategory ? editingCategory.name : ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Initials:</SectionLabel>
            <Input
              value={editingCategory ? editingCategory.initials : ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, initials: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Qualify Mode:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.qualifyMode : 'Best Against Index'}
              onChange={(e) => setEditingCategory({ ...editingCategory, qualifyMode: e.target.value })}
              theme={theme}
            >
              <option value="Best Against Index">Best Against Index</option>
              <option value="Average ET">Average ET</option>
              <option value="Single Run">Single Run</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Tree Speed:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.treeSpeed : 500}
              onChange={(e) => setEditingCategory({ ...editingCategory, treeSpeed: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Finish:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.typeFinish : 'Breakout'}
              onChange={(e) => setEditingCategory({ ...editingCategory, typeFinish: e.target.value })}
              theme={theme}
            >
              <option value="Breakout">Breakout</option>
              <option value="First to Finish">First to Finish</option>
              <option value="Index Match">Index Match</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Bulb Mode:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.bulbMode : 'Normal'}
              onChange={(e) => setEditingCategory({ ...editingCategory, bulbMode: e.target.value })}
              theme={theme}
            >
              <option value="Normal">Normal</option>
              <option value="Pro Tree">Pro Tree</option>
              <option value="Full Tree">Full Tree</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Finish Line:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.finishLine : 1320}
              onChange={(e) => setEditingCategory({ ...editingCategory, finishLine: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Auto Start:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.autoStart : 'ON'}
              onChange={(e) => setEditingCategory({ ...editingCategory, autoStart: e.target.value })}
              theme={theme}
            >
              <option value="ON">ON</option>
              <option value="OFF">OFF</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Stage On Time:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.stageOnTime : 900}
              onChange={(e) => setEditingCategory({ ...editingCategory, stageOnTime: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Eliminator:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.typeEliminator : 'Normal Eliminations'}
              onChange={(e) => setEditingCategory({ ...editingCategory, typeEliminator: e.target.value })}
              theme={theme}
            >
              <option value="Normal Eliminations">Normal Eliminations</option>
              <option value="Bracket Racing">Bracket Racing</option>
              <option value="Grudge Racing">Grudge Racing</option>
              <option value="Heads-Up">Heads-Up</option>
              <option value="Index Racing">Index Racing</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Ladder:</SectionLabel>
            <Select
              value={editingCategory ? editingCategory.typeLadder : 'Sportsman'}
              onChange={(e) => setEditingCategory({ ...editingCategory, typeLadder: e.target.value })}
              theme={theme}
            >
              <option value="Sportsman">Sportsman</option>
              <option value="Pro">Pro</option>
            </Select>
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Entry Fee:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.entryFee : 0}
              onChange={(e) => setEditingCategory({ ...editingCategory, entryFee: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Index Value:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.indexValue : 0}
              onChange={(e) => setEditingCategory({ ...editingCategory, indexValue: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Penalty:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.penalty : 0}
              onChange={(e) => setEditingCategory({ ...editingCategory, penalty: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Current Round:</SectionLabel>
            <Input
              type="number"
              value={editingCategory ? editingCategory.currentRound : 1}
              onChange={(e) => setEditingCategory({ ...editingCategory, currentRound: e.target.value })}
              theme={theme}
            />
          </FieldContainer>
          <FieldContainer>
            <SectionLabel>Chicago Shootout:</SectionLabel>
            <Select
              value={editingCategory ? (editingCategory.isChicagoShootout ? 'Yes' : 'No') : 'No'}
              onChange={(e) => setEditingCategory({ ...editingCategory, isChicagoShootout: e.target.value === 'Yes' })}
              theme={theme}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Select>
          </FieldContainer>
          {editingCategory && editingCategory.isChicagoShootout && (
            <FieldContainer>
              <SectionLabel>Chicago Shootout Rounds:</SectionLabel>
              <Input
                type="number"
                value={editingCategory.chicagoShootoutRounds}
                onChange={(e) => setEditingCategory({ ...editingCategory, chicagoShootoutRounds: parseInt(e.target.value) })}
                theme={theme}
              />
            </FieldContainer>
          )}
          <FieldContainer>
            <SectionLabel>Class Eliminations:</SectionLabel>
            <Select
              value={editingCategory?.hasClassEliminations ? 'Yes' : 'No'}
              onChange={handleClassEliminationToggle}
              theme={theme}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Select>
          </FieldContainer>

          {editingCategory?.hasClassEliminations && (
            <ClassContainer>
              <Header>Classes</Header>
              {editingCategory.classes?.map((cls, index) => (
                <ClassItem key={index}>
                  <span>{cls.name} - Index: {cls.index}</span>
                  <Button onClick={() => handleRemoveClass(cls.name)} theme={theme}>Remove</Button>
                </ClassItem>
              ))}
              <FieldContainer>
                <Input
                  placeholder="Class Name"
                  value={newClass.name}
                  onChange={(e) => setNewClass({ ...newClass, name: e.target.value })}
                  theme={theme}
                />
                <Input
                  placeholder="Class Index"
                  value={newClass.index}
                  onChange={(e) => setNewClass({ ...newClass, index: e.target.value })}
                  theme={theme}
                />
                <Button onClick={handleAddClass} theme={theme}>Add Class</Button>
              </FieldContainer>
            </ClassContainer>
          )}

          <ActionContainer>
            <StyledButton onClick={nextRound} theme={theme}>
              Next Round
            </StyledButton>
            <StyledButton onClick={() => setEditingCategory({})} theme={theme}>
              Add Category
            </StyledButton>
            {editingCategory && (
              <SmallButton type="submit" theme={theme}>Save Changes</SmallButton>
            )}
          </ActionContainer>
        </CategoryForm>
        <CategoryList>
          {categories.map(category => (
            <CategoryItem key={category.id} theme={theme}>
              <CategoryHeader>
                <CategoryName>{category.name}</CategoryName>
                <CategoryInitials>{category.initials}</CategoryInitials>
              </CategoryHeader>
              <FieldGrid>
                <FieldItem>
                  <FieldLabel>Qualify Mode</FieldLabel>
                  <FieldValue>{category.qualifyMode || 'Best Against Index'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Tree Speed</FieldLabel>
                  <FieldValue>{category.treeSpeed || '500'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Finish</FieldLabel>
                  <FieldValue>{category.typeFinish || 'First to Finish'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Bulb Mode</FieldLabel>
                  <FieldValue>{category.bulbMode || 'Pro Tree'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Finish Line</FieldLabel>
                  <FieldValue>{category.finishLine || '1320'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Auto Start</FieldLabel>
                  <FieldValue>{category.autoStart || 'OFF'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Stage On Time</FieldLabel>
                  <FieldValue>{category.stageOnTime || '10'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Eliminator</FieldLabel>
                  <FieldValue>{category.typeEliminator || 'Heads-Up'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Ladder</FieldLabel>
                  <FieldValue>{category.typeLadder || 'Pro'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Entry Fee</FieldLabel>
                  <FieldValue>{category.entryFee || '0'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Index Value</FieldLabel>
                  <FieldValue>{category.indexValue || '0'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Penalty</FieldLabel>
                  <FieldValue>{category.penalty || '0'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Current Round</FieldLabel>
                  <FieldValue>{category.currentRound || '1'}</FieldValue>
                </FieldItem>
                <FieldItem>
                  <FieldLabel>Chicago Shootout</FieldLabel>
                  <FieldValue>{category.isChicagoShootout ? 'Yes' : 'No'}</FieldValue>
                </FieldItem>
                {category.isChicagoShootout && (
                  <FieldItem>
                    <FieldLabel>Chicago Shootout Rounds</FieldLabel>
                    <FieldValue>{category.chicagoShootoutRounds || '3'}</FieldValue>
                  </FieldItem>
                )}
                <FieldItem>
                  <FieldLabel>Has Class Eliminations</FieldLabel>
                  <FieldValue>{category.hasClassEliminations ? 'Yes' : 'No'}</FieldValue>
                </FieldItem>
              </FieldGrid>
              {category.hasClassEliminations && category.classes && category.classes.length > 0 && (
                <FieldItem style={{ marginTop: '15px' }}>
                  <FieldLabel>Classes</FieldLabel>
                  <FieldValue>
                    {category.classes.map((cls, index) => (
                      <div key={index}>{cls.name} (Index: {cls.index})</div>
                    ))}
                  </FieldValue>
                </FieldItem>
              )}
              <ButtonContainer>
                <StyledButton onClick={() => handleEdit(category)} theme={theme}>Edit</StyledButton>
                <StyledButton onClick={() => handleRemoveCategory(category.id)} theme={theme}>Remove</StyledButton>
              </ButtonContainer>
            </CategoryItem>
          ))}
        </CategoryList>
        <ChicagoShootoutContainer>
          <Header>Chicago Shootout Help</Header>
          <p>Chicago Shootout is a special elimination format where:</p>
          <ul>
            <li>All entries run in eliminations for a set number of rounds.</li>
            <li>The system calculates the number of wins per entry for each round.</li>
            <li>After the set number of rounds, entrants are laddered based on who has won the most rounds.</li>
            <li>Only categories set for Chicago Style Shootout are displayed in this format.</li>
          </ul>
        </ChicagoShootoutContainer>
        <PresetContainer>
          <Header>Preset Categories</Header>
          <PresetGrid>
            {presetCategories.map((preset, index) => (
              <PresetCard key={index} theme={theme}>
                <h3>{preset.name}</h3>
                <p>Initials: {preset.initials}</p>
                <p>Qualify Mode: {preset.qualifyMode}</p>
                <p>Tree Speed: {preset.treeSpeed}</p>
                <Button onClick={() => handleAddPreset(preset)} theme={theme}>Add</Button>
              </PresetCard>
            ))}
          </PresetGrid>
        </PresetContainer>
      </CategoryContainer>
    </PageContainer>
  );
};

// Styled components for preset cards
const PresetContainer = styled.div`
  margin-top: 20px;
`;

const PresetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`;

const PresetCard = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default CategoryDatabase;
