import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button, Select, List } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaDice, FaRedo, FaShare, FaTrophy, FaFlag, FaClock } from 'react-icons/fa';
import LiveTimingDisplay from './LiveTimingDisplay';
import { v4 as uuidv4 } from 'uuid';
import { ref, set, onValue } from "firebase/database";
import { database } from '../firebase';

const Section = styled.section`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const StyledSelect = styled(Select)`
  flex: 1;
  min-width: 200px;
  font-size: 1rem;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${props => props.theme.background};
  border-bottom: 1px solid ${props => props.theme.border};
  &:last-child {
    border-bottom: none;
  }
`;

const RacerInfo = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.1rem;
`;

const ShareLinkInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  margin-top: 0.5rem;
  font-size: 1rem;
`;

const WinnerSelect = styled(Select)`
  width: 150px;
  font-size: 0.9rem;
  margin-left: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

function ChipDrawing({ eventId: propEventId, initialState, readOnly }) {
  const { eventId: urlEventId } = useParams();
  const navigate = useNavigate();
  const [racers, setRacers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [currentClass, setCurrentClass] = useState('');
  const [bag, setBag] = useState([]);
  const [pairings, setPairings] = useState([]);
  const [shareLink, setShareLink] = useState('');
  const [round, setRound] = useState(1);
  const { theme } = useTheme();
  const [showLiveTiming, setShowLiveTiming] = useState(false);
  const [shareId, setShareId] = useState(null);

  const effectiveEventId = propEventId || urlEventId;

  useEffect(() => {
    if (initialState) {
      setCurrentClass(initialState.currentClass);
      setBag(initialState.bag);
      setPairings(initialState.pairings);
    } else {
      const storedRacers = JSON.parse(localStorage.getItem(`racers_${effectiveEventId}`) || '[]');
      const storedClasses = JSON.parse(localStorage.getItem('classes') || '[]');
      const storedDrawingState = JSON.parse(localStorage.getItem(`drawingState_${effectiveEventId}`) || '{}');
      
      console.log('Loaded racers:', storedRacers);
      setRacers(storedRacers);
      setClasses(storedClasses);
      
      if (storedDrawingState.bag) setBag(storedDrawingState.bag);
      if (storedDrawingState.pairings) setPairings(storedDrawingState.pairings);
      if (storedDrawingState.round) setRound(storedDrawingState.round);
      if (storedDrawingState.currentClass) setCurrentClass(storedDrawingState.currentClass);
    }
  }, [effectiveEventId, initialState]);

  // Effect to update shared data whenever relevant state changes
  useEffect(() => {
    if (shareId) {
      updateSharedData();
    }
  }, [currentClass, round, bag, pairings, shareId]);

  const updateSharedData = () => {
    const sharedData = {
      eventName: `Event ${effectiveEventId}`,
      currentClass,
      round,
      bag,
      pairings,
    };
    
    const sharedDataRef = ref(database, `sharedChipDrawing/${effectiveEventId}/${shareId}`);
    set(sharedDataRef, sharedData)
      .then(() => {
        console.log('Shared data updated successfully:', sharedData);
      })
      .catch((error) => {
        console.error('Error updating shared data:', error);
      });
  };

  const initializeBag = () => {
    if (currentClass) {
      console.log('Current class:', currentClass);
      console.log('All racers:', racers);
      // Filter racers for the selected class
      const classRacers = racers.filter(racer => racer.class === currentClass);
      console.log('Filtered class racers:', classRacers);
      
      if (classRacers.length === 0) {
        alert('No racers found for the selected class. Please add racers first.');
        return;
      }

      console.log('Initializing bag with racers:', classRacers);
      setBag(classRacers);
      setPairings([]);
      setRound(1);

      // Update the drawing state in localStorage
      const drawingState = { bag: classRacers, pairings: [], round: 1, currentClass };
      localStorage.setItem(`drawingState_${effectiveEventId}`, JSON.stringify(drawingState));
    } else {
      alert('Please select a class.');
    }
    updateSharedData();
  };

  const drawAllChips = () => {
    if (bag.length === 0) {
      alert('Please initialize the bag first.');
      return;
    }
    const shuffledRacers = [...bag].sort(() => Math.random() - 0.5);
    console.log('Drawn chips:', shuffledRacers);
    createPairings(shuffledRacers);
    updateSharedData();
  };

  const createPairings = (chips) => {
    const newPairings = [];
    let unpairedRacers = [...chips];
    const byeRunHistory = JSON.parse(localStorage.getItem(`byeRunHistory_${effectiveEventId}_${currentClass}`) || '{}');

    // Shuffle the racers to ensure randomness
    unpairedRacers = unpairedRacers.sort(() => Math.random() - 0.5);

    // First, pair racers from different teams
    const teamsMap = new Map();
    unpairedRacers.forEach(racer => {
      if (!teamsMap.has(racer.team)) {
        teamsMap.set(racer.team, []);
      }
      teamsMap.get(racer.team).push(racer);
    });

    const teams = Array.from(teamsMap.keys());
    while (teams.length > 1) {
      const team1 = teams.shift();
      const team2 = teams.pop();
      const racer1 = teamsMap.get(team1).pop();
      const racer2 = teamsMap.get(team2).pop();
      newPairings.push({ racer1, racer2, winner: null });
      if (teamsMap.get(team1).length === 0) teamsMap.delete(team1);
      if (teamsMap.get(team2).length === 0) teamsMap.delete(team2);
      unpairedRacers = unpairedRacers.filter(r => r !== racer1 && r !== racer2);
    }

    // Pair remaining racers
    while (unpairedRacers.length > 1) {
      const racer1 = unpairedRacers.shift();
      const racer2 = unpairedRacers.shift();
      newPairings.push({ racer1, racer2, winner: null });
    }

    // Handle bye run if there's an odd number of racers
    if (unpairedRacers.length === 1) {
      const byeRacer = unpairedRacers[0];
      
      // Find the racer with the least bye runs
      let leastByeRuns = Infinity;
      let leastByeRacer = byeRacer;

      for (const pairing of newPairings) {
        for (const racer of [pairing.racer1, pairing.racer2]) {
          const racerByeRuns = byeRunHistory[racer.name] || 0;
          if (racerByeRuns < leastByeRuns) {
            leastByeRuns = racerByeRuns;
            leastByeRacer = racer;
          }
        }
      }

      // Swap the bye racer with the racer with the least bye runs
      if (leastByeRacer !== byeRacer) {
        const pairingToSwap = newPairings.find(p => p.racer1 === leastByeRacer || p.racer2 === leastByeRacer);
        if (pairingToSwap.racer1 === leastByeRacer) {
          pairingToSwap.racer1 = byeRacer;
        } else {
          pairingToSwap.racer2 = byeRacer;
        }
      }

      newPairings.push({
        racer1: leastByeRacer,
        racer2: { name: 'Bye', team: 'Bye' },
        winner: leastByeRacer
      });

      // Update bye run history
      byeRunHistory[leastByeRacer.name] = (byeRunHistory[leastByeRacer.name] || 0) + 1;
      localStorage.setItem(`byeRunHistory_${effectiveEventId}_${currentClass}`, JSON.stringify(byeRunHistory));
    }

    console.log('Created pairings:', newPairings);
    setPairings(newPairings);
  };

  const selectWinner = (index, winner) => {
    const updatedPairings = [...pairings];
    updatedPairings[index].winner = winner;
    setPairings(updatedPairings);
    updateSharedData();
  };

  const processRoundResults = () => {
    const winners = pairings.map(pair => pair.winner).filter(Boolean);
    if (winners.length > 1) {
      setBag(winners);
      setPairings([]);
      setRound(round + 1);
      
      // Save round results to event history
      const roundResults = {
        round: round,
        pairings: pairings
      };
      const storedHistory = JSON.parse(localStorage.getItem(`eventHistory_${effectiveEventId}`) || '[]');
      const updatedHistory = [...storedHistory, roundResults];
      localStorage.setItem(`eventHistory_${effectiveEventId}`, JSON.stringify(updatedHistory));
    } else if (winners.length === 1) {
      alert(`The winner is ${winners[0].name}!`);
      // Save final result to event history
      const finalResult = {
        round: round,
        pairings: pairings,
        winner: winners[0]
      };
      const storedHistory = JSON.parse(localStorage.getItem(`eventHistory_${effectiveEventId}`) || '[]');
      const updatedHistory = [...storedHistory, finalResult];
      localStorage.setItem(`eventHistory_${effectiveEventId}`, JSON.stringify(updatedHistory));
    } else {
      alert('Please select winners for all races before processing results.');
    }
    updateSharedData();
  };

  const resetDrawing = () => {
    setBag([]);
    setPairings([]);
    setCurrentClass('');
    setShareLink('');
    setRound(1);
    localStorage.removeItem(`drawingState_${effectiveEventId}`);
    localStorage.removeItem(`byeRunHistory_${effectiveEventId}_${currentClass}`);
  };

  const generateShareLink = () => {
    try {
      const newShareId = uuidv4();
      setShareId(newShareId);
      
      const link = `${window.location.origin}/events/${effectiveEventId}/draw/shared/${newShareId}`;
      setShareLink(link);
      console.log('Generated share link:', link);
      console.log('ShareId:', newShareId);
      console.log('EffectiveEventId:', effectiveEventId);

      updateSharedData();
    } catch (error) {
      console.error('Error generating share link:', error);
    }
  };

  const navigateToRaceResults = () => {
    navigate(`/events/${effectiveEventId}/results`);
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Chip Drawing - Round {round}</Header>
        
        <Section>
          <SectionHeader><FaDice /> Controls</SectionHeader>
          <ControlsContainer>
            <StyledSelect 
              value={currentClass} 
              onChange={(e) => setCurrentClass(e.target.value)}
            >
              <option value="">Select Class</option>
              {classes.map((cls, index) => (
                <option key={index} value={cls}>{cls}</option>
              ))}
            </StyledSelect>
            <StyledButton onClick={initializeBag} theme={theme}>
              <FaDice /> Initialize Bag
            </StyledButton>
            <StyledButton onClick={drawAllChips} disabled={bag.length === 0} theme={theme}>
              <FaDice /> Draw All Chips
            </StyledButton>
            <StyledButton onClick={resetDrawing} theme={theme}>
              <FaRedo /> Reset Drawing
            </StyledButton>
            <StyledButton onClick={generateShareLink} theme={theme}>
              <FaShare /> Generate Share Link
            </StyledButton>
            <StyledButton onClick={() => setShowLiveTiming(!showLiveTiming)} theme={theme}>
              <FaClock /> {showLiveTiming ? 'Hide Live Timing' : 'Show Live Timing'}
            </StyledButton>
          </ControlsContainer>
          {shareLink && (
            <ShareLinkInput 
              type="text" 
              value={shareLink} 
              readOnly 
              onClick={(e) => e.target.select()} 
            />
          )}
        </Section>
        
        <Section>
          <SectionHeader><FaDice /> Bag</SectionHeader>
          <List>
            {bag.map((racer, index) => (
              <ListItem key={index}>
                <RacerInfo>
                  <span><strong>{racer.name}</strong></span>
                  <span>{racer.team}</span>
                  <span>{racer.class}</span>
                </RacerInfo>
              </ListItem>
            ))}
          </List>
        </Section>

        <Section>
          <SectionHeader><FaTrophy /> Pairings</SectionHeader>
          <List>
            {pairings.map((pair, index) => (
              <ListItem key={index}>
                <RacerInfo>
                  {pair.racer2.name === 'Bye' ? (
                    <>
                      <span><strong>{pair.racer1.name}</strong></span>
                      <span>{pair.racer1.team}</span>
                      <span>Bye Run</span>
                    </>
                  ) : (
                    <>
                      <span><strong>{pair.racer1.name}</strong> vs <strong>{pair.racer2.name}</strong></span>
                      <span>{pair.racer1.team} vs {pair.racer2.team}</span>
                    </>
                  )}
                </RacerInfo>
                {pair.racer2.name !== 'Bye' && (
                  <WinnerSelect
                    value={pair.winner ? pair.winner.name : ''}
                    onChange={(e) => selectWinner(index, e.target.value === pair.racer1.name ? pair.racer1 : pair.racer2)}
                  >
                    <option value="">Select Winner</option>
                    <option value={pair.racer1.name}>{pair.racer1.name}</option>
                    <option value={pair.racer2.name}>{pair.racer2.name}</option>
                  </WinnerSelect>
                )}
              </ListItem>
            ))}
          </List>
        </Section>

        <Section>
          <SectionHeader><FaTrophy /> Round Results</SectionHeader>
          <List>
            {pairings.map((pair, index) => (
              <ListItem key={index}>
                <RacerInfo>
                  <span>Race {index + 1}:</span>
                  {pair.racer2.name === 'Bye' ? (
                    <span><strong>{pair.racer1.name}</strong> - Bye Run (Advances)</span>
                  ) : (
                    <span>
                      {pair.winner ? <strong>{pair.winner.name} wins</strong> : 'Not decided'}
                    </span>
                  )}
                </RacerInfo>
              </ListItem>
            ))}
          </List>
        </Section>

        <ButtonContainer>
          {pairings.length > 0 && (
            <StyledButton onClick={processRoundResults} theme={theme}>
              <FaTrophy /> Process Round Results
            </StyledButton>
          )}
          <StyledButton onClick={navigateToRaceResults} theme={theme}>
            <FaFlag /> Go to Race Results
          </StyledButton>
        </ButtonContainer>

        {showLiveTiming && <LiveTimingDisplay eventId={effectiveEventId} />}
      </Container>
    </PageContainer>
  );
}

export default ChipDrawing;
