import React from 'react';

function EventProgress({ event }) {
  return (
    <div>
      <h2>Event Progress</h2>
      {/* Implement event progress display logic here */}
    </div>
  );
}

export default EventProgress;