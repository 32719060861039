import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Container, Header, Button, Input } from './SharedStyles';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaPaperPlane } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from './Navigation';

const PageTitle = styled(motion.h1)`
  font-size: 2.5rem;
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

const Section = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.primary};
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.75rem;
  color: ${props => props.theme.text};
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${props => props.theme.text};

  svg {
    margin-right: 0.5rem;
    color: ${props => props.theme.primary};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  resize: vertical;
  min-height: 100px;
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const PageContent = styled.div`
  padding-top: 80px;
`;

const ContactPage = () => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send data to backend)
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <>
      <Navigation />
      <PageContent>
        <Container>
          <PageTitle
            theme={theme}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Contact Us
          </PageTitle>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <SubTitle theme={theme}><FaEnvelope /> Get in Touch</SubTitle>
            <Paragraph theme={theme}>We'd love to hear from you! Whether you have questions about our app, need support, or want to share your racing experiences, don't hesitate to reach out.</Paragraph>
          </Section>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <SubTitle theme={theme}><FaMapMarkerAlt /> Contact Information</SubTitle>
            <ContactInfo>
              <ContactItem theme={theme}><FaEnvelope /> info@noprepchipdrawing.com</ContactItem>
              <ContactItem theme={theme}><FaPhone /> +1 (555) 123-4567</ContactItem>
              <ContactItem theme={theme}><FaMapMarkerAlt /> 123 Race Street, Speed City, FL 12345</ContactItem>
            </ContactInfo>
          </Section>
          
          <Section
            theme={theme}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <SubTitle theme={theme}><FaPaperPlane /> Send Us a Message</SubTitle>
            <Form onSubmit={handleSubmit}>
              <Input
                theme={theme}
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Input
                theme={theme}
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextArea
                theme={theme}
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <SubmitButton type="submit" theme={theme}>Send Message</SubmitButton>
            </Form>
          </Section>
        </Container>
      </PageContent>
    </>
  );
};

export default ContactPage;