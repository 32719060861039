import React, { useState } from 'react';
import styled from 'styled-components';
import { FaFlag, FaClock, FaEdit, FaTrash, FaInfoCircle } from 'react-icons/fa';

const ScheduleContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  color: ${props => props.theme.text};
`;

const ScheduleHeader = styled.h2`
  color: ${props => props.theme.buttonText};
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: 700;
`;

const SectionHeader = styled.h3`
  color: ${props => props.theme.buttonText};
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.buttonText};
  padding-bottom: 0.5rem;
`;

// Add this new styled component
const SubSectionHeader = styled.h4`
  color: ${props => props.theme.text};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
`;

const ScheduleGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const ScheduleItem = styled.div`
  background-color: ${props => props.theme.mode === 'light' ? props.theme.primary : props.theme.buttonBackground};
  color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

const RoundType = styled.h4`
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const TimeInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const Notes = styled.div`
  color: white;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;

  svg {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  color: white;
  font-size: 1.1rem;
  
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`;

function ViewSchedule({ eventId, scheduleItems, onEdit, onDelete, theme }) {
  const [expandedNotes, setExpandedNotes] = useState({});

  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString([], options);
  };

  const toggleNotes = (itemId) => {
    setExpandedNotes(prev => ({ ...prev, [itemId]: !prev[itemId] }));
  };

  // Group schedule items by raceName only
  const groupedScheduleItems = scheduleItems.reduce((acc, item) => {
    if (!acc[item.raceName]) {
      acc[item.raceName] = [];
    }
    acc[item.raceName].push(item);
    return acc;
  }, {});

  const handleDelete = (itemId) => {
    console.log('Deleting item with id:', itemId);
    onDelete(itemId);
  };

  return (
    <ScheduleContainer theme={theme}>
      <ScheduleHeader theme={theme}>Event Schedule</ScheduleHeader>
      {Object.entries(groupedScheduleItems).map(([raceName, items]) => (
        <div key={raceName}>
          <SectionHeader theme={theme}>{raceName}</SectionHeader>
          <ScheduleGrid>
            {items.map((item) => (
              <ScheduleItem key={item.id} theme={theme}>
                <RoundType theme={theme}>{item.roundType}</RoundType>
                <TimeInfo theme={theme}>
                  <FaClock /> {formatTime(item.startTime)} - {formatTime(item.endTime)}
                </TimeInfo>
                {item.notes && (
                  <Notes theme={theme}>
                    <FaInfoCircle />
                    <div>
                      {expandedNotes[item.id] ? item.notes : `${item.notes.slice(0, 50)}...`}
                      {item.notes.length > 50 && (
                        <ActionButton onClick={() => toggleNotes(item.id)} theme={theme}>
                          {expandedNotes[item.id] ? 'Show less' : 'Show more'}
                        </ActionButton>
                      )}
                    </div>
                  </Notes>
                )}
                <ActionButtons>
                  <ActionButton onClick={() => onEdit(item)} theme={theme}>
                    <FaEdit />
                  </ActionButton>
                  <ActionButton onClick={() => handleDelete(item.id)} theme={theme}>
                    <FaTrash />
                  </ActionButton>
                </ActionButtons>
              </ScheduleItem>
            ))}
          </ScheduleGrid>
        </div>
      ))}
    </ScheduleContainer>
  );
}

export default ViewSchedule;