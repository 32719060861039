import React from 'react';
import styled from 'styled-components';
import { Select } from './SharedStyles';

const SelectorContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

export const trackDatabase = [
  { id: '1', name: '710 Dragway', location: 'Rowland, North Carolina, USA', lat: 34.5388, lon: -79.2903, type: '1/8 mile' },
  { id: '2', name: 'Accelaquarter Raceway', location: 'Harrisburg, Illinois, USA', lat: 37.7383, lon: -88.5478, type: '1/4 mile' },
  { id: '3', name: 'Alabama International Dragway', location: 'Baileyton, Alabama, USA', lat: 34.0019, lon: -86.0611, type: '1/8 mile' },
  { id: '4', name: 'Alamo City Motorplex', location: 'Marion, Texas, USA', lat: 29.5852, lon: -98.1647, type: '1/4 mile' },
  { id: '5', name: 'Alaska Raceway Park', location: 'Palmer, Alaska, USA', lat: 61.6002, lon: -149.1375, type: '1/4 mile' },
  { id: '6', name: 'Albany-Saratoga Speedway', location: 'Malta, New York, USA', lat: 43.0287, lon: -73.7912, type: '1/4 mile' },
  { id: '7', name: 'Albuquerque Dragway', location: 'Albuquerque, New Mexico, USA', lat: 34.9927, lon: -106.6185, type: '1/4 mile' },
  { id: '8', name: 'Alien Raceway', location: 'Roswell, New Mexico, USA', lat: 33.3943, lon: -104.5230, type: '1/4 mile' },
  { id: '9', name: 'Alton Motorplex', location: 'Alton, Virginia, USA', lat: 36.5722, lon: -79.0036, type: '1/8 mile' },
  { id: '10', name: 'Amarillo Dragway', location: 'Amarillo, Texas, USA', lat: 35.1107, lon: -101.8313, type: '1/4 mile' },
  { id: '11', name: 'Ardmore Dragway', location: 'Ardmore, Oklahoma, USA', lat: 34.1746, lon: -97.1294, type: '1/8 mile' },
  { id: '12', name: 'Atco Dragway', location: 'Atco, New Jersey, USA', lat: 39.7684, lon: -74.8660, type: '1/4 mile' },
  { id: '13', name: 'Atlanta Dragway', location: 'Commerce, Georgia, USA', lat: 34.2724, lon: -83.4598, type: '1/4 mile' },
  { id: '14', name: 'Atmore Dragway', location: 'Atmore, Alabama, USA', lat: 31.0243, lon: -87.4963, type: '1/8 mile' },
  { id: '15', name: 'Auto Club Famoso Raceway', location: 'Bakersfield, California, USA', lat: 35.5201, lon: -119.0567, type: '1/4 mile' },
  { id: '16', name: 'Auto Club Raceway at Pomona', location: 'Pomona, California, USA', lat: 34.0853, lon: -117.7461, type: '1/4 mile' },
  { id: '17', name: 'Autódromo de Buenos Aires', location: 'Buenos Aires, Argentina', lat: -34.6877, lon: -58.5079, type: '1/4 mile' },
  { id: '18', name: 'Autódromo Internacional de Curitiba', location: 'Curitiba, Brazil', lat: -25.4373, lon: -49.2795, type: '1/4 mile' },
  { id: '19', name: 'Autódromo Internacional de Guaporé', location: 'Guaporé, Brazil', lat: -28.8444, lon: -51.8897, type: '1/8 mile' },
  { id: '20', name: 'Bahrain International Circuit', location: 'Sakhir, Bahrain', lat: 26.0325, lon: 50.5106, type: '1/4 mile' },
  { id: '21', name: 'Baileyton Drag Strip', location: 'Baileyton, Alabama, USA', lat: 34.3061, lon: -86.6031, type: '1/8 mile' },
  { id: '22', name: 'Bandimere Speedway', location: 'Morrison, Colorado, USA', lat: 39.6347, lon: -105.1915, type: '1/4 mile' },
  { id: '23', name: 'Barbados Motorsport Park', location: 'St. Philip, Barbados', lat: 13.1032, lon: -59.5262, type: '1/4 mile' },
  { id: '24', name: 'Beacon Dragway', location: 'Paducah, Kentucky, USA', lat: 37.0013, lon: -88.7261, type: '1/8 mile' },
  { id: '25', name: 'Beech Bend Raceway Park', location: 'Bowling Green, Kentucky, USA', lat: 37.0067, lon: -86.4357, type: '1/4 mile' },
  { id: '26', name: 'Benton County Dragway', location: 'Blodgett, Oregon, USA', lat: 44.5852, lon: -123.5574, type: '1/8 mile' },
  { id: '27', name: 'Billings Motorsports Park', location: 'Billings, Montana, USA', lat: 45.7833, lon: -108.5007, type: '1/4 mile' },
  { id: '28', name: 'Bonne Terre Drag Strip', location: 'Bonne Terre, Missouri, USA', lat: 37.9231, lon: -90.5554, type: '1/8 mile' },
  { id: '29', name: 'Bradenton Motorsports Park', location: 'Bradenton, Florida, USA', lat: 27.4582, lon: -82.3517, type: '1/4 mile' },
  { id: '30', name: 'Brainerd International Raceway', location: 'Brainerd, Minnesota, USA', lat: 46.3965, lon: -94.2887, type: '1/4 mile' },
  { id: '31', name: 'Breakaway Dragway', location: 'Ecorse, Michigan, USA', lat: 42.2442, lon: -83.1499, type: '1/8 mile' },
  { id: '32', name: 'Brewton Motorsports Park', location: 'Brewton, Alabama, USA', lat: 31.1054, lon: -87.0722, type: '1/8 mile' },
  { id: '33', name: 'Bristol Dragway', location: 'Bristol, Tennessee, USA', lat: 36.5164, lon: -82.2662, type: '1/4 mile' },
  { id: '34', name: 'Brownsburg Raceway Park', location: 'Brownsburg, Indiana, USA', lat: 39.8456, lon: -86.3972, type: '1/4 mile' },
  { id: '35', name: 'Bunker Hill Dragstrip', location: 'Bunker Hill, Indiana, USA', lat: 40.6616, lon: -86.0944, type: '1/8 mile' },
  { id: '36', name: 'Bushy Park Racing Circuit', location: 'St. Philip, Barbados', lat: 13.1568, lon: -59.4721, type: '1/8 mile' },
  { id: '37', name: 'Byron Dragway', location: 'Byron, Illinois, USA', lat: 42.1278, lon: -89.2559, type: '1/4 mile' },
  { id: '38', name: 'Carolina Dragway', location: 'Aiken, South Carolina, USA', lat: 33.3776, lon: -81.9093, type: '1/4 mile' },
  { id: '39', name: 'Castrol Raceway', location: 'Edmonton, Alberta, Canada', lat: 53.3635, lon: -113.5946, type: '1/4 mile' },
  { id: '40', name: 'Centerville Dragway', location: 'Centerville, Arkansas, USA', lat: 35.3016, lon: -93.0548, type: '1/8 mile' },
  { id: '41', name: 'Circuit de la Guadeloupe', location: 'Baie-Mahault, Guadeloupe', lat: 16.2650, lon: -61.5044, type: '1/8 mile' },
  { id: '42', name: 'Cordova International Raceway', location: 'Cordova, Illinois, USA', lat: 41.6661, lon: -90.3197, type: '1/4 mile' },
  { id: '43', name: 'Crossville Dragway', location: 'Crossville, Tennessee, USA', lat: 35.9461, lon: -85.0623, type: '1/8 mile' },
  { id: '44', name: 'Darlington Dragway', location: 'Hartsville, South Carolina, USA', lat: 34.3417, lon: -79.9129, type: '1/4 mile' },
  { id: '45', name: 'Eddyville Raceway Park', location: 'Oskaloosa, Iowa, USA', lat: 41.2025, lon: -92.7499, type: '1/8 mile' },
  { id: '46', name: 'Famoso Raceway', location: 'McFarland, California, USA', lat: 35.5201, lon: -119.0567, type: '1/4 mile' },
  { id: '47', name: 'Farmington Dragway', location: 'Mocksville, North Carolina, USA', lat: 36.0016, lon: -80.5061, type: '1/8 mile' },
  { id: '48', name: 'Firebird Raceway', location: 'Eagle, Idaho, USA', lat: 43.7396, lon: -116.4407, type: '1/4 mile' },
  { id: '49', name: 'Gainesville Raceway', location: 'Gainesville, Florida, USA', lat: 29.7708, lon: -82.2631, type: '1/4 mile' },
  { id: '50', name: 'Gateway Motorsports Park', location: 'Madison, Illinois, USA', lat: 38.6531, lon: -90.1223, type: '1/4 mile' },
  { id: '51', name: 'Grand Bend Motorplex', location: 'Grand Bend, Ontario, Canada', lat: 43.2911, lon: -81.7182, type: '1/4 mile' },
  { id: '52', name: 'Great Lakes Dragaway', location: 'Union Grove, Wisconsin, USA', lat: 42.5814, lon: -88.0543, type: '1/4 mile' },
  { id: '53', name: 'Grove Creek Raceway', location: 'Grove City, Minnesota, USA', lat: 45.1117, lon: -94.4153, type: '1/8 mile' },
  { id: '54', name: 'Gulfport Dragway', location: 'Gulfport, Mississippi, USA', lat: 30.4117, lon: -89.0978, type: '1/4 mile' },
  { id: '55', name: 'Heartland Motorsports Park', location: 'Topeka, Kansas, USA', lat: 39.0669, lon: -95.6681, type: '1/4 mile' },
  { id: '56', name: 'Hilo Dragstrip', location: 'Hilo, Hawaii, USA', lat: 19.7241, lon: -155.0870, type: '1/4 mile' },
  { id: '57', name: 'Hockenheimring', location: 'Hockenheim, Germany', lat: 49.3278, lon: 8.5619, type: '1/4 mile' },
  { id: '58', name: 'Holiday Raceway', location: 'Woodstock, Alabama, USA', lat: 33.6443, lon: -87.1824, type: '1/8 mile' },
  { id: '59', name: 'Houston Raceway Park', location: 'Baytown, Texas, USA', lat: 29.7748, lon: -94.9276, type: '1/4 mile' },
  { id: '60', name: 'Hub City Dragway', location: 'Hattiesburg, Mississippi, USA', lat: 31.2288, lon: -89.2657, type: '1/8 mile' },
  { id: '61', name: 'Huntsville Dragway', location: 'Huntsville, Alabama, USA', lat: 34.8813, lon: -86.5742, type: '1/8 mile' },
  { id: '62', name: 'Jamestown Drag Racing', location: 'Jamestown, North Dakota, USA', lat: 46.9063, lon: -98.7031, type: '1/8 mile' },
  { id: '63', name: 'Jamwest Motorsports and Adventure Park', location: 'Negril, Jamaica', lat: 18.2519, lon: -78.2777, type: '1/4 mile' },
  { id: '64', name: 'Julesburg Drag Strip', location: 'Julesburg, Colorado, USA', lat: 40.9681, lon: -102.2715, type: '1/4 mile' },
  { id: '65', name: 'Kanawha Valley Motorsports Park', location: 'Charleston, West Virginia, USA', lat: 38.3872, lon: -81.7826, type: '1/8 mile' },
  { id: '66', name: 'Kansas International Dragway', location: 'Maize, Kansas, USA', lat: 37.8469, lon: -97.5487, type: '1/4 mile' },
  { id: '67', name: 'Kearney Raceway Park', location: 'Kearney, Nebraska, USA', lat: 40.6955, lon: -99.1128, type: '1/8 mile' },
  { id: '68', name: 'Knoxville Dragstrip', location: 'Knoxville, Tennessee, USA', lat: 36.0476, lon: -83.9209, type: '1/8 mile' },
  { id: '69', name: 'Lebanon Valley Dragway', location: 'West Lebanon, New York, USA', lat: 42.4723, lon: -73.3839, type: '1/4 mile' },
  { id: '70', name: 'London Dragway', location: 'London, Kentucky, USA', lat: 37.1287, lon: -83.9637, type: '1/8 mile' },
  { id: '71', name: 'Lost Creek Raceway', location: 'Anaconda, Montana, USA', lat: 46.2660, lon: -112.6791, type: '1/4 mile' },
  { id: '72', name: 'Lucas Oil Raceway', location: 'Indianapolis, Indiana, USA', lat: 39.7950, lon: -86.3405, type: '1/4 mile' },
  { id: '73', name: 'Madras Dragstrip', location: 'Madras, Oregon, USA', lat: 44.6334, lon: -121.1373, type: '1/8 mile' },
  { id: '74', name: 'Magic City International Dragway', location: 'Minot, North Dakota, USA', lat: 48.2587, lon: -101.3207, type: '1/4 mile' },
  { id: '75', name: 'Maple Grove Raceway', location: 'Mohnton, Pennsylvania, USA', lat: 40.2251, lon: -75.9701, type: '1/4 mile' },
  { id: '76', name: 'Memphis International Raceway', location: 'Millington, Tennessee, USA', lat: 35.3325, lon: -89.9566, type: '1/4 mile' },
  { id: '77', name: 'Mid Michigan Motorplex', location: 'Stanton, Michigan, USA', lat: 43.1911, lon: -84.9983, type: '1/4 mile' },
  { id: '78', name: 'Milan Dragway', location: 'Milan, Michigan, USA', lat: 42.0722, lon: -83.6158, type: '1/4 mile' },
  { id: '79', name: 'Muncie Dragway', location: 'Albany, Indiana, USA', lat: 40.2420, lon: -85.2072, type: '1/8 mile' },
  { id: '80', name: 'Music City Raceway', location: 'Nashville, Tennessee, USA', lat: 36.3223, lon: -86.8320, type: '1/8 mile' },
  { id: '81', name: 'National Trail Raceway', location: 'Hebron, Ohio, USA', lat: 39.9908, lon: -82.6147, type: '1/4 mile' },
  { id: '82', name: 'New England Dragway', location: 'Epping, New Hampshire, USA', lat: 42.9992, lon: -71.2277, type: '1/4 mile' },
  { id: '83', name: 'New York International Raceway Park', location: 'Leicester, New York, USA', lat: 43.0964, lon: -77.9658, type: '1/4 mile' },
  { id: '84', name: 'No Problem Raceway', location: 'Belle Rose, Louisiana, USA', lat: 30.0547, lon: -90.7898, type: '1/4 mile' },
  { id: '85', name: 'Numidia Dragway', location: 'Numidia, Pennsylvania, USA', lat: 40.8870, lon: -76.3911, type: '1/4 mile' },
  { id: '86', name: 'Oahe Speedway', location: 'Pierre, South Dakota, USA', lat: 44.5179, lon: -100.4111, type: '1/4 mile' },
  { id: '87', name: 'Old Bridge Township Raceway Park', location: 'Englishtown, New Jersey, USA', lat: 40.3654, lon: -74.3638, type: '1/4 mile' },
  { id: '88', name: 'Orlando Speed World Dragway', location: 'Orlando, Florida, USA', lat: 28.5533, lon: -81.0912, type: '1/4 mile' },
  { id: '89', name: 'Ozark Raceway Park', location: 'Rogersville, Missouri, USA', lat: 37.1742, lon: -93.1597, type: '1/4 mile' },
  { id: '90', name: 'Pacific Raceways', location: 'Kent, Washington, USA', lat: 47.3134, lon: -122.1415, type: '1/4 mile' },
  { id: '91', name: 'Palm Beach International Raceway', location: 'Jupiter, Florida, USA', lat: 26.9194, lon: -80.3643, type: '1/4 mile' },
  { id: '92', name: 'Palomarga International Raceway Park', location: 'San Nicolas, Aruba', lat: 12.4325, lon: -69.9050, type: '1/4 mile' },
  { id: '93', name: 'Paradise Drag Strip', location: 'Calhoun, Georgia, USA', lat: 34.3226, lon: -84.9113, type: '1/8 mile' },
  { id: '94', name: 'Prescott Raceway', location: 'Prescott, Arkansas, USA', lat: 33.7926, lon: -93.3604, type: '1/8 mile' },
  { id: '95', name: 'Rock Falls Raceway', location: 'Eau Claire, Wisconsin, USA', lat: 44.7928, lon: -91.4122, type: '1/4 mile' },
  { id: '96', name: 'Rockingham Dragway', location: 'Rockingham, North Carolina, USA', lat: 34.9347, lon: -79.7194, type: '1/4 mile' },
  { id: '97', name: 'Rocky Mountain Raceways', location: 'West Valley City, Utah, USA', lat: 40.7146, lon: -112.0659, type: '1/4 mile' },
  { id: '98', name: 'Route 66 Raceway', location: 'Joliet, Illinois, USA', lat: 41.4620, lon: -88.0578, type: '1/4 mile' },
  { id: '99', name: 'S.R.C.A. Dragstrip', location: 'Great Bend, Kansas, USA', lat: 38.8281, lon: -99.3441, type: '1/4 mile' },
  { id: '100', name: 'Sacramento Raceway Park', location: 'Sacramento, California, USA', lat: 38.5138, lon: -121.2724, type: '1/4 mile' },
  { id: '101', name: 'Sakakawea Dragway', location: 'New Town, North Dakota, USA', lat: 47.3113, lon: -101.6224, type: '1/8 mile' },
  { id: '102', name: 'Salinas Speedway', location: 'Salinas, Puerto Rico', lat: 17.9784, lon: -66.2976, type: '1/4 mile' },
  { id: '103', name: 'San Antonio Raceway', location: 'Marion, Texas, USA', lat: 29.3333, lon: -98.0016, type: '1/4 mile' },
  { id: '104', name: 'San Juan Raceway', location: 'San Juan, Puerto Rico', lat: 18.4095, lon: -66.1474, type: '1/4 mile' },
  { id: '105', name: 'Sanair Super Speedway', location: 'Saint-Pie, Quebec, Canada', lat: 45.5291, lon: -72.8837, type: '1/4 mile' },
  { id: '106', name: 'Santa Pod Raceway', location: 'Wellingborough, UK', lat: 52.2366, lon: -0.5957, type: '1/4 mile' },
  { id: '107', name: 'Seekonk Speedway', location: 'Seekonk, Massachusetts, USA', lat: 41.8605, lon: -71.3429, type: '1/8 mile' },
  { id: '108', name: 'Silver Dollar Motorsports Park', location: 'Reynolds, Georgia, USA', lat: 32.4018, lon: -84.0534, type: '1/4 mile' },
  { id: '109', name: 'Silver Drag Strip', location: 'Eastover, South Carolina, USA', lat: 34.0187, lon: -80.3557, type: '1/8 mile' },
  { id: '110', name: 'Sonoma Raceway', location: 'Sonoma, California, USA', lat: 38.1617, lon: -122.4543, type: '1/4 mile' },
  { id: '111', name: 'South Georgia Motorsports Park', location: 'Adel, Georgia, USA', lat: 31.0318, lon: -83.5859, type: '1/4 mile' },
  { id: '112', name: 'Southwestern International Raceway', location: 'Tucson, Arizona, USA', lat: 32.0586, lon: -110.7992, type: '1/4 mile' },
  { id: '113', name: 'St. Croix Motor Sports Complex', location: 'St. Croix, U.S. Virgin Islands', lat: 17.7355, lon: -64.7550, type: '1/4 mile' },
  { id: '114', name: 'State Capitol Raceway', location: 'Port Allen, Louisiana, USA', lat: 30.4719, lon: -91.2332, type: '1/4 mile' },
  { id: '115', name: 'Sumerduck Dragway', location: 'Sumerduck, Virginia, USA', lat: 38.4527, lon: -77.7312, type: '1/8 mile' },
  { id: '116', name: 'Summit Motorsports Park', location: 'Norwalk, Ohio, USA', lat: 41.2507, lon: -82.6066, type: '1/4 mile' },
  { id: '117', name: 'Sydney Dragway', location: 'Eastern Creek, NSW, Australia', lat: -33.8136, lon: 150.8711, type: '1/4 mile' },
  { id: '118', name: 'Texas Motorplex', location: 'Ennis, Texas, USA', lat: 32.3815, lon: -96.7449, type: '1/4 mile' },
  { id: '119', name: 'The Strip at Las Vegas Motor Speedway', location: 'Las Vegas, Nevada, USA', lat: 36.2713, lon: -115.0111, type: '1/4 mile' },
  { id: '120', name: 'Thunder Valley Raceway Park', location: 'Noble, Oklahoma, USA', lat: 35.1903, lon: -97.5433, type: '1/4 mile' },
  { id: '121', name: 'Top Gun Raceway', location: 'Fallon, Nevada, USA', lat: 39.3722, lon: -118.7601, type: '1/4 mile' },
  { id: '122', name: 'Toronto Motorsports Park', location: 'Cayuga, Ontario, Canada', lat: 42.90222, lon: -79.85278, type: '1/4 mile' },
  { id: '123', name: 'Tri-State Raceway', location: 'Earlville, Iowa, USA', lat: 42.5633, lon: -91.5232, type: '1/4 mile' },
  { id: '124', name: 'Trinidad Raceway', location: 'Camden, Couva, Trinidad and Tobago', lat: 10.4182, lon: -61.4624, type: '1/4 mile' },
  { id: '125', name: 'Tucson Dragway', location: 'Tucson, Arizona, USA', lat: 32.0649, lon: -110.8487, type: '1/4 mile' },
  { id: '126', name: 'Tulsa Raceway Park', location: 'Tulsa, Oklahoma, USA', lat: 36.2286, lon: -95.8547, type: '1/4 mile' },
  { id: '127', name: 'US 13 Dragway', location: 'Delmar, Delaware, USA', lat: 38.5411, lon: -75.5698, type: '1/4 mile' },
  { id: '128', name: 'US 131 Motorsports Park', location: 'Martin, Michigan, USA', lat: 42.4981, lon: -85.6426, type: '1/4 mile' },
  { id: '129', name: 'US 41 Dragstrip', location: 'Morocco, Indiana, USA', lat: 41.1755, lon: -87.3039, type: '1/4 mile' },
  { id: '130', name: "Vermont's Thunder Road", location: 'Barre, Vermont, USA', lat: 44.2015, lon: -72.5742, type: '1/8 mile' },
  { id: '131', name: 'Virginia Motorsports Park', location: 'North Dinwiddie, Virginia, USA', lat: 37.1910, lon: -77.5439, type: '1/4 mile' },
  { id: '132', name: 'Viva CanAm Raceway', location: 'Freeport, Bahamas', lat: 26.5275, lon: -78.6975, type: '1/8 mile' },
  { id: '133', name: 'Wallerfield International Raceway', location: 'Arima, Trinidad and Tobago', lat: 10.6376, lon: -61.2870, type: '1/4 mile' },
  { id: '134', name: 'Ware Shoals Dragway', location: 'Ware Shoals, South Carolina, USA', lat: 34.3924, lon: -82.2381, type: '1/8 mile' },
  { id: '135', name: 'Wild Horse Pass Motorsports Park', location: 'Chandler, Arizona, USA', lat: 33.2766, lon: -111.9734, type: '1/4 mile' },
  { id: '136', name: 'Wisconsin International Raceway', location: 'Kaukauna, Wisconsin, USA', lat: 44.2410, lon: -88.2614, type: '1/4 mile' },
  { id: '143', name: 'Winterport Dragway', location: 'Winterport, Maine, USA', lat: 44.6395, lon: -68.8543, type: '1/8 mile' },
  { id: '144', name: 'Woodburn Dragstrip', location: 'Woodburn, Oregon, USA', lat: 45.1715, lon: -122.8176, type: '1/4 mile' },
  { id: '145', name: 'Wyoming Drag Racing Association (WDRA)', location: 'Casper, Wyoming, USA', lat: 42.8666, lon: -106.3131, type: '1/8 mile' },
  { id: '146', name: 'Yellowstone Drag Strip', location: 'Acton, Montana, USA', lat: 45.8586, lon: -108.5227, type: '1/4 mile' },
  { id: '147', name: 'ZMAX Dragway', location: 'Concord, North Carolina, USA', lat: 35.3578, lon: -80.6839, type: '1/4 mile' },
];

const TrackSelector = ({ onSelect }) => {
  const handleChange = (e) => {
    const selectedTrack = trackDatabase.find(track => track.id === e.target.value);
    onSelect(selectedTrack);
  };

  return (
    <SelectorContainer>
      <StyledSelect onChange={handleChange}>
        <option value="">-- Select a track --</option>
        {trackDatabase.map((track) => (
          <option key={track.id} value={track.id}>
            {track.name} - {track.location} ({track.type})
          </option>
        ))}
      </StyledSelect>
    </SelectorContainer>
  );
};

export default TrackSelector;