import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Button } from './SharedStyles';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.background};
  padding: 2rem;
  border-radius: 8px;
  z-index: 1000;
  max-width: 500px;
  width: 90%;
`;

const PrintPreviewModal = ({ onPrint, onClose }) => {
  const { theme } = useTheme();

  return (
    <ModalContainer theme={theme}>
      <h3>Print Preview</h3>
      {/* Add options to customize printout */}
      <Button onClick={onPrint} theme={theme}>Print</Button>
      <Button onClick={onClose} theme={theme}>Cancel</Button>
    </ModalContainer>
  );
};

export default PrintPreviewModal;
