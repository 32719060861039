import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from "firebase/database";
import { database } from '../firebase';
import styled from 'styled-components';
import { PageContainer, Container, Header, List } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaDice, FaTrophy } from 'react-icons/fa';

const Section = styled.section`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${props => props.theme.background};
  border-bottom: 1px solid ${props => props.theme.border};
  &:last-child {
    border-bottom: none;
  }
`;

const RacerInfo = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.1rem;
`;

function SharedChipDrawingView() {
  const { eventId, shareId } = useParams();
  const [sharedData, setSharedData] = useState(null);
  const [error, setError] = useState(null);
  const { theme } = useTheme();

  useEffect(() => {
    console.log('SharedChipDrawingView - EventId:', eventId);
    console.log('SharedChipDrawingView - ShareId:', shareId);

    const sharedDataRef = ref(database, `sharedChipDrawing/${eventId}/${shareId}`);

    const unsubscribe = onValue(sharedDataRef, (snapshot) => {
      const data = snapshot.val();
      console.log('SharedChipDrawingView - Received data:', data);
      if (data) {
        setSharedData(data);
      } else {
        setError('No shared data found for this link');
      }
    }, (error) => {
      console.error('Error fetching shared data:', error);
      setError('Error loading shared data');
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, [eventId, shareId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!sharedData) {
    return <div>Loading...</div>;
  }

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>{sharedData.eventName || 'Chip Drawing Results'}</Header>
        
        <Section>
          {sharedData.currentClass && <SectionHeader>Class: {sharedData.currentClass}</SectionHeader>}
          {sharedData.round && <SectionHeader>Round: {sharedData.round}</SectionHeader>}
        </Section>
        
        {sharedData.bag && sharedData.bag.length > 0 && (
          <Section>
            <SectionHeader><FaDice /> Bag</SectionHeader>
            <List>
              {sharedData.bag.map((racer, index) => (
                <ListItem key={index}>
                  <RacerInfo>
                    <span><strong>{racer.name}</strong></span>
                    <span>{racer.team}</span>
                    <span>{racer.class}</span>
                  </RacerInfo>
                </ListItem>
              ))}
            </List>
          </Section>
        )}

        {sharedData.pairings && sharedData.pairings.length > 0 && (
          <Section>
            <SectionHeader><FaTrophy /> Pairings</SectionHeader>
            <List>
              {sharedData.pairings.map((pair, index) => (
                <ListItem key={index}>
                  <RacerInfo>
                    {pair.racer2.name === 'Bye' ? (
                      <>
                        <span><strong>{pair.racer1.name}</strong></span>
                        <span>{pair.racer1.team}</span>
                        <span>Bye Run</span>
                      </>
                    ) : (
                      <>
                        <span><strong>{pair.racer1.name}</strong> vs <strong>{pair.racer2.name}</strong></span>
                        <span>{pair.racer1.team} vs {pair.racer2.team}</span>
                      </>
                    )}
                  </RacerInfo>
                  {pair.winner && <span>Winner: <strong>{pair.winner.name}</strong></span>}
                </ListItem>
              ))}
            </List>
          </Section>
        )}

        {sharedData.pairings && sharedData.pairings.length > 0 && (
          <Section>
            <SectionHeader><FaTrophy /> Round Results</SectionHeader>
            <List>
              {sharedData.pairings.map((pair, index) => (
                <ListItem key={index}>
                  <RacerInfo>
                    <span>Race {index + 1}:</span>
                    {pair.racer2.name === 'Bye' ? (
                      <span><strong>{pair.racer1.name}</strong> - Bye Run (Advances)</span>
                    ) : (
                      <span>
                        {pair.winner ? <strong>{pair.winner.name} wins</strong> : 'Not decided'}
                      </span>
                    )}
                  </RacerInfo>
                </ListItem>
              ))}
            </List>
          </Section>
        )}
      </Container>
    </PageContainer>
  );
}

export default SharedChipDrawingView;
