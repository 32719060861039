import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  padding-top: 60px; // Add this line to account for the fixed navigation bar
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Header = styled.h1`
  color: ${props => props.theme.mode === 'dark' ? 'white' : props.theme.text};
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const SubHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 1rem;
`;

export const Button = styled.button`
  background-color: ${props => props.theme.buttonBackground};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }
  @media (max-width: 768px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
`;

export const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 0.875rem;
`;

export const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 0.875rem;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  background-color: #f1f1f1;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-radius: 0.25rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #0070f3;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

export const TableHeader = styled.th`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 0.5rem;
  text-align: left;
  border-bottom: 2px solid ${props => props.theme.border};
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${props => props.theme.backgroundAlt};
  }
`;

export const TableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.border};
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  resize: vertical;
`;

export const StyledButton = styled.button`
  background-color: ${props => props.theme.buttonBackground || '#007bff'}; // Default to a blue color if not set in theme
  color: white; // Always white text
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.buttonHoverBackground || '#0056b3'}; // Darker blue on hover if not set in theme
  }
`;
