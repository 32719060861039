import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const baseTheme = {
  fontSizes: {
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    normal: 1.5,
    tight: 1.25,
    loose: 1.75,
  },
};

const themes = {
  light: {
    // ... existing properties ...
    primaryHover: '#0056b3', // Slightly darker shade of primary color
  },
  dark: {
    // ... existing properties ...
    primaryHover: '#3700b3', // Darker shade of primary color for hover effects
  },
};

export const lightTheme = {
  // ... other theme properties ...
  buttonBackground: '#007bff',
  buttonHoverBackground: '#0056b3',
  inputBackground: '#ffffff', // White background
};

export const darkTheme = {
  // ... other theme properties ...
  buttonBackground: '#0056b3',
  buttonHoverBackground: '#003d80',
  inputBackground: '#e0e0e0', // Light gray background
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    ...baseTheme,
    mode: 'light',
    background: '#f4f5f7', // Lighter background
    text: '#000000',
    cardBackground: '#ffffff', // White card background
    primary: '#0070f3',
    primaryHover: '#0056b3',
    textSecondary: '#666666',
    border: '#e0e0e0',
    navBackground: '#ffffff',
    buttonBackground: '#0070f3', // Blue for light mode
    buttonBackgroundHover: '#0056b3', // Darker blue for light mode hover
    danger: '#dc3545',
    dangerHover: '#c82333',
    ctaSectionBackground: '#0070f3',
  });

  const toggleTheme = () => {
    setTheme(prevTheme => ({
      ...prevTheme,
      mode: prevTheme.mode === 'light' ? 'dark' : 'light',
      background: prevTheme.mode === 'light' ? '#121212' : '#f4f5f7',
      text: prevTheme.mode === 'light' ? '#ffffff' : '#000000',
      cardBackground: prevTheme.mode === 'light' ? '#1e1e1e' : '#ffffff',
      primary: prevTheme.mode === 'light' ? '#6200ee' : '#0070f3',
      primaryHover: prevTheme.mode === 'light' ? '#3700b3' : '#0056b3',
      textSecondary: prevTheme.mode === 'light' ? '#a0a0a0' : '#666666',
      border: prevTheme.mode === 'light' ? '#333333' : '#e0e0e0',
      navBackground: prevTheme.mode === 'light' ? '#1a1a1a' : '#ffffff',
      buttonBackground: prevTheme.mode === 'light' ? '#6200ee' : '#0070f3', // Purple for dark mode, Blue for light mode
      buttonBackgroundHover: prevTheme.mode === 'light' ? '#3700b3' : '#0056b3', // Darker purple for dark mode hover, Darker blue for light mode hover
      ctaSectionBackground: prevTheme.mode === 'light' ? '#6200ee' : '#0070f3',
    }));
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.background;
    document.body.style.color = theme.text;
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.minHeight = '100vh';
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const useTheme = () => useContext(ThemeContext);
