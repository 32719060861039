import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';

const TimingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
`;

const LaneData = styled.div`
  width: 48%;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 1rem;
`;

const LiveTimingDisplay = ({ data }) => {
  const { theme } = useTheme();

  if (!data) {
    return <LoadingMessage>Waiting for live timing data...</LoadingMessage>;
  }

  const renderLaneData = (lane) => {
    if (!lane) {
      return <LaneData>No data available</LaneData>;
    }

    return (
      <LaneData>
        <h3>{lane.name} (#{lane.carNumber})</h3>
        <DataRow>
          <span>RT:</span>
          <span>{lane.reactionTime?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>60':</span>
          <span>{lane.sixtyFoot?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>330':</span>
          <span>{lane.threeThirtyFoot?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>660':</span>
          <span>{lane.sixSixtyFoot?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>1000':</span>
          <span>{lane.thousandFoot?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>ET:</span>
          <span>{lane.finishET?.toFixed(3) || 'N/A'}</span>
        </DataRow>
        <DataRow>
          <span>MPH:</span>
          <span>{lane.finishMPH?.toFixed(2) || 'N/A'}</span>
        </DataRow>
      </LaneData>
    );
  };

  return (
    <TimingContainer theme={theme}>
      {renderLaneData(data.leftLane)}
      {renderLaneData(data.rightLane)}
    </TimingContainer>
  );
};

export default LiveTimingDisplay;
