import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Button, Select } from './SharedStyles';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.background};
  padding: 2rem;
  border-radius: 8px;
  z-index: 1000;
  max-width: 500px;
  width: 90%;
`;

const CategoryCopyModal = ({ categories, onCopy, onClose }) => {
  const { theme } = useTheme();
  const [sourceCategory, setSourceCategory] = useState('');
  const [targetCategory, setTargetCategory] = useState('');
  const [conflicts, setConflicts] = useState([]);

  const handleCopy = () => {
    // Check for conflicts
    const conflictEntries = []; // Logic to find conflicts
    if (conflictEntries.length > 0) {
      setConflicts(conflictEntries);
    } else {
      onCopy(sourceCategory, targetCategory);
      onClose();
    }
  };

  return (
    <ModalContainer theme={theme}>
      <h3>Copy Category</h3>
      <Select value={sourceCategory} onChange={(e) => setSourceCategory(e.target.value)} theme={theme}>
        <option value="">Select Source Category</option>
        {categories.map(category => (
          <option key={category.id} value={category.name}>{category.name}</option>
        ))}
      </Select>
      <Select value={targetCategory} onChange={(e) => setTargetCategory(e.target.value)} theme={theme}>
        <option value="">Select Target Category</option>
        {categories.map(category => (
          <option key={category.id} value={category.name}>{category.name}</option>
        ))}
      </Select>
      {conflicts.length > 0 && <div>Resolve conflicts before copying</div>}
      <Button onClick={handleCopy} theme={theme}>Copy</Button>
      <Button onClick={onClose} theme={theme}>Cancel</Button>
    </ModalContainer>
  );
};

export default CategoryCopyModal;
