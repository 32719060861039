import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaUsers, FaTicketAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from '../components/Navigation';
import { PageContainer, Container, Header, Button, Input } from '../components/SharedStyles';

const CenteredContainer = styled(Container)`
  text-align: center;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const CenteredHeader = styled(Header)`
  text-align: center;
`;

const SectionContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 2rem;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const EventList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const EventItem = styled.li`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

const EventName = styled.h3`
  margin-bottom: 0.5rem;
`;

const TicketList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TicketItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledSelect = styled.select`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.inputBackground};
  color: black; // Changed to black
  font-size: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  option {
    color: black; // Ensure options are also black
  }
`;

const TicketTypeSelect = styled(StyledSelect)`
  margin-right: 0.5rem;
`;

const AddTicketForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const InputGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledInput = styled(Input)`
  color: black !important; // Force black color
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ActionButton = styled(Button)`
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const AdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [newTicketName, setNewTicketName] = useState('');
  const [newTicketPrice, setNewTicketPrice] = useState('');
  const [newTicketType, setNewTicketType] = useState('Spectator');
  const [customTicketName, setCustomTicketName] = useState('');
  const { theme } = useTheme();

  useEffect(() => {
    const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
    setEvents(storedEvents);
  }, []);

  const handleAddTicket = (eventId) => {
    if ((newTicketName || customTicketName) && newTicketPrice) {
      const ticketName = newTicketName === 'Custom' ? customTicketName : newTicketName;
      const newTicket = { 
        id: Date.now().toString(),
        name: ticketName, 
        price: parseFloat(newTicketPrice), 
        type: newTicketType 
      };

      const updatedEvents = events.map(event => {
        if (event.id === eventId) {
          return {
            ...event,
            tickets: [...(event.tickets || []), newTicket]
          };
        }
        return event;
      });

      setEvents(updatedEvents);
      localStorage.setItem('events', JSON.stringify(updatedEvents));
      
      // Update racerTickets in localStorage
      if (newTicketType === 'Racer') {
        const racerTickets = JSON.parse(localStorage.getItem('racerTickets') || '{}');
        if (!racerTickets[eventId]) {
          racerTickets[eventId] = [];
        }
        racerTickets[eventId].push(newTicket);
        localStorage.setItem('racerTickets', JSON.stringify(racerTickets));
      }
      
      // Reset form fields
      setNewTicketName('');
      setNewTicketPrice('');
      setCustomTicketName('');
    }
  };

  const handleDeleteTicket = (eventId, ticketIndex) => {
    const updatedEvents = events.map(event => {
      if (event.id === eventId) {
        const updatedTickets = event.tickets.filter((_, index) => index !== ticketIndex);
        return { ...event, tickets: updatedTickets };
      }
      return event;
    });
    setEvents(updatedEvents);
    localStorage.setItem('events', JSON.stringify(updatedEvents));

    // Delete the ticket from the racer tickets
    const deletedTicket = events.find(e => e.id === eventId).tickets[ticketIndex];
    if (deletedTicket.type === 'Racer') {
      const racerTickets = JSON.parse(localStorage.getItem('racerTickets') || '{}');
      if (racerTickets[eventId]) {
        racerTickets[eventId] = racerTickets[eventId].filter(t => t.id !== deletedTicket.id);
        localStorage.setItem('racerTickets', JSON.stringify(racerTickets));
      }
    }
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <CenteredContainer>
        <CenteredHeader>Event Holders' Dashboard</CenteredHeader>
        
        <SectionContainer theme={theme}>
          <SectionTitle theme={theme}>Manage Events & Tickets</SectionTitle>
          <EventList>
            {events.map(event => (
              <EventItem key={event.id} theme={theme}>
                <EventName>{event.name}</EventName>
                <TicketList>
                  {event.tickets && event.tickets.map((ticket, index) => (
                    <TicketItem key={index}>
                      <span>{ticket.name} ({ticket.type}): ${ticket.price}</span>
                      <ActionButton onClick={() => handleDeleteTicket(event.id, index)} theme={theme}>
                        <FaTrash />
                      </ActionButton>
                    </TicketItem>
                  ))}
                </TicketList>
                <AddTicketForm onSubmit={(e) => {
                  e.preventDefault();
                  handleAddTicket(event.id);
                }}>
                  <InputGroup>
                    <TicketTypeSelect
                      value={newTicketType}
                      onChange={(e) => setNewTicketType(e.target.value)}
                      theme={theme}
                    >
                      <option value="Spectator">Spectator</option>
                      <option value="Racer">Racer</option>
                    </TicketTypeSelect>
                    <StyledSelect
                      value={newTicketName}
                      onChange={(e) => setNewTicketName(e.target.value)}
                      theme={theme}
                    >
                      <option value="">Select Ticket Name</option>
                      <option value="General Admission">General Admission</option>
                      <option value="VIP">VIP</option>
                      <option value="Custom">Custom</option>
                    </StyledSelect>
                    {newTicketName === 'Custom' && (
                      <StyledInput
                        type="text"
                        placeholder="Custom Ticket Name"
                        value={customTicketName}
                        onChange={(e) => setCustomTicketName(e.target.value)}
                        theme={theme}
                      />
                    )}
                    <StyledInput
                      type="number"
                      placeholder="Price"
                      value={newTicketPrice}
                      onChange={(e) => setNewTicketPrice(e.target.value)}
                      theme={theme}
                    />
                    <ActionButton type="submit" theme={theme}>
                      <FaPlus /> Add Ticket
                    </ActionButton>
                  </InputGroup>
                </AddTicketForm>
              </EventItem>
            ))}
          </EventList>
        </SectionContainer>

        <SectionContainer theme={theme}>
          <SectionTitle theme={theme}>Manage Participants</SectionTitle>
          <ParticipantsManagement theme={theme} />
        </SectionContainer>

        <SectionContainer theme={theme}>
          <SectionTitle theme={theme}>Spectator Sales</SectionTitle>
          <SpectatorSales theme={theme} />
        </SectionContainer>
      </CenteredContainer>
    </PageContainer>
  );
};

const ParticipantsManagement = ({ theme }) => {
  return (
    <div>
      {/* Add table or list of participants with approval and payment status */}
      <p>Participant management functionality will be implemented here.</p>
    </div>
  );
};

const SpectatorSales = ({ theme }) => {
  return (
    <div>
      {/* Add ticket sales summary and attendee list */}
      <p>Spectator sales information will be displayed here.</p>
    </div>
  );
};

export default AdminPanel;