import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="container">
      <div className="terms-of-service">
        <h1>Terms of Service</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using RaceGrid Master, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our service.</p>

        <h2>2. Use of Service</h2>
        <p>You agree to use RaceGrid Master only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the service.</p>

        <h2>3. User Accounts</h2>
        <p>To access certain features of RaceGrid Master, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password.</p>

        <h2>4. Intellectual Property</h2>
        <p>The content, organization, graphics, design, and other matters related to RaceGrid Master are protected under applicable copyrights and other proprietary laws. Copying, redistribution, use or publication of any such matters or any part of the service is prohibited.</p>

        <h2>5. Limitation of Liability</h2>
        <p>RaceGrid Master and its affiliates will not be liable for any damages of any kind arising from the use of this service, including but not limited to direct, indirect, incidental, and consequential damages.</p>

        <h2>6. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms of Service at any time. We will notify users of any changes by posting the new Terms of Service on this page.</p>

        <h2>7. Contact Information</h2>
        <p>If you have any questions about these Terms of Service, please contact us at:</p>
        <p>
          Email: terms@racegridmaster.com<br />
          Address: 123 Race Street, Runner's City, RC 12345, United States
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;