import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
`;

const ModalTitle = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
`;

const AgreementText = styled.div`
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
`;

const AcceptButton = styled(Button)`
  background-color: ${props => props.theme.mode === 'light' ? props.theme.primary : '#8A2BE2'};
  color: white;
  &:hover {
    background-color: ${props => props.theme.mode === 'light' ? props.theme.primaryHover : '#9A4EF1'};
  }
`;

const DeclineButton = styled(Button)`
  background-color: ${props => props.theme.buttonBackground};
  color: ${props => props.theme.buttonText};
  &:hover {
    background-color: ${props => props.theme.buttonHover};
  }
`;

const LegalAgreementModal = ({ isOpen, onClose, onAccept, planName }) => {
  const { theme } = useTheme();

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <ModalContent
            theme={theme}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
          >
            <ModalTitle theme={theme}>Legal Agreement for {planName} Plan</ModalTitle>
            <AgreementText theme={theme}>
              {/* Replace this with your actual legal agreement text */}
              This is a placeholder for the legal agreement text. Please replace this with your actual legal agreement for the {planName} plan. The agreement should outline the terms and conditions, usage rights, payment terms, and any other relevant legal information.
            </AgreementText>
            <ButtonContainer>
              <DeclineButton theme={theme} onClick={onClose}>Decline</DeclineButton>
              <AcceptButton theme={theme} onClick={onAccept}>Accept</AcceptButton>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default LegalAgreementModal;
