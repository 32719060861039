import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCalculator, FaTrophy, FaMedal, FaDollarSign } from 'react-icons/fa';
import { Button } from './SharedStyles';

const Container = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TotalPrizePoolContainer = styled.div`
  background: linear-gradient(135deg, ${props => props.theme.primary} 0%, ${props => props.theme.secondary} 100%);
  color: ${props => props.theme.onPrimary};
  padding: 1.5rem;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TotalPrizePoolLabel = styled.div`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const TotalPrizePoolAmount = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DistributionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const DistributionItem = styled.div`
  background-color: ${props => props.theme.surface};
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 80%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: #000000; // Set text color to black for both light and dark modes
  text-align: center;
  margin-top: 0.5rem;
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.onPrimary};
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

function PrizePoolManagement({ totalPrizePool, onUpdatePrizePool, theme }) {
  const [distribution, setDistribution] = useState({
    firstPlace: 50,
    secondPlace: 30,
    thirdPlace: 20,
  });

  const handleDistributionChange = (place, value) => {
    setDistribution({ ...distribution, [place]: parseInt(value, 10) });
  };

  const updatePrizePool = () => {
    onUpdatePrizePool(totalPrizePool + 1000);
  };

  const getIcon = (place) => {
    switch (place) {
      case 'firstPlace': return <FaTrophy color="gold" />;
      case 'secondPlace': return <FaMedal color="silver" />;
      case 'thirdPlace': return <FaMedal color="#cd7f32" />;
      default: return null;
    }
  };

  return (
    <Container theme={theme}>
      <Header>
        <FaCalculator /> Prize Pool Management
      </Header>
      <TotalPrizePoolContainer theme={theme}>
        <TotalPrizePoolLabel>Total Prize Pool</TotalPrizePoolLabel>
        <TotalPrizePoolAmount>
          <FaDollarSign />
          {totalPrizePool.toLocaleString()}
        </TotalPrizePoolAmount>
      </TotalPrizePoolContainer>
      <DistributionGrid>
        {Object.entries(distribution).map(([place, percentage]) => (
          <DistributionItem key={place} theme={theme}>
            {getIcon(place)}
            <label>{place.replace('Place', ' Place')}</label>
            <Input
              type="number"
              value={percentage}
              onChange={(e) => handleDistributionChange(place, e.target.value)}
              theme={theme}
            />
            <span>%</span>
          </DistributionItem>
        ))}
      </DistributionGrid>
      <EventButton onClick={updatePrizePool} theme={theme}>
        <FaCalculator /> Recalculate Prize Pool
      </EventButton>
    </Container>
  );
}

export default PrizePoolManagement;