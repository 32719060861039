import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useSponsorVendor } from '../contexts/SponsorVendorContext';
import { FaPlus, FaSearch, FaEdit, FaTrash, FaStore, FaSave } from 'react-icons/fa';
import { 
  categorizeSponsorVendor, 
  searchSponsorsVendors,
  getAvailableSpaces,
  rentSpace
} from '../utils/sponsorVendorIntegration';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.h1`
  font-size: 2rem;
  color: ${props => props.theme.text};
  margin-bottom: 2rem;
  text-align: center;
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px 0 0 4px;
`;

const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.primaryDark};
  }
`;

const AddNewContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem; // Add this line to create space above the container
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AddNewTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
`;

const AddButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.primaryDark};
  }
`;

const ListContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 2rem; // Add this line to create space below the grid
`;

const Card = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  color: ${props => props.theme.text};
  margin-bottom: 0.5rem;
`;

const CardInfo = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 0.5rem;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${props => props.theme.buttonBackground};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }

  &:disabled {
    background-color: ${props => props.theme.disabled};
    cursor: not-allowed;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ActionButton = styled(Button)`
  background-color: ${props => props.theme.secondary};
  color: white;
  &:hover {
    background-color: ${props => props.theme.secondaryHover};
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.danger};
  color: white;
  &:hover {
    background-color: ${props => props.theme.dangerHover};
  }
`;

const RentButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: auto;
  width: 100%;
`;

const SpaceCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SaveButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const SponsorsVendorsPage = () => {
  const { eventId } = useParams();
  const { theme } = useTheme();
  const { 
    sponsorsVendors, 
    setSponsorsVendors,
    pendingRegistrations, 
    setPendingRegistrations,
    addSponsorVendor,
    updateSponsorVendor,
    deleteSponsorVendor  // Make sure this line is here
  } = useSponsorVendor();
  const [filteredSponsorsVendors, setFilteredSponsorsVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newEntry, setNewEntry] = useState({ type: 'sponsor', name: '', description: '', logo: '', website: '', category: '', customCategory: '' });
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [editingItem, setEditingItem] = useState(null);

  const sponsorCategories = ['Money', 'Equipment', 'Fuel', 'Tires', 'Media Coverage', 'custom'];
  const vendorCategories = ['Food', 'Merchandise', 'Auto Parts', 'custom'];

  useEffect(() => {
    const fetchData = async () => {
      const spaces = await getAvailableSpaces(eventId);
      setAvailableSpaces(spaces);
    };
    fetchData();
  }, [eventId]);

  useEffect(() => {
    // Filter sponsors and vendors for the current event
    const filtered = sponsorsVendors.filter(item => item.eventId === eventId);
    setFilteredSponsorsVendors(filtered);
  }, [sponsorsVendors, eventId]);

  useEffect(() => {
    // Auto-save whenever sponsorsVendors or pendingRegistrations change
    localStorage.setItem('sponsorsVendors', JSON.stringify(sponsorsVendors));
    localStorage.setItem('pendingRegistrations', JSON.stringify(pendingRegistrations));
  }, [sponsorsVendors, pendingRegistrations]);

  console.log('Filtered Sponsors/Vendors:', filteredSponsorsVendors); // Add this line for debugging
  console.log('Pending Registrations:', pendingRegistrations); // Add this line for debugging

  const handleSearch = () => {
    const results = searchSponsorsVendors(searchQuery);
    setFilteredSponsorsVendors(results);
  };

  const handleAddNew = (e) => {
    e.preventDefault();
    if (editingItem) {
      handleUpdate(e);
    } else {
      const finalCategory = newEntry.category === 'custom' ? newEntry.customCategory : newEntry.category;
      addSponsorVendor({...newEntry, category: finalCategory}, eventId);
      setNewEntry({ type: 'sponsor', name: '', description: '', logo: '', website: '', category: '', customCategory: '' });
    }
  };

  const handleCategorize = (id, category) => {
    categorizeSponsorVendor(id, category);
    // Update the local state to reflect the change
    setFilteredSponsorsVendors(prev => 
      prev.map(item => item.id === id ? {...item, category} : item)
    );
  };

  const handleRentSpace = (spaceId, registration) => {
    rentSpace(eventId, spaceId, registration.id);
    // Update available spaces
    setAvailableSpaces(prev => 
      prev.map(space => space.id === spaceId ? {...space, available: false} : space)
    );
    // Remove the registration from pending
    setPendingRegistrations(prev => prev.filter(reg => reg.id !== registration.id));
    // Add the registration to sponsorsVendors if it's not already there
    setSponsorsVendors(prev => {
      if (!prev.some(item => item.id === registration.id)) {
        return [...prev, {...registration, spaceId, eventId}];
      }
      return prev;
    });
    // Update filteredSponsorsVendors to include the new registration
    setFilteredSponsorsVendors(prev => {
      if (!prev.some(item => item.id === registration.id)) {
        return [...prev, {...registration, spaceId, eventId}];
      }
      return prev;
    });
  };

  // Function to handle editing
  const handleEdit = (item) => {
    setEditingItem(item);
    setNewEntry({ ...item });
  };

  // Function to handle updating
  const handleUpdate = (e) => {
    e.preventDefault();
    const finalCategory = newEntry.category === 'custom' ? newEntry.customCategory : newEntry.category;
    updateSponsorVendor({...newEntry, category: finalCategory});
    setEditingItem(null);
    setNewEntry({ type: 'sponsor', name: '', description: '', logo: '', website: '', category: '', customCategory: '' });
  };

  // Function to handle deleting
  const handleDelete = (id) => {
    deleteSponsorVendor(id);
    setFilteredSponsorsVendors(prev => prev.filter(item => item.id !== id));
  };

  const handleSave = () => {
    localStorage.setItem('sponsorsVendors', JSON.stringify(sponsorsVendors));
    localStorage.setItem('pendingRegistrations', JSON.stringify(pendingRegistrations));
    alert('Data saved successfully!');
  };

  return (
    <PageContainer theme={theme}>
      <Header theme={theme}>Sponsors and Vendors</Header>
      
      <SearchContainer>
        <SearchInput
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search sponsors/vendors"
          theme={theme}
        />
        <SearchButton onClick={handleSearch} theme={theme}>
          <FaSearch />
        </SearchButton>
      </SearchContainer>

      <AddNewContainer theme={theme}>
        <AddNewTitle theme={theme}>
          {editingItem ? 'Edit Sponsor/Vendor' : 'Add New Sponsor/Vendor'}
        </AddNewTitle>
        <Form onSubmit={handleAddNew}>
          <Select
            value={newEntry.type}
            onChange={(e) => setNewEntry({...newEntry, type: e.target.value})}
            theme={theme}
          >
            <option value="sponsor">Sponsor</option>
            <option value="vendor">Vendor</option>
          </Select>
          <Input
            type="text"
            value={newEntry.name}
            onChange={(e) => setNewEntry({...newEntry, name: e.target.value})}
            placeholder="Name"
            theme={theme}
            required
          />
          <Select
            value={newEntry.category}
            onChange={(e) => setNewEntry({...newEntry, category: e.target.value})}
            theme={theme}
            required
          >
            <option value="">Select Category</option>
            {(newEntry.type === 'sponsor' ? sponsorCategories : vendorCategories).map(category => (
              <option key={category} value={category}>{category === 'custom' ? 'Other' : category}</option>
            ))}
          </Select>
          {newEntry.category === 'custom' && (
            <Input
              type="text"
              value={newEntry.customCategory}
              onChange={(e) => setNewEntry({...newEntry, customCategory: e.target.value})}
              placeholder="Enter custom category"
              theme={theme}
              required
            />
          )}
          <Input
            type="text"
            value={newEntry.description}
            onChange={(e) => setNewEntry({...newEntry, description: e.target.value})}
            placeholder="Description"
            theme={theme}
          />
          <Input
            type="text"
            value={newEntry.logo}
            onChange={(e) => setNewEntry({...newEntry, logo: e.target.value})}
            placeholder="Logo URL"
            theme={theme}
          />
          <Input
            type="text"
            value={newEntry.website}
            onChange={(e) => setNewEntry({...newEntry, website: e.target.value})}
            placeholder="Website"
            theme={theme}
          />
          <AddButton type="submit" theme={theme}>
            <FaPlus /> {editingItem ? 'Update' : 'Add'}
          </AddButton>
          {editingItem && (
            <Button onClick={() => setEditingItem(null)} theme={theme}>
              Cancel
            </Button>
          )}
        </Form>
      </AddNewContainer>

      <ListContainer>
        {filteredSponsorsVendors.map(item => (
          <Card key={item.id} theme={theme}>
            <CardImage src={item.logo} alt={`${item.name} logo`} />
            <CardTitle theme={theme}>{item.name}</CardTitle>
            <CardInfo theme={theme}>Type: {item.type}</CardInfo>
            <CardInfo theme={theme}>Category: {item.category}</CardInfo>
            <CardInfo theme={theme}>{item.description}</CardInfo>
            <CardInfo theme={theme}>
              Website: <a href={item.website} target="_blank" rel="noopener noreferrer">{item.website}</a>
            </CardInfo>
            <ButtonGroup>
              <ActionButton theme={theme} onClick={() => handleEdit(item)}>
                <FaEdit /> Edit
              </ActionButton>
              <DeleteButton theme={theme} onClick={() => handleDelete(item.id)}>
                <FaTrash /> Delete
              </DeleteButton>
            </ButtonGroup>
          </Card>
        ))}
      </ListContainer>

      <AddNewContainer theme={theme}>
        <AddNewTitle theme={theme}>Pending Registrations</AddNewTitle>
        <ListContainer>
          {pendingRegistrations.map(registration => (
            <Card key={registration.id} theme={theme}>
              <CardTitle theme={theme}>{registration.name}</CardTitle>
              <CardInfo theme={theme}>Type: {registration.type}</CardInfo>
              <CardInfo theme={theme}>{registration.description}</CardInfo>
              {availableSpaces.map(space => (
                <RentButton 
                  key={space.id}
                  theme={theme} 
                  onClick={() => handleRentSpace(space.id, registration)}
                >
                  <FaStore /> Rent {space.name} for {registration.name}
                </RentButton>
              ))}
            </Card>
          ))}
        </ListContainer>
      </AddNewContainer>

      <AddNewContainer theme={theme}>
        <AddNewTitle theme={theme}>Available Spaces for Rent</AddNewTitle>
        <ListContainer>
          {availableSpaces.map(space => (
            <SpaceCard key={space.id} theme={theme}>
              <CardTitle theme={theme}>{space.name}</CardTitle>
              <CardInfo theme={theme}>Size: {space.size}</CardInfo>
              <CardInfo theme={theme}>Price: ${space.price}</CardInfo>
              <CardInfo theme={theme}>Status: {space.available ? 'Available' : 'Rented'}</CardInfo>
              {space.available && pendingRegistrations.map(registration => (
                <RentButton 
                  key={registration.id}
                  theme={theme} 
                  onClick={() => handleRentSpace(space.id, registration)}
                >
                  <FaStore /> Rent Space for {registration.name}
                </RentButton>
              ))}
            </SpaceCard>
          ))}
        </ListContainer>
      </AddNewContainer>

      <SaveButton onClick={handleSave} theme={theme}>
        <FaSave /> Save All Data
      </SaveButton>
    </PageContainer>
  );
};

export default SponsorsVendorsPage;