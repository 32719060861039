import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from './Navigation';
import LegalAgreementModal from './LegalAgreementModal';

const PageContainer = styled.div`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  min-height: 100vh;
  padding-top: 80px;
`;

const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const PricingHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const PricingTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: ${props => props.theme.primary};
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const PricingSubtitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${props => props.theme.textSecondary};
  margin-top: 0;
`;

const PricingTiers = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const PricingTier = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  ${props => props.isPopular && `
    border: 2px solid ${props.theme.primary};
  `}
`;

const PopularTag = styled.span`
  background-color: ${props => props.theme.primary};
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 1rem;
`;

const TierName = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const Price = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.theme.text};
`;

const PerMonth = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${props => props.theme.textSecondary};
`;

const YearlyPrice = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.textSecondary};
  margin-top: 0.5rem;
`;

const FeaturesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1.5rem 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: ${props => props.theme.text};
`;

const CheckIcon = styled.svg`
  width: 1rem;
  height: 1rem;
  color: ${props => props.theme.primary};
  margin-right: 0.5rem;
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  ${props => props.isPopular
    ? `
      background-color: ${props.theme.primary};
      color: white;
      &:hover {
        background-color: ${props.theme.primaryHover};
      }
    `
    : `
      background-color: ${props.theme.buttonBackground};
      color: ${props => props.theme.mode === 'dark' ? 'white' : props.theme.buttonText};
      &:hover {
        background-color: ${props.theme.buttonHover};
        color: white;
      }
    `
  }

  @media (max-width: 768px) {
    ${props => props.isPopular
      ? `
        color: white;
      `
      : `
        color: black;
      `
    }
  }
`;

const PricingCategories = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const CategoryButton = styled.button`
  background-color: ${props => props.isActive ? props.theme.primary : props.theme.buttonBackground};
  color: ${props => props.isActive ? 'white' : props.theme.buttonText};
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${props => props.isActive ? props.theme.primaryHover : props.theme.buttonHover};
  }
`;

const MaintenanceFee = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.textSecondary};
  margin-top: 0.5rem;
`;

const PricingTierComponent = ({ name, price, yearlyPrice, maintenanceFee, features, isPopular, onSelect, billingType }) => {
  const { theme } = useTheme();
  
  return (
    <PricingTier
      theme={theme}
      isPopular={isPopular}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      {isPopular && <PopularTag theme={theme}>Most Popular</PopularTag>}
      <TierName theme={theme}>{name}</TierName>
      <Price theme={theme}>
        ${price}
        {billingType === 'onetime' && <PerMonth theme={theme}>One-time</PerMonth>}
        {billingType === 'subscription' && <PerMonth theme={theme}>/month</PerMonth>}
      </Price>
      {billingType === 'subscription' && (
        <YearlyPrice theme={theme}>
          or ${yearlyPrice}/year
        </YearlyPrice>
      )}
      {maintenanceFee && (
        <MaintenanceFee theme={theme}>
          + ${maintenanceFee.monthly}/month or ${maintenanceFee.yearly}/year maintenance
        </MaintenanceFee>
      )}
      <FeaturesList>
        {features.map((feature, index) => (
          <FeatureItem key={index} theme={theme}>
            <CheckIcon theme={theme} fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </CheckIcon>
            {feature}
          </FeatureItem>
        ))}
      </FeaturesList>
      <SelectButton onClick={onSelect} isPopular={isPopular} theme={theme}>
        Select Plan
      </SelectButton>
    </PricingTier>
  );
};

const PricingPage = () => {
  const { theme } = useTheme();
  const [activeCategory, setActiveCategory] = useState('subscription');
  const [selectedTier, setSelectedTier] = useState(null);
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false);

  const subscriptionTiers = [
    {
      name: 'Free Trial',
      price: '0',
      yearlyPrice: '0',
      features: [
        'Access to basic features',
        'Up to 10 racers',
        'Up to 2 teams',
        'Basic event management',
        'No Prep Chip Draw (limited)',
        '14-day trial period'
      ],
      isPopular: false,
      billingType: 'trial'
    },
    {
      name: 'Chip Draw',
      price: '49',
      yearlyPrice: '588',
      features: [
        'No Prep Chip Draw',
        'Up to 50 racers',
        'Up to 5 teams',
        'Basic event management',
        'Email support'
      ],
      isPopular: false,
      billingType: 'subscription'
    },
    {
      name: 'Basic',
      price: '299',
      yearlyPrice: '3,588',
      features: [
        'All Chip Draw features',
        'Up to 100 racers',
        'Up to 10 teams',
        'Advanced event management',
        'Basic analytics',
        'Priority email support'
      ],
      isPopular: false,
      billingType: 'subscription'
    },
    {
      name: 'Pro',
      price: '499',
      yearlyPrice: '5,988',
      features: [
        'All Basic features',
        'Up to 500 racers',
        'Up to 50 teams',
        'Advanced analytics',
        'API access (limited)',
        'Phone support'
      ],
      isPopular: true,
      billingType: 'subscription'
    },
  ];

  const oneTimeTiers = [
    {
      name: 'Full Suite',
      price: '7,500',
      yearlyPrice: 'One-time purchase',
      features: [
        'All Enterprise subscription features',
        'Up to 1000 racers',
        'Up to 100 teams',
        'Perpetual license',
        'One year of updates and support included',
        'Custom feature development (up to 20 hours)',
        'Remote training and setup'
      ],
      isPopular: false,
      billingType: 'onetime'
    },
    {
      name: 'White Label',
      price: '15,000',
      yearlyPrice: 'One-time purchase',
      maintenanceFee: {
        monthly: '750',
        yearly: '7,500'
      },
      features: [
        'All Full Suite features',
        'Unlimited racers and teams',
        'Complete branding customization',
        'Extended API access',
        'Custom feature development (up to 50 hours)',
        'Priority support',
        'On-site training and setup',
        'Quarterly consultation calls'
      ],
      isPopular: true,
      billingType: 'onetime'
    },
  ];

  const whiteLabelTiers = [
    {
      name: 'White Label Maintenance (Monthly)',
      price: '750',
      features: [
        'Software updates',
        'Bug fixes',
        'Premium support',
        'Up to 10 hours of customization per month',
        '6-month minimum contract required',
        'Monthly billing'
      ],
      isPopular: false,
      billingType: 'maintenance'
    },
    {
      name: 'White Label Maintenance (Yearly)',
      price: '7,500',
      yearlyPrice: '7,500',
      features: [
        'Software updates',
        'Bug fixes',
        'Premium support',
        'Up to 10 hours of customization per month',
        '6-month minimum contract required',
        'Annual billing (save 16.67%)'
      ],
      isPopular: true,
      billingType: 'maintenance'
    }
  ];

  const getActiveTiers = () => {
    switch (activeCategory) {
      case 'subscription':
        return subscriptionTiers;
      case 'onetime':
        return oneTimeTiers;
      case 'whitelabel':
        return whiteLabelTiers;
      default:
        return subscriptionTiers;
    }
  };

  const handlePlanSelection = (tier) => {
    setSelectedTier(tier);
    setIsLegalModalOpen(true);
  };

  const handleLegalAgreementAccept = () => {
    setIsLegalModalOpen(false);
    // Here you would implement the actual payment process
    console.log(`Initiating payment process for ${selectedTier.name} plan`);
    // You can use a library like Stripe to handle payments
    // or redirect to a payment gateway
  };

  const handleLegalAgreementDecline = () => {
    setIsLegalModalOpen(false);
    setSelectedTier(null);
    console.log('Agreement not accepted. Payment process cancelled.');
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <PricingContainer>
        <PricingHeader>
          <PricingTitle theme={theme}>
            Pricing Plans
          </PricingTitle>
          <PricingSubtitle theme={theme}>
            Choose the perfect plan for your racing needs
          </PricingSubtitle>
        </PricingHeader>
        <PricingCategories>
          <CategoryButton
            isActive={activeCategory === 'subscription'}
            onClick={() => setActiveCategory('subscription')}
            theme={theme}
          >
            Subscription
          </CategoryButton>
          <CategoryButton
            isActive={activeCategory === 'onetime'}
            onClick={() => setActiveCategory('onetime')}
            theme={theme}
          >
            One-Time Purchase
          </CategoryButton>
          <CategoryButton
            isActive={activeCategory === 'whitelabel'}
            onClick={() => setActiveCategory('whitelabel')}
            theme={theme}
          >
            White Label Maintenance
          </CategoryButton>
        </PricingCategories>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <PricingTiers>
              {getActiveTiers().map((tier) => (
                <PricingTierComponent
                  key={tier.name}
                  {...tier}
                  onSelect={() => handlePlanSelection(tier)}
                />
              ))}
            </PricingTiers>
          </motion.div>
        </AnimatePresence>
      </PricingContainer>
      <LegalAgreementModal
        isOpen={isLegalModalOpen}
        onClose={handleLegalAgreementDecline}
        onAccept={handleLegalAgreementAccept}
        planName={selectedTier?.name}
      />
    </PageContainer>
  );
}

export default PricingPage;