import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { FaDollarSign, FaTrophy, FaUserFriends, FaChartLine } from 'react-icons/fa';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import Navigation from './Navigation';

const CenteredContainer = styled(Container)`
  text-align: center;
`;

const CenteredHeader = styled(Header)`
  text-align: center;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const Section = styled.section`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 0.5rem;
  border-bottom: 2px solid ${props => props.theme.border};
  color: ${props => props.theme.text};
`;

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.border};
  color: ${props => props.theme.text};
`;

const SectionContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.mode === 'light' ? '#007bff' : '#BB86FC'};
  color: ${props => props.theme.mode === 'light' ? 'white' : 'black'};
  &:hover {
    background-color: ${props => props.theme.mode === 'light' ? '#0056b3' : '#3700B3'};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
`;

function FinancialsPayoutManagement() {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const [entryFee, setEntryFee] = useState('');
  const [prizePool, setPrizePool] = useState(0);
  const [payouts, setPayouts] = useState([]);

  useEffect(() => {
    // Fetch existing financial data here
  }, []);

  const handleEntryFeeSubmit = (e) => {
    e.preventDefault();
    // Update entry fee and recalculate prize pool
  };

  const handlePayoutCalculation = () => {
    // Calculate payouts based on prize pool and predefined percentages
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <CenteredContainer>
        <CenteredHeader>Financials and Payout Management</CenteredHeader>
        <SectionGrid>
          <Section theme={theme}>
            <SectionTitle><FaDollarSign /> Entry Fee Management</SectionTitle>
            <SectionContent>
              <Form onSubmit={handleEntryFeeSubmit}>
                <Input
                  type="number"
                  value={entryFee}
                  onChange={(e) => setEntryFee(e.target.value)}
                  placeholder="Enter entry fee"
                />
                <EventButton type="submit" theme={theme}>
                  Update Entry Fee
                </EventButton>
              </Form>
            </SectionContent>
          </Section>

          <Section theme={theme}>
            <SectionTitle><FaChartLine /> Prize Pool Management</SectionTitle>
            <SectionContent>
              <p>Current Prize Pool: ${prizePool}</p>
              <ButtonGroup>
                <EventButton onClick={() => {/* Add logic */}} theme={theme}>
                  Adjust Pool
                </EventButton>
                <EventButton onClick={() => {/* Add logic */}} theme={theme}>
                  Reset Pool
                </EventButton>
              </ButtonGroup>
            </SectionContent>
          </Section>

          <Section theme={theme}>
            <SectionTitle><FaTrophy /> Payout Distribution</SectionTitle>
            <SectionContent>
              <EventButton onClick={handlePayoutCalculation} theme={theme}>
                Calculate Payouts
              </EventButton>
              <Table>
                <thead>
                  <tr>
                    <Th>Position</Th>
                    <Th>Percentage</Th>
                    <Th>Amount</Th>
                  </tr>
                </thead>
                <tbody>
                  {payouts.map((payout, index) => (
                    <tr key={index}>
                      <Td>{payout.position}</Td>
                      <Td>{payout.percentage}%</Td>
                      <Td>${payout.amount}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SectionContent>
          </Section>

          <Section theme={theme}>
            <SectionTitle><FaUserFriends /> Financial Dashboard</SectionTitle>
            <SectionContent>
              {/* Add financial dashboard content here */}
              <p>Total Revenue: $X,XXX</p>
              <p>Expenses: $X,XXX</p>
              <p>Net Profit: $X,XXX</p>
              <ButtonGroup>
                <EventButton onClick={() => {/* Add logic */}} theme={theme}>
                  View Details
                </EventButton>
                <EventButton onClick={() => {/* Add logic */}} theme={theme}>
                  Generate Report
                </EventButton>
              </ButtonGroup>
            </SectionContent>
          </Section>
        </SectionGrid>
      </CenteredContainer>
    </PageContainer>
  );
}

export default FinancialsPayoutManagement;