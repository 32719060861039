import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useSponsorVendor } from '../contexts/SponsorVendorContext';
import { FaPlus, FaUpload, FaLink } from 'react-icons/fa';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.h1`
  font-size: 2rem;
  color: ${props => props.theme.text};
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${props => props.theme.buttonBackground};
  color: white; // Always white text
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${props => props.theme.buttonBackground};
  color: white; // Always white text
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
`;

const ImagePreview = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.danger};
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const SponsorVendorRegistration = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { addSponsorVendor } = useSponsorVendor();
  const [formData, setFormData] = useState({
    type: 'sponsor',
    name: '',
    description: '',
    logo: '',
    website: '',
    sponsorshipAmount: '',
    category: '',
    customCategory: '',
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const [error, setError] = useState('');
  const [logoType, setLogoType] = useState('upload'); // 'upload' or 'url'

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      ...(name === 'category' && value !== 'custom' ? { customCategory: '' } : {})
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
        setFormData({ ...formData, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.type === 'sponsor' && formData.category === 'Money' && !formData.sponsorshipAmount) {
      setError('Please enter the sponsorship amount for Money category.');
      return;
    }
    
    // Create a new object with all the form data
    const newSponsorVendor = {
      ...formData,
      logo: uploadedImage || formData.logo, // Use uploaded image if available
    };
    
    addSponsorVendor(newSponsorVendor, eventId);
    
    // Reset form
    setFormData({
      type: 'sponsor',
      name: '',
      description: '',
      logo: '',
      website: '',
      sponsorshipAmount: '',
      category: '',
      customCategory: '',
    });
    setUploadedImage(null);
    setError('');
    alert('Sponsor/Vendor registered successfully!');
    navigate(`/events/${eventId}/sponsors-vendors`);
  };

  const sponsorCategories = ['Money', 'Equipment', 'Fuel', 'Tires', 'Media Coverage', 'custom'];
  const vendorCategories = ['Food', 'Merchandise', 'Auto Parts', 'custom'];

  return (
    <PageContainer>
      <Header theme={theme}>Sponsor/Vendor Registration</Header>
      <Form onSubmit={handleSubmit}>
        <Select
          name="type"
          value={formData.type}
          onChange={handleChange}
          theme={theme}
        >
          <option value="sponsor">Sponsor</option>
          <option value="vendor">Vendor</option>
        </Select>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
          theme={theme}
        />
        <Input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description (optional)"
          theme={theme}
        />
        <Select
          name="category"
          value={formData.category}
          onChange={handleChange}
          theme={theme}
          required
        >
          <option value="">Select Category</option>
          {(formData.type === 'sponsor' ? sponsorCategories : vendorCategories).map(category => (
            <option key={category} value={category}>{category === 'custom' ? 'Other' : category}</option>
          ))}
        </Select>
        {formData.category === 'custom' && (
          <Input
            type="text"
            name="customCategory"
            value={formData.customCategory}
            onChange={handleChange}
            placeholder="Enter custom category"
            required
            theme={theme}
          />
        )}
        <Select
          name="logoType"
          value={logoType}
          onChange={(e) => setLogoType(e.target.value)}
          theme={theme}
        >
          <option value="upload">Upload Logo</option>
          <option value="url">Logo URL</option>
        </Select>
        {logoType === 'upload' ? (
          <FileInputLabel theme={theme}>
            <FaUpload /> Upload Logo
            <FileInput
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required={logoType === 'upload'}
            />
          </FileInputLabel>
        ) : (
          <Input
            type="url"
            name="logo"
            value={formData.logo}
            onChange={handleChange}
            placeholder="Logo URL"
            required={logoType === 'url'}
            theme={theme}
          />
        )}
        {uploadedImage && (
          <ImagePreviewContainer>
            <ImagePreview src={uploadedImage} alt="Uploaded logo" />
          </ImagePreviewContainer>
        )}
        {error && <ErrorMessage theme={theme}>{error}</ErrorMessage>}
        <Input
          type="url"
          name="website"
          value={formData.website}
          onChange={handleChange}
          placeholder="Website"
          theme={theme}
        />
        {formData.type === 'sponsor' && (
          <Input
            type="number"
            name="sponsorshipAmount"
            value={formData.sponsorshipAmount}
            onChange={handleChange}
            placeholder="Sponsorship Amount"
            theme={theme}
          />
        )}
        <Button type="submit" theme={theme}>
          <FaPlus /> Register
        </Button>
      </Form>
    </PageContainer>
  );
};

export default SponsorVendorRegistration;