import React, { useState } from 'react';
import styled from 'styled-components';
import { FaDollarSign, FaMoneyBillWave, FaUsers } from 'react-icons/fa';
import { Button } from './SharedStyles';

const Container = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: ${props => props.theme.text};
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1.1rem;
  background-color: ${props => props.theme.inputBackground};
  color: #000000;
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.onPrimary};
  padding: 1rem 2rem;
  font-size: 1.1rem;
  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StatCard = styled.div`
  background-color: ${props => props.theme.surface};
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
`;

const StatLabel = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 0.5rem;
`;

const StatValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.text};
`;

function EntryFeeManagement({ onPaymentIntent, theme }) {
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onPaymentIntent(parseInt(amount, 10) * 100); // Convert to cents for Stripe
  };

  return (
    <Container theme={theme}>
      <Header>
        <FaMoneyBillWave /> Entry Fee Management
      </Header>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="feeAmount">Entry Fee Amount</Label>
          <Input
            id="feeAmount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter fee amount"
            theme={theme}
          />
        </InputGroup>
        <EventButton type="submit" theme={theme}>
          <FaDollarSign /> Collect Fee
        </EventButton>
      </Form>
      <StatsContainer>
        <StatCard theme={theme}>
          <StatLabel>Total Collected</StatLabel>
          <StatValue>$10,000</StatValue>
        </StatCard>
        <StatCard theme={theme}>
          <StatLabel>Participants</StatLabel>
          <StatValue>50</StatValue>
        </StatCard>
        <StatCard theme={theme}>
          <StatLabel>Average Fee</StatLabel>
          <StatValue>$200</StatValue>
        </StatCard>
      </StatsContainer>
    </Container>
  );
}

export default EntryFeeManagement;