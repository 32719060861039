import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { FaYoutube, FaTwitch, FaCopy, FaPlay, FaFacebook, FaInstagram, FaVideo, FaEnvelope } from 'react-icons/fa';
import { SiZoom } from 'react-icons/si';
import { PageContainer, Container, Header, Button } from './SharedStyles';

const StreamingSection = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StreamingTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const StreamingForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
`;

const StreamingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const ViewerSection = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ViewerTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.text};
`;

const StreamContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
`;

const StreamIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const ShareLink = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const ShareInput = styled(Input)`
  flex-grow: 1;
`;

const PlatformIcon = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const PlatformRequirements = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
`;

const ShareSection = styled.div`
  margin-top: 1rem;
`;

const EmailInput = styled.input`
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
`;

function MediaAndReplay() {
  const { theme } = useTheme();
  const [streamPlatform, setStreamPlatform] = useState('youtube');
  const [streamKey, setStreamKey] = useState('');
  const [streamUrl, setStreamUrl] = useState('');
  const [facebookLiveApiKey, setFacebookLiveApiKey] = useState('');
  const [instagramRtmpUrl, setInstagramRtmpUrl] = useState('');
  const [zoomMeetingId, setZoomMeetingId] = useState('');
  const [zoomPassword, setZoomPassword] = useState('');
  const [streamyardBroadcastUrl, setStreamyardBroadcastUrl] = useState('');
  const [email, setEmail] = useState('');

  const handleStartStream = (e) => {
    e.preventDefault();
    // Here you would implement the logic to start the stream
    console.log(`Starting stream on ${streamPlatform}`);
    // For demonstration, we'll set a dummy stream URL
    setStreamUrl(`https://${streamPlatform}.com/watch?v=dummyStreamId`);
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(streamUrl);
    alert('Share link copied to clipboard!');
  };

  const renderPlatformInputs = () => {
    switch (streamPlatform) {
      case 'youtube':
      case 'twitch':
        return (
          <Input 
            type="text" 
            placeholder="Enter stream key" 
            value={streamKey}
            onChange={(e) => setStreamKey(e.target.value)}
            theme={theme}
          />
        );
      case 'facebook':
        return (
          <Input 
            type="text" 
            placeholder="Enter Facebook Live API Key" 
            value={facebookLiveApiKey}
            onChange={(e) => setFacebookLiveApiKey(e.target.value)}
            theme={theme}
          />
        );
      case 'instagram':
        return (
          <Input 
            type="text" 
            placeholder="Enter Instagram RTMP URL" 
            value={instagramRtmpUrl}
            onChange={(e) => setInstagramRtmpUrl(e.target.value)}
            theme={theme}
          />
        );
      case 'zoom':
        return (
          <>
            <Input 
              type="text" 
              placeholder="Enter Zoom Meeting ID" 
              value={zoomMeetingId}
              onChange={(e) => setZoomMeetingId(e.target.value)}
              theme={theme}
            />
            <Input 
              type="password" 
              placeholder="Enter Zoom Meeting Password" 
              value={zoomPassword}
              onChange={(e) => setZoomPassword(e.target.value)}
              theme={theme}
            />
          </>
        );
      case 'streamyard':
        return (
          <Input 
            type="text" 
            placeholder="Enter StreamYard Broadcast URL" 
            value={streamyardBroadcastUrl}
            onChange={(e) => setStreamyardBroadcastUrl(e.target.value)}
            theme={theme}
          />
        );
      default:
        return null;
    }
  };

  const renderPlatformRequirements = () => {
    switch (streamPlatform) {
      case 'youtube':
        return "You'll need a YouTube account and a stream key from your YouTube Live Dashboard.";
      case 'twitch':
        return "You'll need a Twitch account and a stream key from your Twitch Creator Dashboard.";
      case 'facebook':
        return "You'll need a Facebook account and a Live API Key from your Facebook Live Producer.";
      case 'instagram':
        return "You'll need an Instagram business account and the RTMP URL from the Instagram app.";
      case 'zoom':
        return "You'll need a Zoom account, a Meeting ID, and the meeting password.";
      case 'streamyard':
        return "You'll need a StreamYard account and the Broadcast URL from your StreamYard dashboard.";
      default:
        return "";
    }
  };

  const renderPlatformIcon = () => {
    switch (streamPlatform) {
      case 'youtube':
        return <FaYoutube />;
      case 'twitch':
        return <FaTwitch />;
      case 'facebook':
        return <FaFacebook />;
      case 'instagram':
        return <FaInstagram />;
      case 'zoom':
        return <SiZoom />;
      case 'streamyard':
        return <FaVideo />;
      default:
        return <FaPlay />;
    }
  };

  const sendStreamLink = () => {
    // Here you would implement the logic to send the email
    // This is a placeholder function
    console.log(`Sending stream link to ${email}`);
    alert(`Stream link sent to ${email}`);
    setEmail('');
  };

  const viewerLink = `${window.location.origin}/live-stream-viewer?url=${encodeURIComponent(streamUrl)}`;

  return (
    <PageContainer>
      <Container>
        <Header theme={theme}>Media and Replay</Header>
        <StreamingSection theme={theme}>
          <StreamingTitle theme={theme}>Start Streaming</StreamingTitle>
          <StreamingForm onSubmit={handleStartStream}>
            <Select 
              value={streamPlatform} 
              onChange={(e) => setStreamPlatform(e.target.value)}
              theme={theme}
            >
              <option value="youtube">YouTube</option>
              <option value="twitch">Twitch</option>
              <option value="facebook">Facebook Live</option>
              <option value="instagram">Instagram Live</option>
              <option value="zoom">Zoom</option>
              <option value="streamyard">StreamYard</option>
            </Select>
            {renderPlatformInputs()}
            <StreamingButton type="submit" theme={theme}>
              <PlatformIcon>
                {renderPlatformIcon()}
              </PlatformIcon>
              Start Streaming
            </StreamingButton>
          </StreamingForm>
          <PlatformRequirements theme={theme}>
            {renderPlatformRequirements()}
          </PlatformRequirements>
        </StreamingSection>
        {streamUrl && (
          <ViewerSection theme={theme}>
            <ViewerTitle theme={theme}>Live Stream</ViewerTitle>
            <StreamContainer>
              <StreamIframe 
                src={streamUrl} 
                allowFullScreen
              />
            </StreamContainer>
            <ShareLink>
              <ShareInput 
                type="text" 
                value={viewerLink} 
                readOnly 
                theme={theme}
              />
              <StreamingButton onClick={copyShareLink} theme={theme}>
                <FaCopy />
                Copy Link
              </StreamingButton>
            </ShareLink>
            <ShareSection>
              <EmailInput 
                type="email" 
                placeholder="Enter email to share"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                theme={theme}
              />
              <StreamingButton onClick={sendStreamLink} theme={theme}>
                <FaEnvelope />
                Send Link
              </StreamingButton>
            </ShareSection>
          </ViewerSection>
        )}
      </Container>
    </PageContainer>
  );
}

export default MediaAndReplay;