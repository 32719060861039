import React from 'react';
import styled from 'styled-components';
import { FaMoneyBillWave, FaTrophy, FaMedal, FaAward } from 'react-icons/fa';
import { Button } from './SharedStyles';

const Container = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PayoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const PayoutCard = styled.div`
  background-color: ${props => props.theme.surface};
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const DriverName = styled.h3`
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1.1rem;
  background-color: ${props => props.theme.inputBackground};
  color: #000000;
  text-align: center;
  margin-top: 0.5rem;
`;

const EventButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.onPrimary};
  padding: 1rem 2rem;
  font-size: 1.1rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const getIcon = (index) => {
  switch (index) {
    case 0: return <FaTrophy color="gold" size={24} />;
    case 1: return <FaMedal color="silver" size={24} />;
    case 2: return <FaMedal color="#cd7f32" size={24} />;
    default: return <FaAward color="#4a4a4a" size={24} />;
  }
};

function PayoutDistribution({ payouts, onUpdatePayouts, theme }) {
  const handlePayoutChange = (index, amount) => {
    const newPayouts = [...payouts];
    newPayouts[index].amount = parseInt(amount, 10);
    onUpdatePayouts(newPayouts);
  };

  return (
    <Container theme={theme}>
      <Header>
        <FaMoneyBillWave /> Payout Distribution
      </Header>
      <PayoutGrid>
        {payouts.map((payout, index) => (
          <PayoutCard key={index} theme={theme}>
            {getIcon(index)}
            <DriverName theme={theme}>{payout.driver}</DriverName>
            <Input
              type="number"
              value={payout.amount}
              onChange={(e) => handlePayoutChange(index, e.target.value)}
              theme={theme}
              placeholder="Enter payout amount"
            />
          </PayoutCard>
        ))}
      </PayoutGrid>
      <EventButton theme={theme}>
        <FaMoneyBillWave /> Distribute Payouts
      </EventButton>
    </Container>
  );
}

export default PayoutDistribution;