import React from 'react';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { SponsorVendorProvider } from './contexts/SponsorVendorContext';
import Navigation from './components/Navigation';
import styled from 'styled-components';
import LandingPage from './components/LandingPage';
import EventManagement from './components/EventManagement';
import RacerRegistration from './components/RacerRegistration';
import ClassManagement from './components/ClassManagement';
import ChipDrawing from './components/ChipDrawing';
import RaceResults from './components/RaceResults';
import Features from './components/Features';
import PricingPage from './components/PricingPage';
import AboutPage from './components/About';
import ContactPage from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import SignUp from './components/Auth/SignUp';
import Login from './components/Auth/Login';
import Onboarding from './components/Onboarding';
import PrivateRoute from './components/PrivateRoute';
import SharedView from './components/SharedView';
import UserProfile from './components/UserProfile';
import ForgotPassword from './components/Auth/ForgotPassword';
import TeamManagement from './components/TeamManagement';
import BracketBuilder from './components/BracketBuilder';
import RaceSimulation from './components/RaceSimulation';
import EventResults from './components/EventResults';
import RacerRegistrationForm from './components/RacerRegistrationForm';
import SpectatorRegistration from './components/SpectatorRegistration';
import AdminPanel from './pages/AdminPanel';
import ScheduleManagement from './pages/ScheduleManagement';
import WeatherTrackConditions from './components/WeatherTrackConditions';
import SponsorsVendorsPage from './pages/SponsorsVendorsPage';
import SponsorVendorRegistration from './pages/SponsorVendorRegistration';
import FinancialsPayoutManagement from './components/FinancialsPayoutManagement';
import MediaAndReplay from './components/MediaAndReplay';
import LiveStreamViewer from './components/LiveStreamViewer';
import FinancialsAndPayoutManagement from './pages/FinancialsAndPayoutManagement';
import Settings from './components/Settings'; // Update this line
import NoPrepRace from './components/NoPrepRace';
import SharedChipDrawingView from './components/SharedChipDrawingView';
import SharedBracketView from './components/SharedBracketView';
import RacerDatabase from './components/RacerDatabase';
import CategoryDatabase from './components/CategoryDatabase'; // Import the new component
import QualifyingProgram from './components/QualifyingProgram';
import LadderManager from './components/LadderManager'; // Import LadderManager

const MainContent = styled.div`
  padding-top: 80px;
  max-width: 100%;
  overflow-x: hidden;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <SponsorVendorProvider>
          <Router>
            <AppContainer>
              <Navigation />
              <MainContent>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/events" element={
                    <PrivateRoute>
                      <EventManagement />
                    </PrivateRoute>
                  } />
                  <Route path="/events/:eventId/racers" element={
                    <PrivateRoute>
                      <RacerRegistration />
                    </PrivateRoute>
                  } />
                  <Route path="/events/:eventId/classes" element={
                    <PrivateRoute>
                      <ClassManagement />
                    </PrivateRoute>
                  } />
                  <Route path="/events/:eventId/draw" element={<ChipDrawing />} />
                  <Route path="/events/:eventId/draw/shared/:shareId" element={<SharedChipDrawingView />} />
                  <Route path="/events/:eventId/results" element={<EventResults />} />
                  <Route path="/events/:eventId/draw/shared/:encodedState" element={<SharedView />} />
                  <Route path="/features" element={<Features />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="/profile" element={<UserProfile />} />
                  <Route path="/events/:eventId/teams" element={
                    <PrivateRoute>
                      <TeamManagement />
                    </PrivateRoute>
                  } />
                  <Route path="/bracket-builder" element={<BracketBuilder />} />
                  <Route path="/race-simulation" element={<RaceSimulation />} />
                  <Route path="/racer-registration" element={<RacerRegistrationForm />} />
                  <Route path="/spectator-registration" element={<SpectatorRegistration />} />
                  <Route path="/admin" element={<AdminPanel />} />
                  <Route path="/events/:eventId/schedule" element={<ScheduleManagement />} />
                  <Route path="/weather-track-conditions" element={<WeatherTrackConditions />} />
                  <Route path="/events/:eventId/sponsor-vendor-registration" element={<SponsorVendorRegistration />} />
                  <Route path="/events/:eventId/sponsors-vendors" element={<SponsorsVendorsPage />} />
                  <Route path="/sponsor-vendor-registration" element={<SponsorVendorRegistration />} />
                  <Route path="/events/:eventId/financials" element={
                    <PrivateRoute>
                      <FinancialsAndPayoutManagement />
                    </PrivateRoute>
                  } />
                  <Route path="/media-and-replay" element={
                    <PrivateRoute>
                      <MediaAndReplay />
                    </PrivateRoute>
                  } />
                  <Route path="/live-stream-viewer" element={<LiveStreamViewer />} />
                  <Route path="/settings" element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  } />
                  <Route path="/events/:eventId/bracket" element={<BracketBuilder />} />
                  <Route path="/events/:eventId/chip-draw" element={<ChipDrawing />} />
                  <Route path="/events/:eventId/no-prep" element={<NoPrepRace />} />
                  <Route path="/shared-bracket/:eventId/:shareId" element={<SharedBracketView />} />
                  <Route path="/racer-database" element={<RacerDatabase />} />
                  <Route path="/category-database" element={<CategoryDatabase />} /> {/* New route */}
                  <Route path="/qualifying-program" element={<QualifyingProgram />} />
                  <Route path="/ladder-manager" element={<LadderManager />} /> {/* Add route for LadderManager */}
                </Routes>
              </MainContent>
            </AppContainer>
          </Router>
        </SponsorVendorProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
