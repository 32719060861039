import React, { useState, useRef, useEffect } from 'react';

import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useTheme } from '../contexts/ThemeContext';

import { FaUser, FaBars, FaTimes, FaTachometerAlt, FaSun, FaMoon, FaChevronDown, FaUsers, FaTicketAlt, FaStore, FaDatabase, FaListOl } from 'react-icons/fa';

import { useAuth } from '../contexts/AuthContext';

// Add this line to import your logo

import logoImage from '../assets/RaceGrid-Master.png';



const NavContainer = styled.header`

  display: flex;

  height: 80px;

  align-items: center;

  justify-content: space-between;

  padding: 0 24px;

  background-color: ${props => props.theme.navBackground};

  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  z-index: 1000;

`;



const Logo = styled(Link)`

  font-size: ${({ theme }) => theme.fontSizes.large};

  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  display: flex;

  align-items: center;

  text-decoration: none;

  color: ${props => props.theme.text};



  img {

    height: 50px; // Adjust this value to fit your logo's dimensions

    margin-right: 10px; // Add some space between the logo and text

  }

`;



const Nav = styled.nav`

  display: flex;

  gap: 1rem;



  @media (max-width: 1024px) {

    display: ${props => props.isOpen ? 'flex' : 'none'};

    flex-direction: column;

    position: absolute;

    top: 80px;

    left: 0;

    right: 0;

    background-color: ${props => props.theme.navBackground};

    padding: 1rem;

    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  }

`;



const NavLink = styled(Link)`

  font-size: ${({ theme }) => theme.fontSizes.medium};

  font-weight: ${({ theme }) => theme.fontWeights.medium};

  text-decoration: none;

  color: ${props => props.theme.text};

  transition: color 0.2s;

  padding: 0.5rem 1rem;

  border-radius: 4px;

  display: flex;

  align-items: center;



  &:hover {

    color: ${props => props.theme.primary};

    background-color: ${props => props.theme.hover};

  }



  @media (max-width: 1024px) {

    padding: 1rem;

  }

`;



const UserMenu = styled.div`

  position: relative;

  display: flex;

  align-items: center;

`;



const UserMenuButton = styled.button`

  background: none;

  border: none;

  cursor: pointer;

  color: ${props => props.theme.text};

  font-size: 1.5rem;

`;



const UserMenuDropdown = styled.div`

  position: absolute;

  top: 100%;

  right: 0;

  background-color: ${props => props.theme.background};

  border: 1px solid ${props => props.theme.border};

  border-radius: 4px;

  padding: 0.5rem;

  display: ${props => props.isOpen ? 'block' : 'none'};

  z-index: 1001;

  * {

    outline: 1px solid red;

  }

`;



const UserMenuItem = styled.div`

  display: block;

  padding: 1rem;

  color: black;

  cursor: pointer;

  background-color: #f0f0f0;

  border: 1px solid #ccc;

  margin-bottom: 5px;



  &:hover {

    background-color: #e0e0e0;

  }



  position: relative;

  z-index: 1000;

`;



const ThemeToggle = styled.button`

  background: none;

  border: none;

  color: ${props => props.theme.text};

  font-size: 1.5rem;

  cursor: pointer;

  margin-left: 1rem;

  display: flex;

  align-items: center;

  justify-content: center;

  width: 40px;

  height: 40px;

  border-radius: 50%;

  transition: background-color 0.2s;



  &:hover {

    background-color: ${props => props.theme.hover};

  }

`;



const MobileMenuButton = styled.button`

  display: none;

  background: none;

  border: none;

  color: ${props => props.theme.text};

  font-size: 1.5rem;

  cursor: pointer;



  @media (max-width: 1024px) {

    display: block;

  }

`;



const DropdownMenu = styled.div`

  position: relative;

  display: inline-block;

`;



const DropdownContent = styled.div`

  display: ${props => props.isOpen ? 'block' : 'none'};

  position: absolute;

  background-color: ${props => props.theme.background};

  min-width: 160px;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  z-index: 1;

`;



const DropdownItem = styled(Link)`

  color: ${props => props.theme.text};

  padding: 12px 16px;

  text-decoration: none;

  display: block;

  &:hover {

    background-color: ${props => props.theme.hover};

  }

`;



const RightSection = styled.div`

  display: flex;

  align-items: center;

  gap: 1rem;

`;



const AuthButton = styled(Link)`

  padding: 0.5rem 1rem;

  border-radius: 4px;

  text-decoration: none;

  font-weight: ${({ theme }) => theme.fontWeights.medium};

  transition: all 0.2s;



  &.signup {

    background-color: ${props => props.theme.primary};

    color: white;



    &:hover {

      background-color: ${props => props.theme.primaryDark};

    }

  }



  &.login {

    border: 1px solid ${props => props.theme.primary};

    color: ${props => props.theme.primary};



    &:hover {

      background-color: ${props => props.theme.primaryLight};

    }

  }

`;



const Navigation = () => {

  const navigate = useNavigate();

  const { eventId } = useParams();

  const location = useLocation();

  const { theme, toggleTheme } = useTheme();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isContactDropdownOpen, setIsContactDropdownOpen] = useState(false);

  const [isEventsDropdownOpen, setIsEventsDropdownOpen] = useState(false);

  const [isRegistrationDropdownOpen, setIsRegistrationDropdownOpen] = useState(false);

  const [isMediaCenterDropdownOpen, setIsMediaCenterDropdownOpen] = useState(false);

  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);

  const [isAdminToolsDropdownOpen, setIsAdminToolsDropdownOpen] = useState(false);

  const navRef = useRef(null);

  const { currentUser, logout, isPaidUser } = useAuth();



  const isEventPage = location.pathname.includes('/events/');

  const isBracketBuilderPage = location.pathname.includes('/bracket-builder');



  const profileRef = useRef(null);

  const settingsRef = useRef(null);



  useEffect(() => {

    const handleProfileClick = () => {

      console.log('Profile clicked');

      setIsUserMenuOpen(false);

      navigate('/profile');

    };



    const handleSettingsClick = () => {

      console.log('Settings clicked');

      setIsUserMenuOpen(false);

      navigate('/settings');

    };



    const profileElement = profileRef.current;

    const settingsElement = settingsRef.current;



    if (profileElement) {

      profileElement.addEventListener('click', handleProfileClick);

    }



    if (settingsElement) {

      settingsElement.addEventListener('click', handleSettingsClick);

    }



    return () => {

      if (profileElement) {

        profileElement.removeEventListener('click', handleProfileClick);

      }

      if (settingsElement) {

        settingsElement.removeEventListener('click', handleSettingsClick);

      }

    };

  }, [navigate]);



  const toggleMobileMenu = () => {

    setIsMobileMenuOpen(!isMobileMenuOpen);

    // Close all dropdowns when toggling mobile menu

    setIsEventsDropdownOpen(false);

    setIsRegistrationDropdownOpen(false);

    setIsMediaCenterDropdownOpen(false);

    setIsResourcesDropdownOpen(false);

    setIsAdminToolsDropdownOpen(false);

  };



  const toggleDropdown = (dropdownSetter) => (e) => {

    e.preventDefault();

    e.stopPropagation();

    dropdownSetter(prev => !prev);

  };



  useEffect(() => {

    const handleClickOutside = (event) => {

      if (navRef.current && !navRef.current.contains(event.target)) {

        setIsEventsDropdownOpen(false);

        setIsRegistrationDropdownOpen(false);

        setIsMediaCenterDropdownOpen(false);

        setIsResourcesDropdownOpen(false);

        setIsUserMenuOpen(false);

        setIsAdminToolsDropdownOpen(false);

      }

    };



    document.addEventListener('mousedown', handleClickOutside);

    return () => {

      document.removeEventListener('mousedown', handleClickOutside);

    };

  }, []);



  const handleLogout = async () => {

    try {

      await logout();

      navigate('/');

    } catch (error) {

      console.error('Failed to log out', error);

    }

  };



  const handleUserMenuItemClick = (path) => (e) => {

    e.stopPropagation();

    console.log(`Clicked on ${path}`);

    setIsUserMenuOpen(false);

    navigate(path);

  };



  const renderNavLinks = () => (

    <>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsEventsDropdownOpen)} theme={theme}>

          Event Management <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isEventsDropdownOpen} theme={theme}>

          <DropdownItem to="/events" theme={theme}>All Events</DropdownItem>

          {(isEventPage || currentUser) && (

            <>

              <DropdownItem to={`/events/${eventId}/teams`} theme={theme}>Manage Teams</DropdownItem>

              <DropdownItem to={`/events/${eventId}/classes`} theme={theme}>Manage Classes</DropdownItem>

              <DropdownItem to={`/events/${eventId}/racers`} theme={theme}>Manage Racers</DropdownItem>

              <DropdownItem to={`/events/${eventId}/draw`} theme={theme}>Chip Drawing</DropdownItem>

              <DropdownItem to="/bracket-builder" theme={theme}>Bracket Builder</DropdownItem>

              <DropdownItem to={`/events/${eventId}/results`} theme={theme}>Results</DropdownItem>

            </>

          )}

        </DropdownContent>

      </DropdownMenu>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsAdminToolsDropdownOpen)} theme={theme}>

          Admin Tools <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isAdminToolsDropdownOpen} theme={theme}>

          <DropdownItem to="/admin" theme={theme}>Ticket Management</DropdownItem>

          <DropdownItem to={`/events/${eventId}/schedule`} theme={theme}>Schedule Management</DropdownItem>

          <DropdownItem to={`/events/${eventId}/sponsors-vendors`} theme={theme}>Sponsors & Vendors</DropdownItem>

          <DropdownItem to={`/events/${eventId}/financials`} theme={theme}>Financials & Payouts</DropdownItem>

          <DropdownItem to="/racer-database" theme={theme}>Racer Database</DropdownItem>

          <DropdownItem to="/category-database" theme={theme}>Category Database</DropdownItem> {/* Moved link */}

          <DropdownItem to="/qualifying-program" theme={theme}>Qualifying Program</DropdownItem>

          <DropdownItem to="/ladder-manager" theme={theme}>Ladder Manager</DropdownItem> {/* New link */}

        </DropdownContent>

      </DropdownMenu>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsRegistrationDropdownOpen)} theme={theme}>

          Registration <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isRegistrationDropdownOpen} theme={theme}>

          <DropdownItem to="/racer-registration" theme={theme}>Racer Registration</DropdownItem>

          <DropdownItem to="/spectator-registration" theme={theme}>Spectator Registration</DropdownItem>

          <DropdownItem to="/sponsor-vendor-registration" theme={theme}>Sponsor/Vendor Registration</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

      <NavLink to="/weather-track-conditions" theme={theme}>Track & Weather</NavLink>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsMediaCenterDropdownOpen)} theme={theme}>

          Media Center <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isMediaCenterDropdownOpen} theme={theme}>

          <DropdownItem to="/media-and-replay" theme={theme}>Media and Replay</DropdownItem>

          <DropdownItem to="/live-stream-viewer" theme={theme}>Live Stream Viewer</DropdownItem>

          <DropdownItem to="/race-simulation" theme={theme}>Race Simulation</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsResourcesDropdownOpen)} theme={theme}>

          Resources <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isResourcesDropdownOpen} theme={theme}>

          <DropdownItem to="/features" theme={theme}>Features</DropdownItem>

          <DropdownItem to="/pricing" theme={theme}>Pricing</DropdownItem>

          <DropdownItem to="/about" theme={theme}>About</DropdownItem>

          <DropdownItem to="/contact" theme={theme}>Contact Us</DropdownItem>

          <DropdownItem to="/privacy" theme={theme}>Privacy Policy</DropdownItem>

          <DropdownItem to="/terms" theme={theme}>Terms of Service</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

    </>

  );



  const renderLoggedOutNavLinks = () => (

    <>

      <NavLink to="/events" theme={theme}>Events</NavLink>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsRegistrationDropdownOpen)} theme={theme}>

          Registration <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isRegistrationDropdownOpen} theme={theme}>

          <DropdownItem to="/racer-registration" theme={theme}>Racer Registration</DropdownItem>

          <DropdownItem to="/spectator-registration" theme={theme}>Spectator Registration</DropdownItem>

          <DropdownItem to="/sponsor-vendor-registration" theme={theme}>Sponsor/Vendor Registration</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

      <NavLink to="/weather-track-conditions" theme={theme}>Track & Weather</NavLink>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsMediaCenterDropdownOpen)} theme={theme}>

          Media Center <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isMediaCenterDropdownOpen} theme={theme}>

          <DropdownItem to="/media-and-replay" theme={theme}>Media and Replay</DropdownItem>

          <DropdownItem to="/live-stream-viewer" theme={theme}>Live Stream Viewer</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

      <DropdownMenu>

        <NavLink as="div" onClick={toggleDropdown(setIsResourcesDropdownOpen)} theme={theme}>

          Resources <FaChevronDown style={{ marginLeft: '5px' }} />

        </NavLink>

        <DropdownContent isOpen={isResourcesDropdownOpen} theme={theme}>

          <DropdownItem to="/features" theme={theme}>Features</DropdownItem>

          <DropdownItem to="/pricing" theme={theme}>Pricing</DropdownItem>

          <DropdownItem to="/about" theme={theme}>About</DropdownItem>

          <DropdownItem to="/contact" theme={theme}>Contact Us</DropdownItem>

          <DropdownItem to="/privacy" theme={theme}>Privacy Policy</DropdownItem>

          <DropdownItem to="/terms" theme={theme}>Terms of Service</DropdownItem>

        </DropdownContent>

      </DropdownMenu>

    </>

  );



  return (

    <NavContainer theme={theme}>

      <Logo to="/" theme={theme}>

        <img src={logoImage} alt="RaceGrid Master Logo" />

        <span>RaceGrid Master</span>

      </Logo>

      <MobileMenuButton onClick={toggleMobileMenu} theme={theme}>

        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}

      </MobileMenuButton>

      <Nav isOpen={isMobileMenuOpen} theme={theme} ref={navRef}>

        {currentUser ? renderNavLinks() : renderLoggedOutNavLinks()}

      </Nav>

      <RightSection>

        {!currentUser ? (

          <>

            <AuthButton to="/login" className="login" theme={theme}>Log In</AuthButton>

            <AuthButton to="/signup" className="signup" theme={theme}>Sign Up</AuthButton>

          </>

        ) : (

          <>

            <AuthButton as="button" onClick={handleLogout} className="login" theme={theme}>Log Out</AuthButton>

            <UserMenu onClick={(e) => {

              console.log('User menu clicked');

              e.stopPropagation();

            }}>

              <UserMenuButton onClick={toggleDropdown(setIsUserMenuOpen)} theme={theme}>

                <FaUser />

              </UserMenuButton>

              <UserMenuDropdown 

                isOpen={isUserMenuOpen} 

                theme={theme}

                onClick={(e) => {

                  console.log('Dropdown clicked');

                  e.stopPropagation();

                }}

              >

                <div onClick={handleUserMenuItemClick('/profile')} style={{ cursor: 'pointer', padding: '10px', border: '1px solid black' }}>

                  Profile

                </div>

                <div onClick={handleUserMenuItemClick('/settings')} style={{ cursor: 'pointer', padding: '10px', border: '1px solid black' }}>

                  Settings

                </div>

              </UserMenuDropdown>

            </UserMenu>

          </>

        )}

        <ThemeToggle onClick={toggleTheme} theme={theme}>

          {theme.mode === 'light' ? <FaMoon /> : <FaSun />}

        </ThemeToggle>

      </RightSection>

    </NavContainer>

  );

};



export default Navigation;








