import React, { useState, useEffect, useRef } from 'react';















import styled from 'styled-components';















import { useTheme } from '../contexts/ThemeContext';















import { FaSearch, FaPlus, FaCopy, FaTrash, FaPrint, FaEdit, FaEye, FaSync, FaCog } from 'react-icons/fa';















import { PageContainer, Container, Header, Button, Input, Select } from './SharedStyles';















import RacerForm from './RacerForm';















import { useReactToPrint } from 'react-to-print';















import ListBoxComponent from './ListBoxComponent';















import IndexUpdateDropdown from './IndexUpdateDropdown';















import QuickAddForm from './QuickAddForm';















import CategoryCopyModal from './CategoryCopyModal';















import EntryNumberLookup from './EntryNumberLookup';















import PrintPreviewModal from './PrintPreviewModal';































const DatabaseContainer = styled(Container)`















  max-width: 1200px;















  padding: 1rem;















`;































const SearchBar = styled.div`















  display: flex;















  flex-wrap: wrap;















  gap: 0.5rem;















  margin-bottom: 1rem;















`;































const CategorySelect = styled(Select)`















  flex: 1;















  min-width: 150px;















`;































const SearchInput = styled(Input)`















  flex: 2;















  min-width: 200px;















`;































const ActionButton = styled(Button)`















  font-size: 0.9rem;















  padding: 0.5rem 0.75rem;















  display: flex;















  align-items: center;















  justify-content: center;































  svg {















    margin-right: 0.3rem;















  }































  @media (max-width: 768px) {















    font-size: 0.8rem;















    padding: 0.4rem 0.6rem;















  }















`;































const RacerList = styled.div`















  display: grid;















  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));















  gap: 1rem;















`;































const RacerCard = styled.div`















  background-color: ${props => props.theme.cardBackground};















  border-radius: 8px;















  padding: 1rem;















  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);















`;































const RacerInfo = styled.p`















  margin: 0.5rem 0;















  font-size: 0.9rem;















`;































const ButtonGroup = styled.div`















  display: flex;















  flex-wrap: wrap;















  gap: 0.5rem;















  margin-bottom: 1rem;















`;































const Pagination = styled.div`















  display: flex;















  justify-content: center;















  margin-top: 2rem;















`;































const PageButton = styled(Button)`















  margin: 0 0.5rem;















`;































const Modal = styled.div`















  position: fixed;















  top: 50%;















  left: 50%;















  transform: translate(-50%, -50%);















  background-color: ${props => props.theme.background};















  padding: 2rem;















  border-radius: 8px;















  z-index: 1000;















`;
















const Overlay = styled.div`















  position: fixed;















  top: 0;















  left: 0;















  right: 0;















  bottom: 0;















  background-color: rgba(0, 0, 0, 0.5);















  z-index: 999;















`;































const CategoryCounter = styled.span`















  margin-left: 0.5rem;















  font-size: 0.8rem;















  color: ${props => props.theme.textSecondary};















`;































const QuickLookupModal = styled(Modal)`















  width: 80%;















  max-width: 600px;















  max-height: 80vh;















  overflow-y: auto;















`;































const ConfigModal = styled.div`















  position: fixed;















  top: 50%;















  left: 50%;















  transform: translate(-50%, -50%);















  background-color: ${props => props.theme.background};















  padding: 2rem;















  border-radius: 8px;















  z-index: 1000;















  max-width: 500px;















  width: 90%;















`;































const ConfigForm = styled.form`















  display: flex;















  flex-direction: column;















  gap: 1rem;















`;































// Define initialRacerFields















const initialRacerFields = {















  name: '',















  entryNumber: '',















  awardedNumber: '',















  category: '',















  registrationStatus: 'Not Registered',















  yearToDatePoints: 0,















  // Add more fields as needed to match your 76 different data fields















};
















const API_BASE_URL = 'https://your-api-url.com/api'; // Replace with your actual API URL































const RacerDatabase = () => {















  const { theme } = useTheme();















  const [categories, setCategories] = useState([]);















  const [selectedCategory, setSelectedCategory] = useState('');















  const [searchTerm, setSearchTerm] = useState('');















  const [racers, setRacers] = useState([]);















  const [currentPage, setCurrentPage] = useState(1);















  const [racersPerPage] = useState(12);















  const [editingRacer, setEditingRacer] = useState(null);















  const [showForm, setShowForm] = useState(false);















  const [quickLookupRacer, setQuickLookupRacer] = useState(null);















  const printRef = useRef();















  const [useApi, setUseApi] = useState(false);















  const [apiConfig, setApiConfig] = useState({















    system: '',















    apiKey: '',















    baseUrl: '',















  });















  const [showConfig, setShowConfig] = useState(false);















  const [showCategoryCopyModal, setShowCategoryCopyModal] = useState(false);















  const [showPrintPreviewModal, setShowPrintPreviewModal] = useState(false);































  // State to manage visibility of sections, initialized to false















  const [showListBox, setShowListBox] = useState(false);















  const [showIndexDropdown, setShowIndexDropdown] = useState(false);















  const [showQuickAddForm, setShowQuickAddForm] = useState(false);















  const [showEntryLookup, setShowEntryLookup] = useState(false);































  const trackSystems = [















    { name: 'MyRacePass (MRP)', value: 'mrp' },















    { name: 'RaceMonitor', value: 'racemonitor' },















    { name: 'SpeedHive', value: 'speedhive' },















    { name: 'RaceDay', value: 'raceday' },















    { name: 'Motorsport Reg', value: 'motorsportreg' },















    { name: 'RaceResult', value: 'raceresult' },















    { name: 'TrackIntel', value: 'trackintel' },















    { name: 'Custom', value: 'custom' },















  ];































  useEffect(() => {















    if (useApi && apiConfig.system && apiConfig.apiKey && apiConfig.baseUrl) {















      fetchCategories();















      fetchRacers();















    } else if (!useApi) {















      loadCategories();















      loadRacers();















    }















  }, [useApi, apiConfig]);































  const loadCategories = () => {















    const generatedCategories = Array.from({ length: 99 }, (_, i) => ({















      id: i + 1,















      name: `Category ${i + 1}`















    }));















    setCategories(generatedCategories);















  };































  const loadRacers = () => {















    const generatedRacers = Array.from({ length: 100 }, (_, i) => ({















      id: i + 1,















      name: `Racer ${i + 1}`,















      category: `Category ${Math.floor(Math.random() * 99) + 1}`,















    }));















    setRacers(generatedRacers);















  };































  const fetchCategories = async () => {















    try {















      const response = await fetch(`${apiConfig.baseUrl}/categories`, {















        headers: { 'Authorization': `Bearer ${apiConfig.apiKey}` }















      });















      if (!response.ok) throw new Error('Failed to fetch categories');















      const data = await response.json();















      setCategories(data);















    } catch (err) {















      console.error('Error fetching categories:', err);















      loadCategories();















    }















  };































  const fetchRacers = async () => {















    try {















      const response = await fetch(`${apiConfig.baseUrl}/racers`, {















        headers: { 'Authorization': `Bearer ${apiConfig.apiKey}` }















      });















      if (!response.ok) throw new Error('Failed to fetch racers');















      const data = await response.json();















      setRacers(data);















    } catch (err) {















      console.error('Error fetching racers:', err);















      loadRacers();















    }















  };
















  const filteredRacers = racers.filter(racer => 















    (selectedCategory === '' || racer.category === selectedCategory) &&















    (searchTerm === '' || Object.values(racer).some(value => 















      value.toString().toLowerCase().includes(searchTerm.toLowerCase())















    ))















  );































  const indexOfLastRacer = currentPage * racersPerPage;















  const indexOfFirstRacer = indexOfLastRacer - racersPerPage;















  const currentRacers = filteredRacers.slice(indexOfFirstRacer, indexOfLastRacer);































  const paginate = (pageNumber) => setCurrentPage(pageNumber);































  const handleQuickAdd = () => {















    setEditingRacer(null);















    setShowForm(true);















  };































  const handleCopyCategory = () => {















    if (!selectedCategory) {















      alert("Please select a category to copy.");















      return;















    }















    const newCategoryName = prompt("Enter a name for the new category:");















    if (newCategoryName) {















      const newCategory = { id: categories.length + 1, name: newCategoryName };















      setCategories([...categories, newCategory]);















      const racersToCopy = racers.filter(racer => racer.category === selectedCategory);















      const copiedRacers = racersToCopy.map(racer => ({















        ...racer,















        id: Date.now() + Math.random(),















        category: newCategoryName















      }));















      setRacers([...racers, ...copiedRacers]);















    }















  };































  const handleDeleteCategory = () => {















    console.log('Delete Category');















  };































  const handlePrint = useReactToPrint({















    content: () => printRef.current,















  });































  const handleSearch = () => {















    setCurrentPage(1);















  };































  const handleEdit = (racer) => {















    setEditingRacer(racer);















    setShowForm(true);















  };































  const handleDelete = (racerId) => {















    setRacers(racers.filter(racer => racer.id !== racerId));















  };































  const copyRacerToCategory = (racer, targetCategory) => {















    const copiedRacer = { ...racer, id: Date.now(), category: targetCategory };















    setRacers([...racers, copiedRacer]);















  };































  const handleSaveRacer = (racerData) => {















    if (useApi) {















      saveRacerApi(racerData);















    } else {















      saveRacerManual(racerData);















    }















  };































  const saveRacerApi = async (racerData) => {















    try {















      const method = editingRacer ? 'PUT' : 'POST';















      const url = editingRacer 















        ? `${API_BASE_URL}/racers/${editingRacer.id}`















        : `${API_BASE_URL}/racers`;















      















      const response = await fetch(url, {















        method,















        headers: { 'Content-Type': 'application/json' },















        body: JSON.stringify(racerData),















      });































      if (!response.ok) throw new Error('Failed to save racer');















      const savedRacer = await response.json();































      setRacers(prevRacers => 















        editingRacer















          ? prevRacers.map(r => r.id === editingRacer.id ? savedRacer : r)















          : [...prevRacers, savedRacer]















      );































      setShowForm(false);















      setEditingRacer(null);















    } catch (err) {















      console.error('Error saving racer:', err);















      saveRacerManual(racerData);















    }















  };
















  const saveRacerManual = (racerData) => {















    if (editingRacer) {















      setRacers(racers.map(r => r.id === editingRacer.id ? { ...r, ...racerData } : r));















    } else {















      setRacers([...racers, { ...racerData, id: Date.now() }]);















    }















    setShowForm(false);















    setEditingRacer(null);















  };































  const handleCancelForm = () => {















    setShowForm(false);















    setEditingRacer(null);















  };































  const categoryCounts = categories.reduce((acc, category) => {















    acc[category.name] = filteredRacers.filter(racer => racer.category === category.name).length;















    return acc;















  }, {});































  const handleQuickLookup = (racer) => {















    setQuickLookupRacer(racer);















  };































  const handleConfigSubmit = (e) => {















    e.preventDefault();















    setShowConfig(false);















    setUseApi(true);















  };































  const closeConfig = () => {















    setShowConfig(false);















  };































  const handleApiToggle = () => {















    if (useApi) {















      setUseApi(false);















    } else if (!apiConfig.system || !apiConfig.apiKey || !apiConfig.baseUrl) {















      setShowConfig(true);















    } else {















      setUseApi(true);















    }















  };
















  return (















    <PageContainer theme={theme}>















      <DatabaseContainer ref={printRef}>















        <Header>Racer Database</Header>















        <ButtonGroup>















          <Button onClick={handleApiToggle} theme={theme}>















            <FaSync /> {useApi ? 'Switch to Manual' : 'Switch to API'}















          </Button>















          <Button onClick={() => setShowConfig(true)} theme={theme}>















            <FaCog /> Configure API















          </Button>















        </ButtonGroup>















        <SearchBar>















          <CategorySelect 















            value={selectedCategory} 















            onChange={(e) => setSelectedCategory(e.target.value)}















          >















            <option value="">All Categories</option>















            {categories.map(category => (















              <option key={category.id} value={category.name}>















                {category.name} 















                <CategoryCounter>({categoryCounts[category.name] || 0})</CategoryCounter>















              </option>















            ))}















          </CategorySelect>















          <SearchInput 















            type="text" 















            placeholder="Search racers..." 















            value={searchTerm}















            onChange={(e) => setSearchTerm(e.target.value)}















          />















          <ActionButton onClick={handleSearch} theme={theme}>















            <FaSearch /> Search















          </ActionButton>















        </SearchBar>















        <ButtonGroup>















          <ActionButton onClick={handleQuickAdd} theme={theme}>















            <FaPlus /> Quick Add















          </ActionButton>















          <ActionButton onClick={() => setShowCategoryCopyModal(true)} theme={theme}>















            <FaCopy /> Copy Category















          </ActionButton>















          <ActionButton onClick={handleDeleteCategory} theme={theme}>















            <FaTrash /> Delete Category















          </ActionButton>















          <ActionButton onClick={() => setShowPrintPreviewModal(true)} theme={theme}>















            <FaPrint /> Print















          </ActionButton>















        </ButtonGroup>















        <ButtonGroup>















          <Button onClick={() => setShowQuickAddForm(!showQuickAddForm)} theme={theme}>















            Toggle Quick Add Form















          </Button>















          <Button onClick={() => setShowEntryLookup(!showEntryLookup)} theme={theme}>















            Toggle Entry Lookup















          </Button>















          <Button onClick={() => setShowIndexDropdown(!showIndexDropdown)} theme={theme}>















            Toggle Index Dropdown















          </Button>















          <Button onClick={() => setShowListBox(!showListBox)} theme={theme}>















            Toggle List Box















          </Button>















        </ButtonGroup>















        {showForm ? (















          <RacerForm















            racer={editingRacer}















            onSave={handleSaveRacer}















            onCancel={() => setShowForm(false)}















          />








        ) : (















          <>















            {showQuickAddForm && <QuickAddForm onSave={handleSaveRacer} />}















            {showEntryLookup && <EntryNumberLookup racers={racers} onLookup={handleQuickLookup} />}















            {showIndexDropdown && <IndexUpdateDropdown indexes={[]} onUpdate={() => {}} />}















            {showListBox && <ListBoxComponent entries={currentRacers} onEdit={handleEdit} />}















            <RacerList>















              {currentRacers.map(racer => (















                <RacerCard key={racer.id} theme={theme}>















                  <RacerInfo><strong>Name:</strong> {racer.name}</RacerInfo>















                  <RacerInfo><strong>Entry Number:</strong> {racer.entryNumber}</RacerInfo>















                  {racer.awardedNumber && <RacerInfo><strong>Awarded Number:</strong> {racer.awardedNumber}</RacerInfo>}















                  <RacerInfo><strong>Category:</strong> {racer.category}</RacerInfo>















                  <RacerInfo><strong>Status:</strong> {racer.registrationStatus}</RacerInfo>















                  <RacerInfo><strong>YTD Points:</strong> {racer.yearToDatePoints}</RacerInfo>















                  <ButtonGroup>















                    <ActionButton onClick={() => handleEdit(racer)} theme={theme}>















                      <FaEdit /> Edit















                    </ActionButton>















                    <ActionButton onClick={() => handleDelete(racer.id)} theme={theme}>















                      <FaTrash /> Delete















                    </ActionButton>















                  </ButtonGroup>















                </RacerCard>















              ))}















            </RacerList>















            <Pagination>















              {Array.from({ length: Math.ceil(filteredRacers.length / racersPerPage) }, (_, i) => (















                <PageButton key={i} onClick={() => paginate(i + 1)} theme={theme}>















                  {i + 1}















                </PageButton>















              ))}















            </Pagination>















          </>















        )}















      </DatabaseContainer>















      {showConfig && (















        <>















          <Overlay onClick={closeConfig} />















          <ConfigModal theme={theme}>















            <Header>API Configuration</Header>















            <ConfigForm onSubmit={handleConfigSubmit}>















              <Select 















                value={apiConfig.system} 















                onChange={(e) => setApiConfig({...apiConfig, system: e.target.value})}















                required















              >















                <option value="">Select Track System</option>















                {trackSystems.map(system => (















                  <option key={system.value} value={system.value}>{system.name}</option>















                ))}















              </Select>















              <Input 















                type="text" 















                placeholder="API Key" 















                value={apiConfig.apiKey}















                onChange={(e) => setApiConfig({...apiConfig, apiKey: e.target.value})}















                required















              />















              <Input 















                type="text" 















                placeholder="Base URL" 















                value={apiConfig.baseUrl}















                onChange={(e) => setApiConfig({...apiConfig, baseUrl: e.target.value})}















                required















              />















              <ButtonGroup>















                <Button type="submit" theme={theme}>Save Configuration</Button>















                <Button type="button" onClick={closeConfig} theme={theme}>Cancel</Button>















              </ButtonGroup>















            </ConfigForm>















          </ConfigModal>















        </>















      )}















      {showCategoryCopyModal && (















        <CategoryCopyModal















          categories={categories}















          onCopy={(source, target) => {















            // Implement copy logic here















          }}















          onClose={() => setShowCategoryCopyModal(false)}















        />















      )}















      {showPrintPreviewModal && (















        <PrintPreviewModal















          onPrint={handlePrint}















          onClose={() => setShowPrintPreviewModal(false)}















        />















      )}















    </PageContainer>















  );















};































export default RacerDatabase;

















