import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Button as SharedButton } from './SharedStyles';
import { FaTrophy, FaMedal, FaChartLine } from 'react-icons/fa';
import LiveTimingDisplay from './LiveTimingDisplay'; // New component
import { ref, update } from "firebase/database";
import { database } from '../firebase';

const BracketContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  overflow-x: auto;
  max-width: 100%;
`;

const Round = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 20px;
  min-width: 200px;
`;

const Match = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  overflow: hidden;
`;

const Racer = styled.div`
  padding: 10px;
  background-color: ${props => props.winner ? props.theme.accent : props.theme.background};
  color: ${props => props.winner ? props.theme.accentText : props.theme.text};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
`;

const RacerInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 2px;
  font-size: 0.8em;
  padding: 5px;
`;

const RacerInfoItem = styled.span`
  ${props => props.align === 'right' && 'text-align: right;'}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.gridArea && `grid-area: ${props.gridArea};`}
`;

const StyledButton = styled(SharedButton)`
  background-color: ${props => props.theme.mode === 'dark' ? props.theme.primary : props.theme.accent};
  color: ${props => props.theme.mode === 'dark' ? props.theme.text : props.theme.background};
  padding: 10px 20px;
  font-size: 16px;
  min-width: 200px;
  
  &:hover {
    background-color: ${props => props.theme.mode === 'dark' ? props.theme.primaryHover : props.theme.accentHover};
  }
`;

const WinnerDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.accentText};
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  border-radius: 8px;
`;

const StatsBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.border};
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
`;

const StatLine = styled.div`
  margin-bottom: 5px;
`;

const HeaderInfo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  background-color: ${props => props.theme.cardBackground};
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.border};
`;

const ChampionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.accentText};
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  border-radius: 8px;
`;

const BracketWrapper = styled.div`
  position: relative;
  padding: 20px;
  max-width: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const DoubleEliminationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BracketColumn = styled.div`
  width: 100%;
  max-width: 100%;
  transition: all 0.3s ease;
  display: ${props => props.isActive ? 'block' : 'none'};
`;

const BracketTitle = styled.h3`
  text-align: center;
  color: ${props => props.theme.text};
  margin-bottom: 20px;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.isActive ? props.theme.primary : props.theme.background};
  color: ${props => props.isActive ? props.theme.background : props.theme.text};
  border: 1px solid ${props => props.theme.border};
  cursor: pointer;
  transition: all 0.3s ease;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const sanitizeRacer = (racer) => {
  if (!racer) return null;
  if (racer.isBye) {
    return {
      id: 'bye',
      name: 'Bye',
      bestTime: 999999, // Use a large number instead of Infinity
      carNumber: '',
      isBye: true
    };
  }
  return {
    id: racer.id || '',
    name: racer.name || '',
    bestTime: isFinite(racer.bestTime) ? racer.bestTime : 999999, // Use a large number if bestTime is Infinity or not a number
    carNumber: racer.carNumber || '',
    // Add any other properties that should be included
  };
};

const BracketVisualization = ({ 
  eventId, 
  qualifyingResults, 
  onEventComplete, 
  eventDate, 
  carField, 
  roundNumber,
  topSpeeds,
  onWinnerSelection,
  initialBracket,
  isViewOnly = false,
  onResetBracket,
  onViewResults,
  isDoubleElimination,
  showDoubleElimination
}) => {
  const [bracket, setBracket] = useState(initialBracket || []);
  const { theme } = useTheme();
  const [byeRunRacers, setByeRunRacers] = useState(new Set());
  const [champion, setChampion] = useState(null);
  const [losersBracket, setLosersBracket] = useState([]);
  const [activeBracket, setActiveBracket] = useState('winners');
  const [topSpeedRacer, setTopSpeedRacer] = useState(null);
  const [topSpeed, setTopSpeed] = useState(null);

  // Calculate lowETRacer and topSpeedRacer
  const lowETRacer = qualifyingResults.reduce((lowest, current) => 
    (current.bestTime < lowest.bestTime) ? current : lowest
  );

  useEffect(() => {
    if (qualifyingResults.length > 0 && Object.keys(topSpeeds).length > 0) {
      const racer = qualifyingResults.reduce((fastest, current) => {
        return (topSpeeds[current.id] > topSpeeds[fastest.id]) ? current : fastest;
      });
      setTopSpeedRacer(racer);
      setTopSpeed(topSpeeds[racer.id]);
    }
  }, [qualifyingResults, topSpeeds]);

  useEffect(() => {
    if (initialBracket) {
      const sanitizedInitialBracket = initialBracket.map(round => 
        round.map(match => ({
          racer1: sanitizeRacer(match.racer1),
          racer2: sanitizeRacer(match.racer2),
          winner: sanitizeRacer(match.winner)
        }))
      );
      setBracket(sanitizedInitialBracket);
    } else {
      createInitialBracket(qualifyingResults);
    }
  }, [initialBracket, qualifyingResults]);

  useEffect(() => {
    // Save bracket to local storage whenever it changes
    if (bracket.length > 0) {
      try {
        localStorage.setItem(`bracket_${eventId}`, JSON.stringify(bracket));
      } catch (error) {
        console.error('Error saving bracket to local storage:', error);
      }
    }
  }, [bracket, eventId]);

  useEffect(() => {
    const lastRound = bracket[bracket.length - 1];
    if (lastRound && lastRound.length === 1 && lastRound[0].winner) {
      setChampion(lastRound[0].winner);
      if (onEventComplete && typeof onEventComplete === 'function') {
        onEventComplete(lastRound[0].winner);
      }
    }
  }, [bracket, onEventComplete]);

  useEffect(() => {
    // Update Firebase whenever the bracket changes
    const updateFirebase = async () => {
      if (eventId) {
        const bracketRef = ref(database, `events/${eventId}/eliminations`);
        const sanitizedBracket = bracket.map(round => 
          round.map(match => ({
            racer1: sanitizeRacer(match.racer1),
            racer2: sanitizeRacer(match.racer2),
            winner: sanitizeRacer(match.winner)
          }))
        );
        const sanitizedLosersBracket = losersBracket.map(round => 
          round.map(match => ({
            racer1: sanitizeRacer(match.racer1),
            racer2: sanitizeRacer(match.racer2),
            winner: sanitizeRacer(match.winner)
          }))
        );
        await update(bracketRef, {
          bracket: sanitizedBracket,
          losersBracket: sanitizedLosersBracket,
          champion: sanitizeRacer(champion),
          byeRunRacers: Array.from(byeRunRacers)
        });
      }
    };

    updateFirebase();
  }, [eventId, bracket, losersBracket, champion, byeRunRacers]);

  const createInitialBracket = (racers) => {
    const sanitizedRacers = racers.map(sanitizeRacer);
    const sortedRacers = [...sanitizedRacers].sort((a, b) => a.bestTime - b.bestTime);
    const initialRound = createFirstRound(sortedRacers);

    const newBracket = [initialRound];
    let currentRoundSize = initialRound.length;
    while (currentRoundSize > 1) {
      currentRoundSize = Math.ceil(currentRoundSize / 2);
      const newRound = Array(currentRoundSize).fill().map(() => ({ racer1: null, racer2: null, winner: null }));
      newBracket.push(newRound);
    }

    setBracket(newBracket);
    localStorage.setItem(`bracket_${eventId}`, JSON.stringify(newBracket));
  };

  const createFirstRound = (racers) => {
    const matches = [];
    const newByeRunRacers = new Set();

    if (racers.length % 2 !== 0) {
      const fastestRacer = racers.shift();
      matches.push({ racer1: fastestRacer, racer2: { name: 'Bye', bestTime: Infinity, isBye: true }, winner: fastestRacer });
      newByeRunRacers.add(fastestRacer.id);
    }

    while (racers.length > 1) {
      const racer1 = racers.shift();
      const racer2 = racers.pop();
      matches.push({ racer1, racer2, winner: null });
    }

    setByeRunRacers(newByeRunRacers);
    return matches;
  };

  const handleWinnerSelection = (roundIndex, matchIndex, winnerId) => {
    const updatedBracket = [...bracket];
    const winner = qualifyingResults.find(racer => racer.id === winnerId);
    
    if (winner) {
      updatedBracket[roundIndex][matchIndex].winner = winner;

      // If this is not the final round, update the next round
      if (roundIndex < updatedBracket.length - 1) {
        const nextRoundMatchIndex = Math.floor(matchIndex / 2);
        const isFirstWinnerOfPair = matchIndex % 2 === 0;

        if (isFirstWinnerOfPair) {
          updatedBracket[roundIndex + 1][nextRoundMatchIndex].racer1 = winner;
        } else {
          updatedBracket[roundIndex + 1][nextRoundMatchIndex].racer2 = winner;
        }
      }

      setBracket(updatedBracket);
      localStorage.setItem(`bracket_${eventId}`, JSON.stringify(updatedBracket));
    } else {
      console.error(`Winner with id ${winnerId} not found in qualifyingResults`);
    }
  };

  const isRoundComplete = (round) => {
    return round.every(match => match.winner !== null);
  };

  const setupNextRound = (newBracket, roundIndex) => {
    const currentRound = newBracket[roundIndex - 1];
    let winners = currentRound.map(match => sanitizeRacer(match.winner)).filter(winner => winner !== null);
    let nextRound = newBracket[roundIndex] || [];
    const newByeRunRacers = new Set(byeRunRacers);

    winners.sort((a, b) => a.bestTime - b.bestTime);

    nextRound = [];

    if (winners.length % 2 !== 0) {
      let byeRacer = null;
      
      for (let i = 0; i < winners.length; i++) {
        if (!newByeRunRacers.has(winners[i].id)) {
          byeRacer = winners[i];
          break;
        }
      }

      if (!byeRacer) {
        byeRacer = winners[0];
      }

      winners = winners.filter(racer => racer !== byeRacer);

      nextRound.push({ 
        racer1: sanitizeRacer(byeRacer), 
        racer2: sanitizeRacer({ name: 'Bye', bestTime: 999999, isBye: true }), 
        winner: sanitizeRacer(byeRacer) 
      });
      newByeRunRacers.add(byeRacer.id);
    }

    while (winners.length > 1) {
      const fastestRacer = winners.shift();
      const slowestRacer = winners.pop();
      nextRound.push({ 
        racer1: sanitizeRacer(fastestRacer), 
        racer2: sanitizeRacer(slowestRacer), 
        winner: null 
      });
    }

    newBracket[roundIndex] = nextRound;
    setByeRunRacers(newByeRunRacers);
  };

  const resetBracket = () => {
    if (window.confirm('Are you sure you want to reset the bracket? This will clear all race results.')) {
      createInitialBracket(qualifyingResults);
      localStorage.removeItem(`bracket_${eventId}`);
      localStorage.removeItem(`byeRunRacers_${eventId}`);
      localStorage.removeItem(`champion_${eventId}`);
      setByeRunRacers(new Set());
      setChampion(null);
      onResetBracket(); // Call the parent component's reset function
    }
  };

  const renderRacerInfo = (racer) => {
    console.log('Rendering racer info:', racer); // Keep this log for debugging
    if (!racer) return null;
    const qualifyingSpeed = topSpeeds[racer.id];
    return (
      <RacerInfo>
        <RacerInfoItem fontSize="10px" gridArea="1 / 1">
          {racer.carNumber || racer.number || 'N/A'}
        </RacerInfoItem>
        <RacerInfoItem fontSize="10px" gridArea="1 / 2" align="right">{racer.name}</RacerInfoItem>
        <RacerInfoItem fontSize="8px" gridArea="2 / 1 / 3 / 3">{racer.class || 'N/A'}</RacerInfoItem>
        <RacerInfoItem fontSize="9px" gridArea="3 / 1">{racer.bestTime ? racer.bestTime.toFixed(3) : 'N/A'}</RacerInfoItem>
        <RacerInfoItem fontSize="9px" gridArea="3 / 2" align="right">
          {qualifyingSpeed ? qualifyingSpeed.toFixed(2) : 'N/A'}
        </RacerInfoItem>
      </RacerInfo>
    );
  };

  const getRoundName = (round) => {
    const totalRounds = bracket.length;
    if (round === totalRounds) return "Finals";
    if (round === totalRounds - 1) return "Semi-Finals";
    if (round === totalRounds - 2) return "Quarter-Finals";
    return `Round ${round}`;
  };

  const addLoserToDoubleElimination = (loser, roundIndex) => {
    const newLosersBracket = [...losersBracket];
    if (!newLosersBracket[roundIndex]) {
      newLosersBracket[roundIndex] = [];
    }
    newLosersBracket[roundIndex].push({ racer1: loser, racer2: null, winner: null });
    setLosersBracket(newLosersBracket);
  };

  const renderBracket = (bracketData, isLosersBracket = false) => (
    <BracketContainer theme={theme}>
      {bracketData.map((round, roundIndex) => (
        <Round key={roundIndex}>
          {round.map((match, matchIndex) => (
            <Match key={matchIndex} theme={theme}>
              <Racer
                winner={match.winner === match.racer1}
                onClick={() => handleWinnerSelection(roundIndex, matchIndex, match.racer1.id)}
                theme={theme}
                style={isViewOnly ? { cursor: 'default' } : {}}
              >
                {renderRacerInfo(match.racer1)}
              </Racer>
              <Racer
                winner={match.winner === match.racer2}
                onClick={() => handleWinnerSelection(roundIndex, matchIndex, match.racer2.id)}
                theme={theme}
                style={isViewOnly ? { cursor: 'default' } : {}}
              >
                {renderRacerInfo(match.racer2)}
              </Racer>
            </Match>
          ))}
        </Round>
      ))}
      {!isLosersBracket && (
        <Round>
          <ChampionBox theme={theme}>
            {champion ? (
              <>
                <FaTrophy style={{ marginRight: '10px' }} />
                Champion: {champion.name}
              </>
            ) : (
              "Awaiting Champion"
            )}
          </ChampionBox>
        </Round>
      )}
    </BracketContainer>
  );

  return (
    <div>
      <StepHeader theme={theme}><FaChartLine /> Eliminations</StepHeader>
      <BracketWrapper>
        <HeaderInfo theme={theme}>
          Date: {eventDate} | {carField} | {getRoundName(roundNumber)}
        </HeaderInfo>
        <StatsBox theme={theme}>
          <StatLine>
            Low E.T.: {lowETRacer && lowETRacer.bestTime ? `${lowETRacer.bestTime.toFixed(3)} | ${lowETRacer.name}` : 'N/A'}
          </StatLine>
          <StatLine>
            Top Speed: {topSpeedRacer && topSpeed ? `${topSpeed.toFixed(2)} | ${topSpeedRacer.name}` : 'N/A'}
          </StatLine>
        </StatsBox>
        {isDoubleElimination && showDoubleElimination ? (
          <>
            <ToggleContainer>
              <ToggleButton 
                isActive={activeBracket === 'winners'} 
                onClick={() => setActiveBracket('winners')}
                theme={theme}
              >
                Winners Bracket
              </ToggleButton>
              <ToggleButton 
                isActive={activeBracket === 'losers'} 
                onClick={() => setActiveBracket('losers')}
                theme={theme}
              >
                Losers Bracket
              </ToggleButton>
            </ToggleContainer>
            <DoubleEliminationContainer>
              <BracketColumn isActive={activeBracket === 'winners'}>
                <BracketTitle theme={theme}>Winners Bracket</BracketTitle>
                {renderBracket(bracket)}
              </BracketColumn>
              <BracketColumn isActive={activeBracket === 'losers'}>
                <BracketTitle theme={theme}>Losers Bracket</BracketTitle>
                {renderBracket(losersBracket, true)}
              </BracketColumn>
            </DoubleEliminationContainer>
          </>
        ) : (
          renderBracket(bracket)
        )}
        <ButtonContainer>
          {!isViewOnly && (
            <StyledButton onClick={resetBracket} theme={theme}>
              Reset Bracket
            </StyledButton>
          )}
          <StyledButton onClick={onViewResults} theme={theme}>
            View Event Results
          </StyledButton>
        </ButtonContainer>
      </BracketWrapper>
    </div>
  );
};

export default BracketVisualization;
