import React, { createContext, useContext, useState, useEffect } from 'react';

const SponsorVendorContext = createContext();

export const useSponsorVendor = () => useContext(SponsorVendorContext);

const MAX_STORAGE_ITEMS = 50; // Adjust this number based on your needs

export const SponsorVendorProvider = ({ children }) => {
  const [sponsorsVendors, setSponsorsVendors] = useState(() => {
    const savedData = localStorage.getItem('sponsorsVendors');
    return savedData ? JSON.parse(savedData) : [];
  });

  const [pendingRegistrations, setPendingRegistrations] = useState(() => {
    const savedPending = localStorage.getItem('pendingRegistrations');
    return savedPending ? JSON.parse(savedPending) : [];
  });

  useEffect(() => {
    try {
      localStorage.setItem('sponsorsVendors', JSON.stringify(sponsorsVendors));
    } catch (error) {
      console.error('Failed to save sponsors/vendors to localStorage:', error);
      // Remove oldest items if storage limit is exceeded
      if (sponsorsVendors.length > MAX_STORAGE_ITEMS) {
        setSponsorsVendors(prev => prev.slice(-MAX_STORAGE_ITEMS));
      }
    }
  }, [sponsorsVendors]);

  useEffect(() => {
    try {
      localStorage.setItem('pendingRegistrations', JSON.stringify(pendingRegistrations));
    } catch (error) {
      console.error('Failed to save pending registrations to localStorage:', error);
      // Remove oldest items if storage limit is exceeded
      if (pendingRegistrations.length > MAX_STORAGE_ITEMS) {
        setPendingRegistrations(prev => prev.slice(-MAX_STORAGE_ITEMS));
      }
    }
  }, [pendingRegistrations]);

  const addSponsorVendor = (newSponsorVendor, eventId) => {
    const newEntry = { 
      ...newSponsorVendor, 
      id: Date.now(), 
      eventId,
      category: newSponsorVendor.category === 'custom' ? newSponsorVendor.customCategory : newSponsorVendor.category
    };
    setPendingRegistrations(prev => [...prev, newEntry]);
  };

  const updateSponsorVendor = (updatedSponsorVendor) => {
    const updatedEntry = {
      ...updatedSponsorVendor,
      category: updatedSponsorVendor.category === 'custom' ? updatedSponsorVendor.customCategory : updatedSponsorVendor.category
    };
    setSponsorsVendors(prev => prev.map(item => item.id === updatedEntry.id ? updatedEntry : item));
    setPendingRegistrations(prev => prev.map(item => item.id === updatedEntry.id ? updatedEntry : item));
  };

  const deleteSponsorVendor = (id) => {
    setSponsorsVendors(prev => prev.filter(item => item.id !== id));
    setPendingRegistrations(prev => prev.filter(item => item.id !== id));
  };

  const value = {
    sponsorsVendors,
    setSponsorsVendors,
    pendingRegistrations,
    setPendingRegistrations,
    addSponsorVendor,
    updateSponsorVendor,
    deleteSponsorVendor
  };

  return (
    <SponsorVendorContext.Provider value={value}>
      {children}
    </SponsorVendorContext.Provider>
  );
};