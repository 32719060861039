import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, TextArea } from './SharedStyles';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.text};

  svg {
    margin-right: 0.5rem;
  }
`;

const StyledInput = styled(Input)`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const StyledTextArea = styled(TextArea)`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  transition: border-color 0.2s;
  resize: vertical;
  min-height: 100px;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ActionButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 180px; // Set a fixed width

    &:hover {
      background-color: ${props => props.theme.buttonBackgroundHover};
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
`;

function EditSchedule({ item, onSave, onCancel }) {
  const [editedItem, setEditedItem] = useState(item);
  const { theme } = useTheme();

  useEffect(() => {
    setEditedItem(item);
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedItem);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Label>Race Name</Label>
        <StyledInput
          name="raceName"
          value={editedItem.raceName}
          onChange={handleChange}
          placeholder="Race Name"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>Round Type</Label>
        <StyledInput
          name="roundType"
          value={editedItem.roundType}
          onChange={handleChange}
          placeholder="Round Type"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>Start Time</Label>
        <StyledInput
          name="startTime"
          type="time"
          value={editedItem.startTime}
          onChange={handleChange}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>End Time</Label>
        <StyledInput
          name="endTime"
          type="time"
          value={editedItem.endTime}
          onChange={handleChange}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label>Notes</Label>
        <StyledTextArea
          name="notes"
          value={editedItem.notes}
          onChange={handleChange}
          placeholder="Notes"
        />
      </InputGroup>
      <ButtonContainer>
        <ActionButton type="submit" theme={theme}>
          <FaSave /> Save Changes
        </ActionButton>
        <ActionButton type="button" onClick={onCancel} theme={theme}>
          <FaTimes /> Cancel
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
}

export default EditSchedule;