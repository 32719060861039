import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, Select } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const Label = styled.label`
  font-weight: 600;
  color: ${props => props.theme.text};
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 1134px; // Adjust this value to match the width of your Select components
`;

const SetupButton = styled(Button)`
  background-color: ${props => props.theme.mode === 'dark' ? props.theme.primary : props.theme.accent};
  color: ${props => props.theme.mode === 'dark' ? props.theme.text : props.theme.background};
  &:hover {
    background-color: ${props => props.theme.mode === 'dark' ? props.theme.primaryHover : props.theme.accentHover};
  }
`;

const EventSetup = ({ onSetup, events, selectedEventId, onEventSelect }) => {
  const [numQualifyingRounds, setNumQualifyingRounds] = useState(2);
  const [raceType, setRaceType] = useState('bracket');
  const { theme } = useTheme();

  useEffect(() => {
    if (selectedEventId) {
      const selectedEvent = events.find(event => event.id === parseInt(selectedEventId));
      if (selectedEvent) {
        if (selectedEvent.numQualifyingRounds) {
          setNumQualifyingRounds(selectedEvent.numQualifyingRounds);
        }
        // Store the selected event name in local storage
        localStorage.setItem('selectedEventName', selectedEvent.name);
      }
    }
  }, [selectedEventId, events]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSetup(parseInt(numQualifyingRounds, 10), raceType);
  };

  const handleEventSelection = (e) => {
    const eventId = e.target.value;
    onEventSelect(eventId);
    if (eventId) {
      const selectedEvent = events.find(event => event.id === parseInt(eventId));
      if (selectedEvent) {
        localStorage.setItem('selectedEventName', selectedEvent.name);
      }
    } else {
      localStorage.removeItem('selectedEventName');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 10)) {
      setNumQualifyingRounds(value);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Label htmlFor="eventSelect" theme={theme}>Select an event:</Label>
        <Select
          id="eventSelect"
          value={selectedEventId}
          onChange={handleEventSelection}
          theme={theme}
        >
          <option value="">Select an event</option>
          {events.map((event) => (
            <option key={event.id} value={event.id}>
              {event.name} - {event.type}
            </option>
          ))}
        </Select>
      </InputGroup>
      
      <InputGroup>
        <Label htmlFor="qualifyingRounds" theme={theme}>Number of Qualifying Rounds:</Label>
        <StyledInput
          id="qualifyingRounds"
          type="number"
          min="1"
          max="10"
          value={numQualifyingRounds}
          onChange={handleInputChange}
          placeholder="Enter number of rounds"
        />
      </InputGroup>
      
      <InputGroup>
        <Label htmlFor="raceType" theme={theme}>Race Type:</Label>
        <Select
          id="raceType"
          value={raceType}
          onChange={(e) => setRaceType(e.target.value)}
          theme={theme}
        >
          <option value="bracket">Bracket Race</option>
          <option value="grudge">Grudge Racing</option>
          <option value="testTune">Test & Tune</option>
          <option value="officialDrag">Official Drag Racing</option>
          <option value="rollRacing">Roll Racing</option>
        </Select>
      </InputGroup>
      
      <SetupButton type="submit" theme={theme}>Set Up Event</SetupButton>
    </Form>
  );
};

export default EventSetup;
