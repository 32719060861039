import React, { useState } from 'react';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import LiveTimingDisplay from './LiveTimingDisplay';
import { FaClock } from 'react-icons/fa';

const NoPrepRace = ({ eventId }) => {
  const [showLiveTiming, setShowLiveTiming] = useState(false);
  const { theme } = useTheme();

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>No Prep Race</Header>
        <Button onClick={() => setShowLiveTiming(!showLiveTiming)} theme={theme}>
          <FaClock /> {showLiveTiming ? 'Hide Live Timing' : 'Show Live Timing'}
        </Button>
        
        {showLiveTiming && <LiveTimingDisplay eventId={eventId} />}

        {/* Add other No Prep race specific components and logic here */}
      </Container>
    </PageContainer>
  );
};

export default NoPrepRace;
