import React, { useState } from 'react';
import { getAuth, getFirestore, getGoogleAuthProvider } from '../../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc'; // Make sure to install react-icons
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const LoginForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #1a73e8;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1557b0;
  }
`;

const ErrorMessage = styled.p`
  color: #d93025;
  margin-bottom: 1rem;
`;

const SignUpLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #1a73e8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const GoogleButton = styled(Button)`
  background-color: white;
  color: #757575;
  border: 1px solid #dadce0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  &:hover {
    background-color: #f8f9fa;
  }
`;

const GoogleIcon = styled(FcGoogle)`
  margin-right: 10px;
  font-size: 18px;
`;

const ForgotPasswordLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  color: #1a73e8;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #757575;
`;

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const checkUserAccess = async (user) => {
    if (user.uid === 'FFV82GUN8WS2T3f393xPoSbtfD13') {
      return true; // Always allow access for this specific user
    }

    const db = await getFirestore();
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.role === 'admin' || userData.role === 'freeTrial' || (userData.role === 'paid' && new Date(userData.subscriptionEnd) > new Date());
    }
    return false;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const auth = await getAuth();
      const result = await signInWithEmailAndPassword(auth, email, password);
      const hasAccess = await checkUserAccess(result.user);
      if (hasAccess) {
        navigate('/');
      } else {
        setError('Your account does not have access. Please check your subscription.');
      }
    } catch (error) {
      setError(getErrorMessage(error));
      console.error('Login error:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const auth = await getAuth();
      const googleProvider = await getGoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const hasAccess = await checkUserAccess(result.user);
      if (hasAccess) {
        navigate('/');
      } else {
        setError('Your account does not have access. Please check your subscription.');
      }
    } catch (error) {
      setError(getErrorMessage(error));
      console.error('Google Sign-In error:', error);
    }
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/invalid-credential':
        return 'Invalid email or password. Please try again.';
      case 'auth/user-not-found':
        return 'No user found with this email. Please check your email or sign up.';
      case 'auth/popup-closed-by-user':
        return 'Google Sign-In was cancelled. Please try again.';
      default:
        return 'An error occurred. Please try again later.';
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <Title>Log In</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <PasswordInputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <PasswordToggle type="button" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </PasswordToggle>
        </PasswordInputWrapper>
        <Button type="submit">Log In</Button>
        <ForgotPasswordLink to="/forgot-password">Forgot Password?</ForgotPasswordLink>
        <GoogleButton type="button" onClick={handleGoogleSignIn}>
          <GoogleIcon />
          Sign in with Google
        </GoogleButton>
        <SignUpLink to="/signup">Don't have an account? Sign up</SignUpLink>
      </LoginForm>
    </LoginContainer>
  );
}

export default Login;