import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Button } from './SharedStyles'; // Import the shared Button component

const Container = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
`;

// Add a styled component for the button container
const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px; // Add space between buttons
    margin-bottom: 20px; // Add space below the button group
`;

const Title = styled.h1`
    font-size: 2rem;
    color: ${props => props.theme.text};
    text-align: center;
    margin-bottom: 20px;
`;

const Description = styled.p`
    font-size: 1rem;
    color: ${props => props.theme.textSecondary};
    text-align: center;
    margin-bottom: 20px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid ${props => props.theme.border};
    border-radius: 5px;
    background-color: ${props => props.theme.inputBackground};
    color: ${props => props.theme.text};
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
`;

const ListItem = styled.li`
    background-color: ${props => props.theme.cardBackground};
    color: ${props => props.theme.text};
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const QualifyingProgram = () => {
    const { theme } = useTheme();
    const [qualifyingList, setQualifyingList] = useState([]);
    const [selectedMode, setSelectedMode] = useState('Fastest ET');

    const qualifyingModes = [
        'Fastest ET',
        'Best Reaction Time',
        'Closest to Dial-In',
        'Farthest Under Index',
        'Best Package',
        'Elimination Run by Fastest ET',
        'Elimination Run by Best Reaction Time',
        'Elimination Run Closest to Dial-In',
        'Elimination Run Farthest under Index'
    ];

    const handleQualify = () => {
        console.log(`Qualifying by ${selectedMode}`);
    };

    const handleCategorySplit = () => {
        console.log('Splitting categories');
    };

    const handleByeRunCheck = () => {
        console.log('Checking BYE run status');
    };

    const handleManualEdit = () => {
        console.log('Manual editing');
    };

    return (
        <Container>
            <Title>Qualifying Program</Title>
            <Description>
                The Qualifying Program uses the settings from the Category Database and the Data from the Results Database to generate a Qualifying list for a specific Category.
            </Description>
            <Select value={selectedMode} onChange={(e) => setSelectedMode(e.target.value)}>
                {qualifyingModes.map(mode => (
                    <option key={mode} value={mode}>{mode}</option>
                ))}
            </Select>
            <ButtonContainer>
                <Button onClick={handleQualify} theme={theme}>Start Qualifying</Button>
                <Button onClick={handleCategorySplit} theme={theme}>Split Categories</Button>
                <Button onClick={handleByeRunCheck} theme={theme}>Check BYE Run Status</Button>
                <Button onClick={handleManualEdit} theme={theme}>Manual Edit</Button>
            </ButtonContainer>
            <List>
                {qualifyingList.map((entry, index) => (
                    <ListItem key={index}>{entry.name} - {entry.result}</ListItem>
                ))}
            </List>
        </Container>
    );
};

export default QualifyingProgram;
