import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaTrash, FaTrophy, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ResetButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const ClassSection = styled.section`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const ClassHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  background-color: ${props => props.theme.primary};
  color: white; // Changed to white
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.primaryHover};
  }
`;

const ClassName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: white; // Added to ensure the class name is also white
`;

const DateHeader = styled.h4`
  font-size: 1.1rem;
  margin: 1rem 0;
  color: ${props => props.theme.textSecondary};
`;

const RoundSection = styled.div`
  margin-bottom: 1.5rem;
`;

const RoundHeader = styled.h5`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.primary};
`;

const RaceResult = styled.p`
  margin: 0.25rem 0;
  padding-left: 1rem;
`;

const EventDate = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
`;

function RaceResults({ eventId, readOnly }) {
  const { theme } = useTheme();
  const { eventId: eventIdParam } = useParams();
  const [eventHistory, setEventHistory] = useState([]);
  const [expandedClasses, setExpandedClasses] = useState({});
  const [eventDate, setEventDate] = useState('');

  useEffect(() => {
    // Load event history from localStorage
    const storedHistory = JSON.parse(localStorage.getItem(`eventHistory_${eventIdParam}`) || '[]');
    setEventHistory(storedHistory);

    // Load event details to get the date
    const events = JSON.parse(localStorage.getItem('events') || '[]');
    const currentEvent = events.find(event => event.id.toString() === eventIdParam);
    if (currentEvent) {
      setEventDate(currentEvent.date);
    }
  }, [eventIdParam]);

  const resetEventHistory = () => {
    if (window.confirm('Are you sure you want to reset all event history?')) {
      setEventHistory([]);
      localStorage.removeItem(`eventHistory_${eventIdParam}`);
    }
  };

  const toggleClass = (className) => {
    setExpandedClasses(prev => ({
      ...prev,
      [className]: !prev[className]
    }));
  };

  const groupEventHistoryByClass = () => {
    const groupedHistory = {};
    eventHistory.forEach(round => {
      round.pairings.forEach(pairing => {
        const className = pairing.racer1.class || 'Unknown';
        if (!groupedHistory[className]) {
          groupedHistory[className] = [];
        }
        groupedHistory[className].push(round);
      });
    });
    return groupedHistory;
  };

  const groupedHistory = groupEventHistoryByClass();

  const formatRaceResult = (pairing, raceNumber) => {
    if (pairing.racer2.name === 'Bye') {
      return `Race ${raceNumber}: ${pairing.racer1.name} - Bye Run (Advances)`;
    }
    const winner = pairing.winner ? pairing.winner.name : 'No winner recorded';
    return `Race ${raceNumber}: ${winner} wins`;
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Race Results and Event History</Header>
        {eventDate && <EventDate theme={theme}>Event Date: {eventDate}</EventDate>}
        <ResetButton onClick={resetEventHistory} theme={theme}>
          <FaTrash /> Reset Event History
        </ResetButton>
        {Object.keys(groupedHistory).length === 0 ? (
          <p>No race history available.</p>
        ) : (
          Object.entries(groupedHistory).map(([className, rounds]) => (
            <ClassSection key={className} theme={theme}>
              <ClassHeader onClick={() => toggleClass(className)} theme={theme}>
                <ClassName>{className}</ClassName>
                {expandedClasses[className] ? <FaChevronUp /> : <FaChevronDown />}
              </ClassHeader>
              {expandedClasses[className] && (
                rounds.map((round, index) => (
                  <RoundSection key={index}>
                    <RoundHeader theme={theme}>
                      <FaTrophy /> Round {round.round}
                    </RoundHeader>
                    {round.pairings.map((pairing, raceIndex) => (
                      <RaceResult key={raceIndex}>
                        {formatRaceResult(pairing, raceIndex + 1)}
                      </RaceResult>
                    ))}
                  </RoundSection>
                ))
              )}
            </ClassSection>
          ))
        )}
        {!readOnly && (
          <ButtonGroup>
            {/* Add any admin-only buttons here */}
          </ButtonGroup>
        )}
      </Container>
    </PageContainer>
  );
}

export default RaceResults;