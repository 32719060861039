import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PageContainer, Container, Header, Button, Select, List } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaUserPlus, FaFlag, FaTrophy, FaCar, FaChartLine, FaClock, FaShare, FaCopy } from 'react-icons/fa';
import EventSetup from './EventSetup';
import RacerRegistration from './RacerRegistration';
import QualifyingRounds from './QualifyingRounds';
import BracketVisualization from './BracketVisualization';
import RaceSimulation from './RaceSimulation';
import EventProgress from './EventProgress';
import { useRegularDragRacingEvent } from '../hooks/useRegularDragRacingEvent';
import LiveTimingDisplay from './LiveTimingDisplay';
import ChipDrawing from './ChipDrawing';
import NoPrepRace from './NoPrepRace';
import { ref, set, onValue, push, update, remove } from "firebase/database";
import { database } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const StepContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  border: 1px solid ${props => props.theme.border};
`;

const StepHeader = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventSelect = styled(Select)`
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Add this styled component definition
const EventName = styled.h2`
  color: ${props => props.theme.secondary};
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
`;

const ShareLinkSection = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ShareLinkLabel = styled.span`
  font-weight: bold;
  margin-right: 1rem;
  color: ${props => props.theme.text};
`;

const ShareLinkInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 0.9rem;
  color: ${props => props.theme.text};
  background-color: ${props => props.theme.background};
`;

const CopyButton = styled(StyledButton)`
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
`;

const ClassEliminationContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
`;

const BracketBuilder = ({ eventId }) => {
  const location = useLocation();
  const initialStep = location.state?.initialStep || 'setup';
  const [step, setStep] = useState(initialStep);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [events, setEvents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [registeredRacers, setRegisteredRacers] = useState([]);
  const [qualifyingResults, setQualifyingResults] = useState([]);
  const [reactionTimes, setReactionTimes] = useState({});
  const [topSpeeds, setTopSpeeds] = useState({});
  const [eventResults, setEventResults] = useState({});
  const [numQualifyingRounds, setNumQualifyingRounds] = useState(2);
  const [selectedEventName, setSelectedEventName] = useState('');
  const [showLiveTiming, setShowLiveTiming] = useState(false);
  const [raceType, setRaceType] = useState('bracket');
  const [eventDate, setEventDate] = useState('');
  const [carField, setCarField] = useState('');
  const [roundNumber, setRoundNumber] = useState(1);
  const [lowET, setLowET] = useState(null);
  const [topSpeed, setTopSpeed] = useState(null);
  const [winner, setWinner] = useState(null);
  const [bracket, setBracket] = useState([]);
  const [byeRunRacers, setByeRunRacers] = useState(new Set());
  const [shareLink, setShareLink] = useState('');
  const [lowETRacer, setLowETRacer] = useState(null);
  const [topSpeedRacer, setTopSpeedRacer] = useState(null);
  const [sharedBracketRef, setSharedBracketRef] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [isDoubleElimination, setIsDoubleElimination] = useState(false);
  const [doubleEliminationBracket, setDoubleEliminationBracket] = useState([]);
  const [showDoubleElimination, setShowDoubleElimination] = useState(false);

  const {
    event,
    createEvent,
    addRacer,
    recordQualifyingTime,
    finalizeQualifying,
    simulateRace,
    updateBracket,
  } = useRegularDragRacingEvent();

  useEffect(() => {
    const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
    // Filter out "No Prep" events
    const filteredEvents = storedEvents.filter(event => event.type !== "No Prep");
    setEvents(filteredEvents);
    const storedEventName = localStorage.getItem('selectedEventName');
    if (storedEventName) {
      setSelectedEventName(storedEventName);
    }
  }, []);

  useEffect(() => {
    if (selectedEventId) {
      const storedTeams = JSON.parse(localStorage.getItem(`teams_${selectedEventId}`) || '[]');
      setTeams(storedTeams);
      const storedRacers = JSON.parse(localStorage.getItem(`racers_${selectedEventId}`) || '[]');
      setRegisteredRacers(storedRacers);
      loadQualifyingData();
    }
  }, [selectedEventId]);

  useEffect(() => {
    console.log('BracketBuilder - Current event:', event);
  }, [event]);

  useEffect(() => {
    if (selectedEventId) {
      const selectedEvent = events.find(event => event.id === parseInt(selectedEventId));
      if (selectedEvent) {
        createEvent(selectedEvent.numQualifyingRounds || 2);
        if (step === 'registration') {
          // If coming from Class Management, we want to stay on the registration step
          setStep('registration');
        }
      }
    }
  }, [selectedEventId, events, step]);

  useEffect(() => {
    // ... other loading logic ...
    const storedTopSpeeds = JSON.parse(localStorage.getItem(`topSpeeds_${selectedEventId}`) || '{}');
    setTopSpeeds(storedTopSpeeds);
  }, [selectedEventId]);

  useEffect(() => {
    if (selectedEventId) {
      const selectedEvent = events.find(event => event.id === parseInt(selectedEventId));
      if (selectedEvent) {
        setEventDate(selectedEvent.date || new Date().toLocaleDateString());
        // Fetch the car field (class) from the registered racers
        const racers = JSON.parse(localStorage.getItem(`racers_${selectedEventId}`) || '[]');
        if (racers.length > 0) {
          setCarField(racers[0].class || '');
        }
        setRoundNumber(1); // Reset round number when a new event is selected
      }
    }
  }, [selectedEventId, events]);

  useEffect(() => {
    if (selectedEventId) {
      const sharedDataRef = ref(database, `sharedBracket/${selectedEventId}`);
      const unsubscribe = onValue(sharedDataRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const latestShareId = Object.keys(data).pop();
          setShareId(latestShareId);
        }
      });

      return () => unsubscribe();
    }
  }, [selectedEventId]);

  useEffect(() => {
    if (selectedEventId && shareId) {
      const bracketRef = ref(database, `sharedBracket/${selectedEventId}/${shareId}`);
      setSharedBracketRef(bracketRef);
    }
  }, [selectedEventId, shareId]);

  useEffect(() => {
    if (selectedEventId && shareId) {
      updateSharedData();
    }
  }, [qualifyingResults, reactionTimes, topSpeeds]);

  useEffect(() => {
    if (selectedEventId && shareId) {
      const sharedDataRef = ref(database, `sharedBracket/${selectedEventId}/${shareId}`);
      update(sharedDataRef, {
        qualifying: {
          qualifyingResults,
          reactionTimes,
          topSpeeds
        }
      });
    }
  }, [selectedEventId, shareId, qualifyingResults, reactionTimes, topSpeeds]);

  const handleEventSelection = (eventId) => {
    setSelectedEventId(eventId);
    if (eventId) {
      const selectedEvent = events.find(event => event.id === parseInt(eventId));
      if (selectedEvent) {
        setSelectedEventName(selectedEvent.name);
      }
    } else {
      setSelectedEventName('');
    }
    const selectedEvent = events.find(event => event.id === parseInt(eventId));
    if (selectedEvent) {
      setNumQualifyingRounds(selectedEvent.numQualifyingRounds || 2);
    }
  };

  const handleEventSetup = (rounds, type) => {
    setNumQualifyingRounds(rounds);
    setRaceType(type);
    createEvent(rounds);
    setStep('registration');
  };

  const handleRegistrationComplete = () => {
    const storedRacers = JSON.parse(localStorage.getItem(`racers_${selectedEventId}`) || '[]');
    setRegisteredRacers(storedRacers);
    setStep('qualifying');
  };

  const updateStats = (racer, time, speed) => {
    if (time && (!lowET || time < lowET)) {
      setLowET(time);
      setLowETRacer(racer);
    }
    if (speed && (!topSpeed || speed > topSpeed)) {
      setTopSpeed(speed);
      setTopSpeedRacer(racer);
    }
  };

  const updateFirebase = (updates) => {
    const cleanedUpdates = Object.entries(updates).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(cleanedUpdates).length > 0) {
      const dbRef = ref(database);
      update(dbRef, cleanedUpdates)
        .then(() => console.log("Firebase update successful"))
        .catch((error) => console.error("Error updating Firebase:", error));
    } else {
      console.log("No valid updates to send to Firebase");
    }
  };

  const handleQualifyingComplete = (results) => {
    setQualifyingResults(results);
    setStep('bracket');
    updateFirebase({ qualifyingResults: results });
  };

  const handleRaceComplete = (winner) => {
    updateBracket(winner);
    if (event.isEventComplete()) {
      setStep('progress');
    }
  };

  const importTimesFromSystem = () => {
    // Simulating import from timing system
    const importedTimes = qualifyingResults.map(racer => ({
      ...racer,
      qualifyingTimes: Array(event.numQualifyingRounds).fill(null).map(() => (Math.random() * 10 + 5).toFixed(3)),
      reactionTime: (Math.random() * 0.5).toFixed(3),
      topSpeed: (Math.random() * 50 + 150).toFixed(2),
    }));

    const newReactionTimes = {};
    const newTopSpeeds = {};
    importedTimes.forEach(racer => {
      newReactionTimes[racer.id] = racer.reactionTime;
      newTopSpeeds[racer.id] = racer.topSpeed;
    });

    setQualifyingResults(importedTimes);
    setReactionTimes(newReactionTimes);
    setTopSpeeds(newTopSpeeds);
    saveData(importedTimes, newReactionTimes, newTopSpeeds);
  };

  const saveData = (results, reactions, speeds) => {
    const dataToSave = {
      qualifyingResults: results,
      reactionTimes: reactions,
      topSpeeds: speeds,
    };
    localStorage.setItem(`qualifyingData_${selectedEventId}`, JSON.stringify(dataToSave));
  };

  const handleViewResults = () => {
    const eventIdToUse = selectedEventId || 'undefined';
    navigate(`/events/${eventIdToUse}/results`);
  };

  const handleEventComplete = (champion) => {
    console.log("Event completed! Champion:", champion);
    
    // Ensure the bracket is valid before updating Firebase
    const validBracket = bracket.map(round => 
      round.map(match => ({
        racer1: match.racer1 ? { id: match.racer1.id, name: match.racer1.name } : null,
        racer2: match.racer2 ? { id: match.racer2.id, name: match.racer2.name } : null,
        winner: match.winner ? { id: match.winner.id, name: match.winner.name } : null
      }))
    );

    // Update the event in Firebase
    updateFirebase({
      [`events/${selectedEventId}/bracket`]: validBracket,
      [`events/${selectedEventId}/champion`]: champion ? { id: champion.id, name: champion.name } : null
    });
  };

  const getFinalRunnerUp = (finalBracket) => {
    const finalRound = finalBracket[finalBracket.length - 1][0];
    return finalRound.winner === finalRound.racer1 ? finalRound.racer2 : finalRound.racer1;
  };

  const getSemiFinalRunnerUps = (finalBracket) => {
    const semiFinalRound = finalBracket[finalBracket.length - 2];
    return semiFinalRound
      .filter(match => match.winner !== null)
      .map(match => match.winner === match.racer1 ? match.racer2 : match.racer1);
  };

  const loadQualifyingData = () => {
    const storedData = JSON.parse(localStorage.getItem(`qualifyingData_${selectedEventId}`) || '{}');
    if (storedData.qualifyingResults) {
      setQualifyingResults(storedData.qualifyingResults);
    }
    setReactionTimes(storedData.reactionTimes || {});
    setTopSpeeds(storedData.topSpeeds || {});
  };

  const resetBracket = () => {
    if (window.confirm('Are you sure you want to reset the bracket? This will clear all race results.')) {
      localStorage.removeItem(`bracket_${selectedEventId}`);
      localStorage.removeItem(`byeRunRacers_${selectedEventId}`);
      localStorage.removeItem(`winner_${selectedEventId}`);
      loadQualifyingData(); // Reload qualifying data to recreate the bracket
    }
  };

  const loadBracketData = () => {
    const storedBracket = JSON.parse(localStorage.getItem(`bracket_${selectedEventId}`) || '[]');
    const storedByeRunRacers = JSON.parse(localStorage.getItem(`byeRunRacers_${selectedEventId}`) || '[]');
    const storedWinner = JSON.parse(localStorage.getItem(`winner_${selectedEventId}`) || 'null');

    if (storedBracket.length > 0) {
      setBracket(storedBracket);
      setByeRunRacers(new Set(storedByeRunRacers));
      setWinner(storedWinner);
    } else if (qualifyingResults && qualifyingResults.length > 0) {
      createInitialBracket(qualifyingResults);
    }

    // Load additional event information
    const eventInfo = JSON.parse(localStorage.getItem(`eventInfo_${selectedEventId}`) || '{}');
    setEventDate(eventInfo.eventDate || '');
    setCarField(eventInfo.carField || '');
    setRoundNumber(eventInfo.roundNumber || 1);
    setLowET(eventInfo.lowET || null);
    setTopSpeed(eventInfo.topSpeed || null);
  };

  const saveBracketData = (newBracket, newByeRunRacers, newWinner) => {
    localStorage.setItem(`bracket_${selectedEventId}`, JSON.stringify(newBracket));
    localStorage.setItem(`byeRunRacers_${selectedEventId}`, JSON.stringify([...newByeRunRacers]));
    localStorage.setItem(`winner_${selectedEventId}`, JSON.stringify(newWinner));

    // Save additional event information
    const eventInfo = {
      eventDate,
      carField,
      roundNumber,
      lowET,
      topSpeed
    };
    localStorage.setItem(`eventInfo_${selectedEventId}`, JSON.stringify(eventInfo));
  };

  const updateSharedBracket = useCallback((data) => {
    if (selectedEventId && shareId) {
      const bracketRef = ref(database, `sharedBracket/${selectedEventId}/${shareId}`);
      update(bracketRef, {
        eliminations: {
          ...data,
          bracket: data.bracket,
        },
      });
    }
  }, [selectedEventId, shareId]);

  const onWinnerSelection = (roundIndex, matchIndex, winnerIndex, updatedBracket) => {
    // Implement any additional logic needed when a winner is selected
    console.log(`Winner selected: Round ${roundIndex}, Match ${matchIndex}, Winner Index ${winnerIndex}`);
    // You can also update state or perform other actions here
  };

  const handleWinnerSelection = (roundIndex, matchIndex, winnerIndex, isDoubleBracket) => {
    const updatedBracket = isDoubleBracket ? [...doubleEliminationBracket] : [...bracket];
    const match = updatedBracket[roundIndex][matchIndex];
    if (!match) {
      console.error(`Invalid matchIndex: ${matchIndex}`);
      return;
    }

    const winner = winnerIndex === 1 ? match.racer1 : match.racer2;

    // Update the winner of the current match
    match.winner = winner;

    // Ensure bestTime is a valid number or null
    if (winner) {
      winner.bestTime = typeof winner.bestTime === 'number' && isFinite(winner.bestTime) 
        ? winner.bestTime 
        : null;
    }

    // Setup the next round if all matches in the current round have winners
    if (updatedBracket[roundIndex].every(match => match.winner)) {
      setupNextRound(updatedBracket, roundIndex);
    }

    if (isDoubleBracket) {
      setDoubleEliminationBracket(updatedBracket);
    } else {
      setBracket(updatedBracket);
      if (isDoubleElimination) {
        const loser = winnerIndex === 1 ? match.racer2 : match.racer1;
        addLoserToDoubleElimination(loser, roundIndex);
      }
    }

    // If you have a defined onWinnerSelection, call it here
    // onWinnerSelection(roundIndex, matchIndex, winnerIndex, updatedBracket);
  };

  const handleResetBracket = useCallback(() => {
    // Reset the bracket in Firebase
    if (selectedEventId && shareId) {
      const bracketRef = ref(database, `sharedBracket/${selectedEventId}/${shareId}/eliminations`);
      set(bracketRef, {
        bracket: [],
        roundNumber: 1,
        // Reset other relevant data
      });
    }
  }, [selectedEventId, shareId]);

  const createInitialBracket = (qualifyingResults) => {
    const sortedRacers = qualifyingResults.sort((a, b) => a.bestTime - b.bestTime);
    const initialBracket = sortedRacers.map(racer => ({
      ...racer,
      carNumber: racer.carNumber, // Ensure carNumber is included
      // ... other properties
    }));
    // ... rest of the bracket creation logic
  };

  const updateSharedData = async () => {
    if (!selectedEventId || !shareId) return;

    const sharedDataRef = ref(database, `sharedBracket/${selectedEventId}/${shareId}`);

    // Prepare the data to be updated
    const updatedData = {
      eventName: selectedEventName,
      qualifying: {
        qualifyingResults,
        reactionTimes,
        topSpeeds,
        numQualifyingRounds
      },
      eliminations: {
        bracket,
        eventDate,
        carField,
        roundNumber
      },
      results: {
        classes: {}
      }
    };

    // Ensure classes are properly structured
    qualifyingResults.forEach(racer => {
      if (racer.class) {
        if (!updatedData.results.classes[racer.class]) {
          updatedData.results.classes[racer.class] = {
            className: racer.class,
            racers: []
          };
        }
        updatedData.results.classes[racer.class].racers.push({
          name: racer.name,
          bestTime: racer.bestTime || null,
          reactionTime: racer.reactionTime || null,
          topSpeed: topSpeeds[racer.id] || null
        });
      }
    });

    try {
      await set(sharedDataRef, updatedData);
      console.log('Shared data updated successfully');
    } catch (error) {
      console.error('Error updating shared data:', error);
    }
  };

  const generateShareLink = () => {
    console.log('Generating share link for eventId:', selectedEventId);
    const newShareId = push(ref(database, `sharedBracket/${selectedEventId}`)).key;
    setShareId(newShareId);
    
    const sharedData = {
      eventName: selectedEventName,
      qualifying: {
        qualifyingResults,
        reactionTimes,
        topSpeeds,
        numQualifyingRounds,
      },
      eliminations: {
        bracket,
        eventDate,
        carField,
        roundNumber,
        lowET,
        topSpeed,
        lowETRacer,
        topSpeedRacer,
      },
      results: eventResults,
    };

    console.log('Shared data to be saved:', sharedData);

    const sharedDataRef = ref(database, `sharedBracket/${selectedEventId}/${newShareId}`);
    set(sharedDataRef, sharedData)
      .then(() => {
        console.log('Shared data saved successfully');
        const shareLink = `${window.location.origin}/shared-bracket/${selectedEventId}/${newShareId}`;
        setShareLink(shareLink);
        console.log('Generated share link:', shareLink);
      })
      .catch((error) => {
        console.error("Error updating shared data:", error);
      });
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      alert('Share link copied to clipboard!');
    });
  };

  const setupNextRound = (newBracket, roundIndex) => {
    const currentRound = newBracket[roundIndex];
    const nextRound = [];

    for (let i = 0; i < currentRound.length; i += 2) {
      const winner1 = currentRound[i].winner;
      const winner2 = currentRound[i + 1]?.winner;

      nextRound.push({
        racer1: winner1,
        racer2: winner2,
        winner: null
      });

      // Ensure bestTime is a valid number or null for both racers
      if (winner1) {
        winner1.bestTime = typeof winner1.bestTime === 'number' && isFinite(winner1.bestTime) 
          ? winner1.bestTime 
          : null;
      }
      if (winner2) {
        winner2.bestTime = typeof winner2.bestTime === 'number' && isFinite(winner2.bestTime) 
          ? winner2.bestTime 
          : null;
      }
    }

    newBracket.push(nextRound);
  };

  const addLoserToDoubleElimination = (loser, roundIndex) => {
    const newDoubleEliminationBracket = [...doubleEliminationBracket];
    if (!newDoubleEliminationBracket[roundIndex]) {
      newDoubleEliminationBracket[roundIndex] = [];
    }
    newDoubleEliminationBracket[roundIndex].push(loser);
    setDoubleEliminationBracket(newDoubleEliminationBracket);
  };

  const toggleDoubleElimination = () => {
    setIsDoubleElimination(!isDoubleElimination);
  };

  const toggleBracketView = () => {
    setShowDoubleElimination(!showDoubleElimination);
  };

  console.log('Qualifying results being passed to BracketVisualization:', qualifyingResults);

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Bracket Builder</Header>
        {selectedEventName && <EventName theme={theme}>{selectedEventName}</EventName>}
        <ButtonGroup>
          <StyledButton onClick={() => setStep('setup')} theme={theme}>
            <FaFlag /> Event Setup
          </StyledButton>
          <StyledButton onClick={() => setStep('registration')} theme={theme}>
            <FaUserPlus /> Registration
          </StyledButton>
          <StyledButton onClick={() => setStep('qualifying')} theme={theme}>
            <FaCar /> Qualifying
          </StyledButton>
          <StyledButton onClick={() => setStep('bracket')} theme={theme}>
            <FaChartLine /> Eliminations
          </StyledButton>
          <StyledButton onClick={handleViewResults} theme={theme}>
            <FaTrophy /> Event Results
          </StyledButton>
          <StyledButton onClick={() => setShowLiveTiming(!showLiveTiming)} theme={theme}>
            <FaClock /> {showLiveTiming ? 'Hide Live Timing' : 'Show Live Timing'}
          </StyledButton>
          <StyledButton onClick={generateShareLink} theme={theme}>
            <FaShare /> Generate Share Link
          </StyledButton>
          <StyledButton onClick={toggleDoubleElimination} theme={theme}>
            {isDoubleElimination ? 'Disable' : 'Enable'} Double Elimination
          </StyledButton>
          {isDoubleElimination && (
            <StyledButton onClick={toggleBracketView} theme={theme}>
              Show {showDoubleElimination ? 'Main' : 'Double Elimination'} Bracket
            </StyledButton>
          )}
        </ButtonGroup>
        {shareLink && (
          <ShareLinkSection theme={theme}>
            <ShareLinkLabel theme={theme}>Share Link:</ShareLinkLabel>
            <ShareLinkInput type="text" value={shareLink} readOnly theme={theme} />
            <CopyButton onClick={copyShareLink} theme={theme}>
              <FaCopy /> Copy
            </CopyButton>
          </ShareLinkSection>
        )}

        {showLiveTiming && <LiveTimingDisplay eventId={selectedEventId} />}

        <StepContainer theme={theme}>
          {step === 'setup' && (
            <>
              <StepHeader theme={theme}><FaFlag /> Event Setup</StepHeader>
              <EventSetup 
                onSetup={handleEventSetup} 
                events={events}
                selectedEventId={selectedEventId}
                onEventSelect={handleEventSelection}
              />
            </>
          )}
          {step === 'registration' && (
            <>
              <StepHeader theme={theme}><FaUserPlus /> Racer Registration</StepHeader>
              <RacerRegistration 
                onComplete={handleRegistrationComplete} 
                addRacer={addRacer} 
                teams={teams}
                eventId={selectedEventId}
              />
              <StyledButton onClick={handleRegistrationComplete} theme={theme}>
                <FaCar /> Go to Qualifying Rounds
              </StyledButton>
            </>
          )}
          {step === 'qualifying' && (
            <QualifyingRounds
              eventId={selectedEventId}
              registeredRacers={registeredRacers}
              qualifyingResults={qualifyingResults}
              setQualifyingResults={setQualifyingResults}
              reactionTimes={reactionTimes}
              setReactionTimes={setReactionTimes}
              topSpeeds={topSpeeds}
              setTopSpeeds={setTopSpeeds}
              onComplete={handleQualifyingComplete}
              numQualifyingRounds={numQualifyingRounds} // Make sure this prop is correctly set
              importTimesFromSystem={importTimesFromSystem}
              onQualifyingUpdate={setQualifyingResults}
              onReactionTimesUpdate={setReactionTimes}
              onTopSpeedsUpdate={setTopSpeeds}
            />
          )}
          {step === 'bracket' && event && (
            <>
              <StepHeader theme={theme}><FaChartLine /> Eliminations</StepHeader>
              <BracketVisualization 
                eventId={selectedEventId}
                qualifyingResults={qualifyingResults.map(racer => ({
                  ...racer,
                  carNumber: racer.carNumber || racer.number, // Ensure carNumber is included
                }))}
                onEventComplete={handleEventComplete}
                eventDate={eventDate}
                carField={carField}
                roundNumber={roundNumber}
                topSpeeds={topSpeeds}  // Make sure this is being passed
                isViewOnly={false}
                onWinnerSelection={handleWinnerSelection}
                onResetBracket={handleResetBracket}
                onViewResults={handleViewResults}
                isDoubleElimination={isDoubleElimination}
                doubleEliminationBracket={doubleEliminationBracket}
                showDoubleElimination={showDoubleElimination}
              />
            </>
          )}
          {step === 'simulation' && (
            <>
              <StepHeader theme={theme}><FaTrophy /> Race Simulation</StepHeader>
              <RaceSimulation 
                onRaceComplete={handleRaceComplete} 
                currentMatchups={event.getCurrentRoundMatchups()} 
              />
            </>
          )}
          {step === 'progress' && (
            <>
              <StepHeader theme={theme}><FaFlag /> Event Progress</StepHeader>
              <EventProgress event={event} />
            </>
          )}
        </StepContainer>
      </Container>
    </PageContainer>
  );
};

export default BracketBuilder;
