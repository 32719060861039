import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CarSelector from './CarSelector';
import { useTheme } from '../contexts/ThemeContext';

const stripePromise = loadStripe('your_stripe_publishable_key');

const FormContainer = styled.form`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const FormElement = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 16px;
  background-color: ${props => props.theme.inputBackground};
  color: black; // Changed to black
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 16px;
  background-color: ${props => props.theme.inputBackground};
  color: black; // Changed to black
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${props => props.theme.buttonBackground};
  color: white; // This ensures the text is always white
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.buttonBackgroundHover};
  }

  &:disabled {
    background-color: ${props => props.theme.disabled};
    color: rgba(255, 255, 255, 0.7); // Slightly transparent white for disabled state
    cursor: not-allowed;
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  color: ${props => props.theme.text};
  margin-bottom: 15px;
`;

function RacerRegistrationForm() {
  const { eventId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    team: '',
    raceNumber: '', // Add this line
    carInfo: '',
    inductionType: '',
    class: '',
    eventId: eventId || '',
    waiverSigned: false,
    customCar: false,
    ticketId: '',
  });
  const [events, setEvents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [racerTickets, setRacerTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const { theme } = useTheme();

  useEffect(() => {
    const fetchEvents = () => {
      const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
      console.log('Fetched events:', storedEvents);
      // Convert event IDs to strings
      const eventsWithStringIds = storedEvents.map(event => ({
        ...event,
        id: event.id.toString()
      }));
      setEvents(eventsWithStringIds);
    };

    const fetchClasses = () => {
      const storedClasses = JSON.parse(localStorage.getItem('classes') || '[]');
      setClasses(storedClasses);
    };

    fetchEvents();
    fetchClasses();
  }, []);

  useEffect(() => {
    const fetchTickets = () => {
      console.log('Current formData.eventId:', formData.eventId);
      if (formData.eventId) {
        const selectedEvent = events.find(event => event.id === formData.eventId);
        console.log('Selected event:', selectedEvent);
        if (selectedEvent && selectedEvent.tickets) {
          const eventRacerTickets = selectedEvent.tickets.filter(ticket => ticket.type === 'Racer');
          console.log('Racer tickets for selected event:', eventRacerTickets);
          setRacerTickets(eventRacerTickets);
        } else {
          console.log('No racer tickets found for the selected event');
          setRacerTickets([]);
        }
      } else {
        console.log('No event selected');
        setRacerTickets([]);
      }
    };

    fetchTickets();
  }, [formData.eventId, events]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    console.log('Form data updated:', { ...formData, [name]: value });

    if (name === 'eventId') {
      setSelectedTicket(null);
      setFormData(prevData => ({ ...prevData, ticketId: '' }));
    }

    if (name === 'ticketId') {
      const ticket = racerTickets.find(t => t.id === value);
      setSelectedTicket(ticket);
    }
  };

  const handleCarSelect = (selectedCar) => {
    setFormData(prevData => ({
      ...prevData,
      carInfo: selectedCar,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // Send formData and paymentMethod.id to your server
      // Your server should create a charge using the payment method ID
      // and save the registration data
    }
  };

  const handleWaiverSign = () => {
    setFormData({ ...formData, waiverSigned: true });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Title theme={theme}>Racer Registration</Title>
      <FormElement>
        <StyledSelect 
          name="eventId" 
          value={formData.eventId} 
          onChange={handleChange} 
          required
          theme={theme}
        >
          <option value="">Select Event</option>
          {events.map((event) => (
            <option key={event.id} value={event.id}>{event.name}</option>
          ))}
        </StyledSelect>
      </FormElement>
      {formData.eventId && (
        <>
          {racerTickets.length > 0 ? (
            <FormElement>
              <StyledSelect
                name="ticketId"
                value={formData.ticketId}
                onChange={handleChange}
                required
                theme={theme}
              >
                <option value="">Select Ticket Type</option>
                {racerTickets.map((ticket) => (
                  <option key={ticket.id} value={ticket.id}>
                    {ticket.name} - ${ticket.price}
                  </option>
                ))}
              </StyledSelect>
            </FormElement>
          ) : (
            <SubTitle theme={theme}>No racer tickets available for this event</SubTitle>
          )}
        </>
      )}
      <FormElement>
        <StyledInput name="name" placeholder="Name" value={formData.name} onChange={handleChange} required theme={theme} />
      </FormElement>
      <FormElement>
        <StyledInput name="team" placeholder="Team" value={formData.team} onChange={handleChange} theme={theme} />
      </FormElement>
      <FormElement>
        <StyledInput 
          name="raceNumber" 
          placeholder="Race Number" 
          value={formData.raceNumber} 
          onChange={handleChange} 
          required 
          theme={theme} 
        />
      </FormElement>
      
      <CarSelector onSelect={handleCarSelect} theme={theme} />
      
      <FormElement>
        <StyledSelect name="inductionType" value={formData.inductionType} onChange={handleChange} required theme={theme}>
          <option value="">Select Induction Type</option>
          <option value="All Motor">All Motor</option>
          <option value="Turbo">Turbo</option>
          <option value="Nitrous">Nitrous</option>
        </StyledSelect>
      </FormElement>
      
      <FormElement>
        <StyledSelect name="class" value={formData.class} onChange={handleChange} required theme={theme}>
          <option value="">Select Class</option>
          {classes.map((cls) => (
            <option key={cls} value={cls}>{cls}</option>
          ))}
        </StyledSelect>
      </FormElement>
      
      <StyledButton type="button" onClick={handleWaiverSign} theme={theme}>
        {formData.waiverSigned ? 'Waiver Signed' : 'Sign Digital Waiver'}
      </StyledButton>
      {selectedTicket && (
        <SubTitle theme={theme}>
          Selected Ticket: {selectedTicket.name} - ${selectedTicket.price}
        </SubTitle>
      )}
      <FormElement>
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: theme.text,
              '::placeholder': {
                color: theme.textSecondary,
              },
            },
          },
        }} />
      </FormElement>
      <StyledButton type="submit" disabled={!formData.waiverSigned || !selectedTicket} theme={theme}>
        Register and Pay
      </StyledButton>
    </FormContainer>
  );
}

function WrappedRacerRegistrationForm() {
  return (
    <Elements stripe={stripePromise}>
      <RacerRegistrationForm />
    </Elements>
  );
}

export default WrappedRacerRegistrationForm;
