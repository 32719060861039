import React, { useState } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../leaflet-icon-fix';  // Add this import
import TrackSelector from './TrackSelector';
import WeatherDisplay from './WeatherDisplay';
import TrackConditionsForm from './TrackConditionsForm';
import { useTheme } from '../contexts/ThemeContext';
import { trackDatabase } from './TrackSelector';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: ${props => props.theme.mode === 'dark' ? '#121212' : '#f5f7fa'};
  min-height: 100vh;
  color: ${props => props.theme.mode === 'dark' ? '#ffffff' : '#2c3e50'};
`;

const PageTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  color: ${props => props.theme.mode === 'dark' ? '#ffffff' : '#2c3e50'};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
`;

const Card = styled.section`
  background-color: ${props => props.theme.mode === 'dark' ? '#1e1e1e' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.mode === 'dark' ? '#ffffff' : '#34495e'};
  margin-bottom: 16px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 8px;
`;

const MapCard = styled(Card)`
  grid-column: 1 / -1;
  height: 600px;
  padding: 0;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMapContainer = styled(MapContainer)`
  height: calc(100% - 60px);
  width: 100%;
`;

const MapTitle = styled(CardTitle)`
  margin: 16px 24px;
`;

const WeatherTrackConditions = () => {
  const [selectedTrack, setSelectedTrack] = useState(null);
  const { theme } = useTheme();

  const handleTrackSelect = (track) => {
    console.log('Selected track:', track);
    setSelectedTrack(track);
  };

  return (
    <PageContainer theme={theme}>
      <PageTitle theme={theme}>Weather and Track Conditions</PageTitle>
      
      <Grid>
        <Card theme={theme}>
          <CardTitle theme={theme}>Track Selection</CardTitle>
          <TrackSelector onSelect={handleTrackSelect} />
          {selectedTrack && (
            <p style={{ marginTop: '16px', color: theme.mode === 'dark' ? '#b3b3b3' : '#7f8c8d' }}>
              Selected Track: {selectedTrack.name}, {selectedTrack.location}
            </p>
          )}
        </Card>

        <Card theme={theme}>
          <CardTitle theme={theme}>Weather Information</CardTitle>
          {selectedTrack ? (
            <WeatherDisplay lat={selectedTrack.lat} lon={selectedTrack.lon} />
          ) : (
            <p style={{ color: theme.mode === 'dark' ? '#b3b3b3' : '#7f8c8d' }}>
              Please select a track to view weather information.
            </p>
          )}
        </Card>

        <Card theme={theme}>
          <CardTitle theme={theme}>Track Conditions</CardTitle>
          <TrackConditionsForm 
            initialConditions={{}}
            onSubmit={(conditions) => console.log('Submitted conditions:', conditions)}
            isOrganizer={true}
          />
        </Card>
      </Grid>

      <MapCard theme={theme}>
        <MapTitle theme={theme}>Track Map</MapTitle>
        <MapWrapper>
          <StyledMapContainer center={[39.8283, -98.5795]} zoom={4}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {trackDatabase.map((track) => (
              <Marker key={track.id} position={[track.lat, track.lon]}>
                <Popup>
                  <strong>{track.name}</strong><br />
                  {track.location}<br />
                  {track.type}
                </Popup>
              </Marker>
            ))}
          </StyledMapContainer>
        </MapWrapper>
      </MapCard>
    </PageContainer>
  );
};

export default WeatherTrackConditions;