import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ChipDrawing from './ChipDrawing';
import RaceResults from './RaceResults';
import { useAuth } from '../contexts/AuthContext';

function SharedView() {
  const { eventId, encodedState } = useParams();
  const [decodedState, setDecodedState] = useState(null);
  const { isPaidUser } = useAuth();

  useEffect(() => {
    try {
      const stateString = atob(encodedState);
      const state = JSON.parse(stateString);
      setDecodedState(state);
    } catch (error) {
      console.error('Error decoding state:', error);
    }
  }, [encodedState]);

  if (!decodedState) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ChipDrawing eventId={eventId} initialState={decodedState} readOnly={!isPaidUser} />
      <RaceResults eventId={eventId} readOnly={!isPaidUser} />
    </div>
  );
}

export default SharedView;