import { useState, useCallback } from 'react'

export function useRegularDragRacingEvent(initialEvent) {
  const [event, setEvent] = useState(initialEvent)

  const createEvent = (numQualifyingRounds) => {
    setEvent({
      id: Date.now(),
      numQualifyingRounds,
      racers: [],
      currentBracket: [],
      // ... other event properties
    })
  }

  const addRacer = useCallback((newRacer) => {
    setEvent((prevEvent) => {
      if (!prevEvent) {
        // If prevEvent is null, initialize it with an empty racers array
        return { racers: [newRacer] };
      }
      return {
        ...prevEvent,
        racers: [...(prevEvent.racers || []), newRacer],
      };
    });
  }, [])

  const recordQualifyingTime = (racerId, time, roundIndex) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      racers: prevEvent.racers.map(racer => 
        racer.id === racerId 
          ? {
              ...racer,
              qualifyingTimes: racer.qualifyingTimes.map((t, i) => i === roundIndex ? time : t),
              bestTime: Math.min(...racer.qualifyingTimes.filter(t => t !== null), time),
            }
          : racer
      ),
    }))
  }

  const finalizeQualifying = () => {
    setEvent(prevEvent => ({
      ...prevEvent,
      qualifyingComplete: true,
      // You might want to sort racers by their best qualifying time here
      racers: prevEvent.racers.sort((a, b) => a.bestTime - b.bestTime)
    }))
  }

  // ... other event-related functions

  return {
    event,
    createEvent,
    addRacer,
    recordQualifyingTime,
    finalizeQualifying,
    // ... other returned functions
  }
}
