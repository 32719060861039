import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Input, Select, Button } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';
import CarSelector from './CarSelector';
import { countries, states, cities } from '../data/locationData'; // You'll need to create this file

const FormContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FormSection = styled.div`
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h3`
  margin-bottom: 1.5rem;
  color: ${props => props.theme.text};
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.primary};
  padding-bottom: 0.5rem;
`;

const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.text};
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.mode === 'dark' ? '#000' : props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.primary}33;
  }

  &::placeholder {
    color: ${props => props.theme.mode === 'dark' ? '#666' : props.theme.text};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.mode === 'dark' ? '#000' : props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    box-shadow: 0 0 0 2px ${props => props.theme.primary}33;
  }

  option {
    background-color: ${props => props.theme.mode === 'dark' ? '#fff' : props.theme.inputBackground};
    color: #000;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
  background-color: ${props => props.theme.mode === 'light' ? props.theme.primary : props.theme.accent};
  color: ${props => props.theme.buttonText};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.mode === 'light' 
      ? props.theme.primaryHover 
      : props.theme.accentHover};
  }
`;

const ErrorMessage = styled.span`
  color: ${props => props.theme.error};
  font-size: 0.8rem;
  margin-top: 0.25rem;
`;

const ToggleButton = styled(Button)`
  margin-bottom: 1rem;
`;

const RacerForm = ({ racer, onSave, onCancel, categories, existingRacers, awardedNumbers }) => {
  const { theme } = useTheme();
  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues: racer || {}
  });
  const [useCarSelector, setUseCarSelector] = useState(true);
  const [useLocationSelector, setUseLocationSelector] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');

  const selectedCategory = watch('category');
  const entryNumber = watch('entryNumber');
  const watchCountry = watch('country');
  const watchState = watch('state');

  useEffect(() => {
    // Check if the entry number is awarded for the selected category
    if (selectedCategory && entryNumber) {
      const isAwarded = checkIfAwardedNumber(selectedCategory, entryNumber);
      setValue('isAwardedNumber', isAwarded);
    }
  }, [selectedCategory, entryNumber]);

  useEffect(() => {
    if (watchCountry !== selectedCountry) {
      setSelectedCountry(watchCountry);
      setValue('state', '');
      setValue('city', '');
    }
  }, [watchCountry, selectedCountry, setValue]);

  useEffect(() => {
    if (watchState !== selectedState) {
      setSelectedState(watchState);
      setValue('city', '');
    }
  }, [watchState, selectedState, setValue]);

  const checkIfAwardedNumber = (category, number) => {
    return awardedNumbers[category]?.includes(number) || false;
  };

  const validateUniqueEntryNumber = (value) => {
    if (!selectedCategory) return true;
    const racersInCategory = existingRacers.filter(r => r.category === selectedCategory);
    return !racersInCategory.some(r => r.entryNumber === value) || "Entry number must be unique within the category";
  };

  const onSubmit = (data) => {
    // Additional validation can be added here
    onSave(data);
  };

  return (
    <FormContainer theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Personal Information</SectionTitle>
          <ToggleButton onClick={() => setUseLocationSelector(!useLocationSelector)} theme={theme}>
            {useLocationSelector ? 'Switch to Manual Entry' : 'Use Location Selector'}
          </ToggleButton>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>First Name</FieldLabel>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'First name is required' }}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
              {errors.firstName && <ErrorMessage theme={theme}>{errors.firstName.message}</ErrorMessage>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Last Name</FieldLabel>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: 'Last name is required' }}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
              {errors.lastName && <ErrorMessage theme={theme}>{errors.lastName.message}</ErrorMessage>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Email</FieldLabel>
              <Controller
                name="email"
                control={control}
                rules={{ pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' } }}
                render={({ field }) => <StyledInput type="email" {...field} theme={theme} />}
              />
              {errors.email && <ErrorMessage theme={theme}>{errors.email.message}</ErrorMessage>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Phone Number</FieldLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Address</FieldLabel>
              <Controller
                name="address"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Country</FieldLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  useLocationSelector ? (
                    <StyledSelect {...field} onChange={(e) => {
                      field.onChange(e);
                      setSelectedCountry(e.target.value);
                    }} theme={theme}>
                      <option value="">Select Country</option>
                      {countries.map(country => (
                        <option key={country} value={country}>{country}</option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledInput {...field} theme={theme} />
                  )
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>State</FieldLabel>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  useLocationSelector ? (
                    <StyledSelect {...field} onChange={(e) => {
                      field.onChange(e);
                      setSelectedState(e.target.value);
                    }} theme={theme} disabled={!selectedCountry}>
                      <option value="">Select State</option>
                      {states[selectedCountry]?.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledInput {...field} theme={theme} />
                  )
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>City</FieldLabel>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  useLocationSelector ? (
                    <StyledSelect {...field} theme={theme} disabled={!selectedState}>
                      <option value="">Select City</option>
                      {cities[selectedState]?.map(city => (
                        <option key={city} value={city}>{city}</option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledInput {...field} theme={theme} />
                  )
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Zip Code</FieldLabel>
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Racing Information</SectionTitle>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Entry Number</FieldLabel>
              <Controller
                name="entryNumber"
                control={control}
                rules={{ 
                  required: 'Entry number is required',
                  validate: validateUniqueEntryNumber
                }}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
              {errors.entryNumber && <ErrorMessage theme={theme}>{errors.entryNumber.message}</ErrorMessage>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Is Awarded Number</FieldLabel>
              <Controller
                name="isAwardedNumber"
                control={control}
                render={({ field }) => (
                  <StyledSelect {...field} theme={theme}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </StyledSelect>
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Awarded Number</FieldLabel>
              <Controller
                name="awardedNumber"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Category</FieldLabel>
              <Controller
                name="category"
                control={control}
                rules={{ required: 'Category is required' }}
                render={({ field }) => (
                  <StyledSelect {...field} theme={theme}>
                    <option value="">Select Category</option>
                    {/* Add category options here */}
                  </StyledSelect>
                )}
              />
              {errors.category && <ErrorMessage theme={theme}>{errors.category.message}</ErrorMessage>}
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Class</FieldLabel>
              <Controller
                name="class"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Registration Status</FieldLabel>
              <Controller
                name="registrationStatus"
                control={control}
                render={({ field }) => (
                  <StyledSelect {...field} theme={theme}>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </StyledSelect>
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Year-to-Date Points</FieldLabel>
              <Controller
                name="yearToDatePoints"
                control={control}
                render={({ field }) => <StyledInput type="number" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>License Number</FieldLabel>
              <Controller
                name="licenseNumber"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>License Type</FieldLabel>
              <Controller
                name="licenseType"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>License Expiration</FieldLabel>
              <Controller
                name="licenseExpiration"
                control={control}
                render={({ field }) => <StyledInput type="date" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Team Name</FieldLabel>
              <Controller
                name="teamName"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Crew Chief</FieldLabel>
              <Controller
                name="crewChief"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Vehicle Information</SectionTitle>
          <ToggleButton onClick={() => setUseCarSelector(!useCarSelector)} theme={theme}>
            {useCarSelector ? 'Switch to Manual Entry' : 'Use Car Selector'}
          </ToggleButton>
          {useCarSelector ? (
            <CarSelector
              onSelect={(carInfo) => {
                const [year, make, model] = carInfo.split(' ');
                setValue('carYear', year);
                setValue('carMake', make);
                setValue('carModel', model);
              }}
              initialYear={racer?.carYear}
              initialMake={racer?.carMake}
              initialModel={racer?.carModel}
            />
          ) : (
            <FieldGroup>
              <FieldWrapper>
                <FieldLabel theme={theme}>Car Year</FieldLabel>
                <Controller
                  name="carYear"
                  control={control}
                  render={({ field }) => <StyledInput type="number" {...field} theme={theme} />}
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel theme={theme}>Car Make</FieldLabel>
                <Controller
                  name="carMake"
                  control={control}
                  render={({ field }) => <StyledInput {...field} theme={theme} />}
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel theme={theme}>Car Model</FieldLabel>
                <Controller
                  name="carModel"
                  control={control}
                  render={({ field }) => <StyledInput {...field} theme={theme} />}
                />
              </FieldWrapper>
            </FieldGroup>
          )}
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Engine Make</FieldLabel>
              <Controller
                name="engineMake"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Engine Size</FieldLabel>
              <Controller
                name="engineSize"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Induction</FieldLabel>
              <Controller
                name="induction"
                control={control}
                render={({ field }) => (
                  <StyledSelect {...field} theme={theme}>
                    <option value="">Select Induction</option>
                    <option value="Naturally Aspirated">Naturally Aspirated</option>
                    <option value="Turbo">Turbo</option>
                    <option value="Supercharged">Supercharged</option>
                    <option value="Nitrous">Nitrous</option>
                  </StyledSelect>
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Transmission</FieldLabel>
              <Controller
                name="transmission"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Fuel Type</FieldLabel>
              <Controller
                name="fuelType"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Performance</SectionTitle>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Best ET (1/4 Mile)</FieldLabel>
              <Controller
                name="bestETQuarterMile"
                control={control}
                render={({ field }) => <StyledInput type="number" step="0.001" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Best Speed (1/4 Mile)</FieldLabel>
              <Controller
                name="bestSpeedQuarterMile"
                control={control}
                render={({ field }) => <StyledInput type="number" step="0.01" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Best ET (1/8 Mile)</FieldLabel>
              <Controller
                name="bestETEighthMile"
                control={control}
                render={({ field }) => <StyledInput type="number" step="0.001" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Best Speed (1/8 Mile)</FieldLabel>
              <Controller
                name="bestSpeedEighthMile"
                control={control}
                render={({ field }) => <StyledInput type="number" step="0.01" {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Sponsors</SectionTitle>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Primary Sponsor</FieldLabel>
              <Controller
                name="primarySponsor"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Additional Sponsors</FieldLabel>
              <Controller
                name="additionalSponsors"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Accomplishments</SectionTitle>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Recent Wins</FieldLabel>
              <Controller
                name="recentWins"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Championships</FieldLabel>
              <Controller
                name="championships"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <FormSection theme={theme}>
          <SectionTitle theme={theme}>Additional Information</SectionTitle>
          <FieldGroup>
            <FieldWrapper>
              <FieldLabel theme={theme}>Hometown</FieldLabel>
              <Controller
                name="hometown"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Years Racing</FieldLabel>
              <Controller
                name="yearsRacing"
                control={control}
                render={({ field }) => <StyledInput type="number" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Favorite Track</FieldLabel>
              <Controller
                name="favoriteTrack"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Racing Idol</FieldLabel>
              <Controller
                name="racingIdol"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Notes</FieldLabel>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => <StyledInput as="textarea" rows="4" {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Social Media Handles</FieldLabel>
              <Controller
                name="socialMediaHandles"
                control={control}
                render={({ field }) => <StyledInput {...field} theme={theme} />}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Merchandise Available</FieldLabel>
              <Controller
                name="merchandiseAvailable"
                control={control}
                render={({ field }) => (
                  <StyledSelect {...field} theme={theme}>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </StyledSelect>
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel theme={theme}>Charity Work</FieldLabel>
              <Controller
                name="charityWork"
                control={control}
                render={({ field }) => <StyledInput as="textarea" rows="3" {...field} theme={theme} />}
              />
            </FieldWrapper>
          </FieldGroup>
        </FormSection>

        <ButtonGroup>
          <StyledButton type="button" onClick={onCancel} theme={theme}>
            Cancel
          </StyledButton>
          <StyledButton type="submit" theme={theme}>
            Save Racer
          </StyledButton>
        </ButtonGroup>
      </form>
    </FormContainer>
  );
};

export default RacerForm;
