import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { FaTrophy, FaFlag, FaUserPlus, FaCar, FaChartLine } from 'react-icons/fa';

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ClassResults = styled.div`
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  padding: 1rem;
`;

const ClassTitle = styled.h3`
  color: ${props => props.theme.primary};
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const WinnerItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const TrophyIcon = styled(FaTrophy)`
  margin-right: 0.5rem;
  color: ${props => {
    switch (props.place) {
      case 1: return 'gold';
      case 2: return 'silver';
      case 3: return '#cd7f32';
      default: return props.theme.text;
    }
  }};
`;

const WinnerInfo = styled.span`
  margin-left: 0.5rem;
`;

const EventName = styled.h2`
  color: ${props => props.theme.secondary};
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventResults = () => {
  const { eventId } = useParams();
  const { theme } = useTheme();
  const [results, setResults] = useState(null);
  const [eventName, setEventName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedResults = JSON.parse(localStorage.getItem(`eventResults_${eventId}`) || 'null');
    console.log('Stored Results:', storedResults);
    
    if (storedResults) {
      if (storedResults.undefined) {
        // Handle the case where results are stored under an 'undefined' key
        const className = Object.values(storedResults.undefined)[0]?.class || 'Default Class';
        setResults({
          classes: {
            [className]: storedResults.undefined
          }
        });
      } else {
        setResults(storedResults);
      }
    }

    const storedEventName = localStorage.getItem('selectedEventName');
    if (storedEventName) {
      setEventName(storedEventName);
    } else if (eventId === 'undefined') {
      setEventName('No Event Selected');
    }
  }, [eventId]);

  const handleNavigation = (step) => {
    navigate(`/bracket-builder`, { state: { initialStep: step } });
  };

  if (!results) {
    return (
      <PageContainer theme={theme}>
        <Navigation />
        <Container>
          <Header>Event Results</Header>
          <ButtonGroup>
            <StyledButton onClick={() => handleNavigation('setup')} theme={theme}>
              <FaFlag /> Event Setup
            </StyledButton>
            <StyledButton onClick={() => handleNavigation('registration')} theme={theme}>
              <FaUserPlus /> Registration
            </StyledButton>
            <StyledButton onClick={() => handleNavigation('qualifying')} theme={theme}>
              <FaCar /> Qualifying
            </StyledButton>
            <StyledButton onClick={() => handleNavigation('bracket')} theme={theme}>
              <FaChartLine /> Eliminations
            </StyledButton>
            <StyledButton disabled theme={theme}>
              <FaTrophy /> Event Results
            </StyledButton>
          </ButtonGroup>
          <p>No results available for this event.</p>
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Event Results</Header>
        {eventName && <EventName theme={theme}>{eventName}</EventName>}
        <ButtonGroup>
          <StyledButton onClick={() => handleNavigation('setup')} theme={theme}>
            <FaFlag /> Event Setup
          </StyledButton>
          <StyledButton onClick={() => handleNavigation('registration')} theme={theme}>
            <FaUserPlus /> Registration
          </StyledButton>
          <StyledButton onClick={() => handleNavigation('qualifying')} theme={theme}>
            <FaCar /> Qualifying
          </StyledButton>
          <StyledButton onClick={() => handleNavigation('bracket')} theme={theme}>
            <FaChartLine /> Eliminations
          </StyledButton>
          <StyledButton disabled theme={theme}>
            <FaTrophy /> Event Results
          </StyledButton>
        </ButtonGroup>
        {results.eventName && <EventName theme={theme}>{results.eventName}</EventName>}
        <ResultsContainer theme={theme}>
          {results.classes && Object.entries(results.classes).map(([className, classResults]) => (
            <ClassResults key={className} theme={theme}>
              <ClassTitle theme={theme}>
                {classResults.first?.class || className}
              </ClassTitle>
              {classResults.first && (
                <WinnerItem>
                  <TrophyIcon place={1} theme={theme} />
                  <WinnerInfo>
                    1st Place: {classResults.first.name}
                  </WinnerInfo>
                </WinnerItem>
              )}
              {classResults.second && (
                <WinnerItem>
                  <TrophyIcon place={2} theme={theme} />
                  <WinnerInfo>
                    2nd Place: {classResults.second.name}
                  </WinnerInfo>
                </WinnerItem>
              )}
              {classResults.third && Array.isArray(classResults.third) && classResults.third
                .filter(racer => racer && racer.name && racer.name !== 'Bye')
                .slice(0, 2)
                .map((thirdPlace, index) => (
                  <WinnerItem key={index}>
                    <TrophyIcon place={3} theme={theme} />
                    <WinnerInfo>
                      3rd Place: {thirdPlace.name}
                    </WinnerInfo>
                  </WinnerItem>
                ))}
            </ClassResults>
          ))}
        </ResultsContainer>
      </Container>
    </PageContainer>
  );
};

export default EventResults;
