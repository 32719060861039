import React from 'react';
import styled from 'styled-components';
import { Container, Header, Button } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';
import { FaChartBar, FaUsers, FaRandom, FaTrophy, FaMobileAlt, FaClock } from 'react-icons/fa';
import Navigation from './Navigation';

const StyledButton = styled(Button)`
  margin-top: auto;
`;

const PageContainer = styled.div`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  min-height: 100vh;
  padding-top: 80px;
`;

const FeaturesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const FeatureCard = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.primary};
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: ${props => props.theme.text};
`;

const FeatureDescription = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 1rem;
  line-height: 1.6;
`;

const SEOSection = styled.section`
  margin-top: 4rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  padding: 3rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const SEOHeader = styled.h2`
  font-size: 2.5rem;
  color: ${props => props.theme.text};
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SEODescription = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  text-align: center;
`;

const FeaturesTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 2rem;
`;

function Features() {
  const { theme } = useTheme();
  
  // Check if theme is undefined
  if (!theme) {
    console.error('Theme is undefined in Features component');
    return <div>Loading...</div>; // Or some error message
  }

  const features = [
    {
      icon: <FaChartBar />,
      title: 'Advanced Event Management',
      description: 'Create and manage racing events effortlessly with RaceGrid Master, including custom classes and categories.'
    },
    {
      icon: <FaUsers />,
      title: 'Streamlined Racer Registration',
      description: 'Simplify the registration process for racers and teams with our user-friendly interface, designed for no prep racing events.'
    },
    {
      icon: <FaRandom />,
      title: 'Fair Chip Drawing',
      description: 'Ensure a fair and transparent chip drawing process for race pairings, a crucial aspect of no prep racing.'
    },
    {
      icon: <FaTrophy />,
      title: 'Real-time Results Tracking',
      description: 'Track and display race results and brackets in real-time, keeping racers and fans engaged throughout the event.'
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile-Friendly Design',
      description: 'Access RaceGrid Master on any device with our responsive design, perfect for on-the-go race management.'
    },
    {
      icon: <FaClock />,
      title: 'Time-Saving Automation',
      description: 'Automate repetitive tasks to save time and reduce errors in event management, allowing you to focus on the racing action.'
    }
  ];

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <FeaturesContainer>
        <FeaturesTitle theme={theme}>Our Features</FeaturesTitle>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index} theme={theme}>
              <FeatureIcon theme={theme}>{feature.icon}</FeatureIcon>
              <FeatureTitle theme={theme}>{feature.title}</FeatureTitle>
              <FeatureDescription theme={theme}>{feature.description}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
        <SEOSection theme={theme}>
          <SEOHeader theme={theme}>Revolutionize Your No Prep Racing Events</SEOHeader>
          <SEODescription theme={theme}>
            RaceGrid Master is the ultimate tool for no prep race organizers, participants, and fans. Our comprehensive platform streamlines event management, ensures fair competition through advanced chip drawing, and enhances the overall racing experience with real-time results tracking and mobile accessibility.
          </SEODescription>
        </SEOSection>
      </FeaturesContainer>
    </PageContainer>
  );
}

export default Features;