import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import { PageContainer, Container, Header } from './SharedStyles';

const StreamContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
`;

const StreamIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const Message = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.2rem;
  color: ${props => props.theme.text};
  width: 80%;
`;

function LiveStreamViewer() {
  const { theme } = useTheme();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const streamUrl = params.get('url');

  return (
    <PageContainer>
      <Container>
        <Header theme={theme}>Live Stream Viewer</Header>
        <StreamContainer theme={theme}>
          {streamUrl ? (
            <StreamIframe 
              src={streamUrl} 
              allowFullScreen
            />
          ) : (
            <Message theme={theme}>
              No stream URL provided. Please access this page through a valid stream link.
            </Message>
          )}
        </StreamContainer>
      </Container>
    </PageContainer>
  );
}

export default LiveStreamViewer;