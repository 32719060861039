import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: "https://no-prep-chip-draw-default-rtdb.firebaseio.com"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const getAuth = async () => {
  const { getAuth } = await import('firebase/auth');
  return getAuth(app);
};

export const getFirestore = async () => {
  const { getFirestore } = await import('firebase/firestore');
  return getFirestore(app);
};

export const getGoogleAuthProvider = async () => {
  const { GoogleAuthProvider } = await import('firebase/auth');
  return new GoogleAuthProvider();
};

export default app;

export { database };
