import React, { useState } from 'react';
import { getAuth, getFirestore, getGoogleAuthProvider } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const SignUpForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #1a73e8;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1557b0;
  }
`;

const ErrorMessage = styled.p`
  color: #d93025;
  margin-bottom: 1rem;
`;

const LoginLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #1a73e8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const GoogleButton = styled(Button)`
  background-color: white;
  color: #757575;
  border: 1px solid #dadce0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  &:hover {
    background-color: #f8f9fa;
  }
`;

const GoogleIcon = styled(FcGoogle)`
  margin-right: 10px;
  font-size: 18px;
`;

const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #757575;
`;

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Add this line
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const auth = await getAuth();
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await createUserDocument(result.user);
      navigate('/');
    } catch (error) {
      setError(getErrorMessage(error));
      console.error('Sign up error:', error);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const auth = await getAuth();
      const googleProvider = await getGoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      await createUserDocument(result.user);
      navigate('/');
    } catch (error) {
      setError(getErrorMessage(error));
      console.error('Google Sign-Up error:', error);
    }
  };

  const createUserDocument = async (user) => {
    const db = await getFirestore();
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      role: 'freeTrial',
      createdAt: new Date(),
    });
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please try logging in or use a different email.';
      case 'auth/invalid-email':
        return 'Invalid email address. Please check and try again.';
      case 'auth/weak-password':
        return 'Password is too weak. Please use a stronger password.';
      case 'auth/popup-closed-by-user':
        return 'Google Sign-Up was cancelled. Please try again.';
      default:
        return 'An error occurred. Please try again later.';
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SignUpContainer>
      <SignUpForm onSubmit={handleSignUp}>
        <Title>Sign Up</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <PasswordInputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <PasswordToggle type="button" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </PasswordToggle>
        </PasswordInputWrapper>
        <Button type="submit">Sign Up</Button>
        <GoogleButton type="button" onClick={handleGoogleSignUp}>
          <GoogleIcon />
          Sign up with Google
        </GoogleButton>
        <LoginLink to="/login">Already have an account? Log in</LoginLink>
      </SignUpForm>
    </SignUpContainer>
  );
}

export default SignUp;