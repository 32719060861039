import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Input, Button } from './SharedStyles';

const LookupContainer = styled.div`
  margin: 1rem 0;
`;

const EntryNumberLookup = ({ racers, onLookup }) => {
  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    const racer = racers.find(r => r.entryNumber === searchTerm);
    onLookup(racer);
  };

  return (
    <LookupContainer>
      <Input
        type="text"
        placeholder="Lookup Entry Number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        theme={theme}
      />
      <Button onClick={handleSearch} theme={theme}>Lookup</Button>
    </LookupContainer>
  );
};

export default EntryNumberLookup;
