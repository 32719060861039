import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { Select } from './SharedStyles';

const DropdownContainer = styled.div`
  margin: 1rem 0;
`;

const IndexUpdateDropdown = ({ indexes, onUpdate }) => {
  const { theme } = useTheme();

  return (
    <DropdownContainer>
      <Select onChange={(e) => onUpdate(e.target.value)} theme={theme}>
        <option value="">Select Index</option>
        {indexes.map(index => (
          <option key={index} value={index}>
            {index}
          </option>
        ))}
      </Select>
    </DropdownContainer>
  );
};

export default IndexUpdateDropdown;
