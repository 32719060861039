import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageContainer, Container, Header, Button, Input, List, ListItem } from './SharedStyles';
import Navigation from './Navigation';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import { FaUsers, FaEdit, FaTrash, FaCar, FaList, FaDatabase, FaPlus, FaChartLine } from 'react-icons/fa';

const FormSection = styled.div`
  margin-bottom: 1rem;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ActionButton = styled(Button)`
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.3rem;
  }
`;

const TeamItem = styled(ListItem)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  border-bottom: 1px solid ${props => props.theme.border};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TeamInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const TeamInfoItem = styled.span`
  font-size: 0.9em;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const NavigateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const DatabaseButton = styled(Button)`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.background};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

function TeamManagement() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [team, setTeam] = useState({ name: '', description: '' });
  const [teams, setTeams] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const [globalTeams, setGlobalTeams] = useState([]);
  const [showTeamDatabase, setShowTeamDatabase] = useState(false);
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    const storedTeams = JSON.parse(localStorage.getItem(`teams_${eventId}`) || '[]');
    setTeams(storedTeams);

    const storedGlobalTeams = JSON.parse(localStorage.getItem(`globalTeams_${currentUser.uid}`) || '[]');
    setGlobalTeams(storedGlobalTeams);

    // Fetch the event type
    const events = JSON.parse(localStorage.getItem('events') || '[]');
    const currentEvent = events.find(event => event.id === parseInt(eventId));
    if (currentEvent) {
      setEventType(currentEvent.type);
    }
  }, [eventId, currentUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedTeams;
    const newTeam = { ...team, id: Date.now() };
    if (editingIndex !== null) {
      updatedTeams = [...teams];
      updatedTeams[editingIndex] = newTeam;
    } else {
      updatedTeams = [...teams, newTeam];
    }
    setTeams(updatedTeams);
    localStorage.setItem(`teams_${eventId}`, JSON.stringify(updatedTeams));
    
    // Add or update team in the user-specific global database
    const updatedGlobalTeams = [...globalTeams];
    const existingIndex = updatedGlobalTeams.findIndex(t => t.name === newTeam.name);
    if (existingIndex !== -1) {
      updatedGlobalTeams[existingIndex] = newTeam;
    } else {
      updatedGlobalTeams.push(newTeam);
    }
    setGlobalTeams(updatedGlobalTeams);
    localStorage.setItem(`globalTeams_${currentUser.uid}`, JSON.stringify(updatedGlobalTeams));

    setTeam({ name: '', description: '' });
    setEditingIndex(null);
  };

  const addTeamFromDatabase = (selectedTeam) => {
    const newTeam = { ...selectedTeam, id: Date.now() };
    const updatedTeams = [...teams, newTeam];
    setTeams(updatedTeams);
    localStorage.setItem(`teams_${eventId}`, JSON.stringify(updatedTeams));
  };

  const handleEdit = (index) => {
    setTeam(teams[index]);
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    const updatedTeams = teams.filter((_, i) => i !== index);
    setTeams(updatedTeams);
    localStorage.setItem(`teams_${eventId}`, JSON.stringify(updatedTeams));
  };

  const saveCurrentTeamsToDatabase = () => {
    const updatedGlobalTeams = [...globalTeams];
    teams.forEach(team => {
      const existingIndex = updatedGlobalTeams.findIndex(t => t.name === team.name);
      if (existingIndex !== -1) {
        updatedGlobalTeams[existingIndex] = team;
      } else {
        updatedGlobalTeams.push(team);
      }
    });
    setGlobalTeams(updatedGlobalTeams);
    localStorage.setItem(`globalTeams_${currentUser.uid}`, JSON.stringify(updatedGlobalTeams));
    alert('Current teams saved to your database');
  };

  const deleteTeamFromDatabase = (teamId) => {
    const updatedGlobalTeams = globalTeams.filter(t => t.id !== teamId);
    setGlobalTeams(updatedGlobalTeams);
    localStorage.setItem(`globalTeams_${currentUser.uid}`, JSON.stringify(updatedGlobalTeams));
  };

  const navigateToNextStep = () => {
    if (eventType === 'No Prep') {
      navigate(`/events/${eventId}/classes`);
    } else {
      navigate(`/bracket-builder`, { 
        state: { 
          eventId, 
          initialStep: 'setup'  // This ensures we start at the setup tab
        } 
      });
    }
  };

  return (
    <PageContainer theme={theme}>
      <Navigation />
      <Container>
        <Header>Team Management</Header>
        <ButtonContainer>
          <DatabaseButton onClick={() => setShowTeamDatabase(true)} theme={theme}>
            <FaDatabase /> View Team Database
          </DatabaseButton>
          <DatabaseButton onClick={saveCurrentTeamsToDatabase} theme={theme}>
            <FaPlus /> Save Teams to Database
          </DatabaseButton>
        </ButtonContainer>
        <form onSubmit={handleSubmit}>
          <FormSection>
            <FormGrid>
              <Input
                type="text"
                value={team.name}
                onChange={(e) => setTeam({ ...team, name: e.target.value })}
                placeholder="Team Name"
                required
              />
              <Input
                type="text"
                value={team.description}
                onChange={(e) => setTeam({ ...team, description: e.target.value })}
                placeholder="Team Description"
              />
            </FormGrid>
          </FormSection>
          
          <SubmitButton type="submit" theme={theme}>
            <FaUsers />
            {editingIndex !== null ? 'Update Team' : 'Add Team'}
          </SubmitButton>
        </form>
        <List>
          {teams.map((t, index) => (
            <TeamItem key={t.id || index} theme={theme}>
              <TeamInfo>
                <TeamInfoItem><strong>{t.name}</strong></TeamInfoItem>
                <TeamInfoItem>{t.description}</TeamInfoItem>
              </TeamInfo>
              <ActionButtons>
                <ActionButton onClick={() => handleEdit(index)} theme={theme}>
                  <FaEdit /> Edit
                </ActionButton>
                <ActionButton onClick={() => handleDelete(index)} theme={theme}>
                  <FaTrash /> Delete
                </ActionButton>
              </ActionButtons>
            </TeamItem>
          ))}
        </List>
        <ButtonContainer>
          <NavigateButton onClick={navigateToNextStep} theme={theme}>
            {eventType === 'No Prep' ? (
              <>
                <FaList /> Go to Class Management
              </>
            ) : (
              <>
                <FaChartLine /> Go to Bracket Builder
              </>
            )}
          </NavigateButton>
        </ButtonContainer>
      </Container>
      {showTeamDatabase && (
        <>
          <Overlay onClick={() => setShowTeamDatabase(false)} />
          <Modal theme={theme}>
            <Header>Team Database</Header>
            <List>
              {globalTeams.map((t) => (
                <TeamItem key={t.id} theme={theme}>
                  <TeamInfo>
                    <TeamInfoItem><strong>Name:</strong> {t.name}</TeamInfoItem>
                    <TeamInfoItem><strong>Description:</strong> {t.description}</TeamInfoItem>
                  </TeamInfo>
                  <ActionButtons>
                    <ActionButton onClick={() => addTeamFromDatabase(t)} theme={theme}>
                      <FaPlus /> Add to Event
                    </ActionButton>
                    <ActionButton onClick={() => deleteTeamFromDatabase(t.id)} theme={theme}>
                      <FaTrash /> Remove from Database
                    </ActionButton>
                  </ActionButtons>
                </TeamItem>
              ))}
            </List>
            <ButtonContainer>
              <Button onClick={() => setShowTeamDatabase(false)} theme={theme}>Close Database</Button>
            </ButtonContainer>
          </Modal>
        </>
      )}
    </PageContainer>
  );
}

export default TeamManagement;