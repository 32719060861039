import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaClock, FaCalendarAlt, FaClipboardList, FaStickyNote, FaTimes } from 'react-icons/fa';
import { Button, TextArea } from './SharedStyles';
import { useTheme } from '../contexts/ThemeContext';

// Remove this line:
// axios.defaults.baseURL = 'http://localhost:5000';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.text};

  svg {
    margin-right: 0.5rem;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  font-size: 1rem;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ActionButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 180px; // Set a fixed width

    &:hover {
      background-color: ${props => props.theme.buttonBackgroundHover};
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

function CreateSchedule({ eventId, onCreateSuccess, onCancel }) {
  const [raceName, setRaceName] = useState("");
  const [roundType, setRoundType] = useState("Qualifying");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [events, setEvents] = useState([]);
  const { theme } = useTheme();

  useEffect(() => {
    // Load events from localStorage
    const storedEvents = JSON.parse(localStorage.getItem('events') || '[]');
    setEvents(storedEvents);
    
    // Set the initial raceName to the current event if it exists
    const currentEvent = storedEvents.find(event => event.id.toString() === eventId);
    if (currentEvent) {
      setRaceName(currentEvent.name);
    }
  }, [eventId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const newScheduleItem = {
        id: Date.now(),
        eventId,
        raceName,
        roundType,
        startTime,
        endTime,
        notes
      };

      onCreateSuccess(newScheduleItem);
      setSuccess("Schedule item created successfully!");
      
      // Clear form fields
      setRoundType("Qualifying");
      setStartTime("");
      setEndTime("");
      setNotes("");
      
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error) {
      console.error('Error creating schedule item:', error);
      setError(`Failed to create schedule item: ${error.message}`);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {success && <SuccessMessage>{success}</SuccessMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <InputGroup>
        <Label><FaCalendarAlt /> Race Name</Label>
        <Select
          value={raceName}
          onChange={(e) => setRaceName(e.target.value)}
          required
        >
          <option value="">Select a race</option>
          {events.map((event) => (
            <option key={event.id} value={event.name}>{event.name}</option>
          ))}
        </Select>
      </InputGroup>

      <InputGroup>
        <Label><FaClipboardList /> Round Type</Label>
        <Select value={roundType} onChange={(e) => setRoundType(e.target.value)}>
          <option value="Qualifying">Qualifying</option>
          <option value="Elimination">Elimination</option>
          <option value="Break">Break</option>
          <option value="Special Event">Special Event</option>
        </Select>
      </InputGroup>

      <InputGroup>
        <Label><FaClock /> Start Time</Label>
        <Input
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          required
        />
      </InputGroup>

      <InputGroup>
        <Label><FaClock /> End Time</Label>
        <Input
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          required
        />
      </InputGroup>

      <InputGroup>
        <Label><FaStickyNote /> Notes</Label>
        <TextArea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter any additional notes"
        />
      </InputGroup>

      <ButtonContainer>
        <ActionButton type="submit" theme={theme}>
          <FaPlus /> Create Item
        </ActionButton>
        <ActionButton type="button" onClick={onCancel} theme={theme}>
          <FaTimes /> Cancel
        </ActionButton>
      </ButtonContainer>
    </Form>
  );
}

export default CreateSchedule;