import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { PageContainer, Header, Button, Select } from './SharedStyles';

const LadderContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormSection = styled.div`
  margin-bottom: 1.5rem;
`;

const FormLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.textSecondary};
`;

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.inputBackground};
  color: black; // This ensures text is always black
  margin-bottom: 1rem;

  /* Ensure the text color of options is also black */
  option {
    color: black;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
  background-color: ${props => props.theme.primary};
  color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.primaryDark};
  }
`;

const ResetButton = styled(Button)`
  width: 100%;
  padding: 0.75rem;I
  font-size: 1rem;
  border-radius: 8px;
  background-color: ${props => props.theme.danger};
  color: white;
  transition: background-color 0.3s;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme.dangerDark};
  }
`;

const LadderDisplay = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Round = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const Match = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const LadderManager = () => {
  const { theme } = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ladderType, setLadderType] = useState('Sportsman');
  const [entryCount, setEntryCount] = useState(0);
  const [ladder, setLadder] = useState(null);

  const generateLadder = (type, count) => {
    const rounds = Math.log2(count);
    const ladder = Array.from({ length: rounds }, (_, round) => ({
      round: round + 1,
      matches: Array.from({ length: count / Math.pow(2, round + 1) }, (_, match) => ({
        match: match + 1,
        racers: [`Racer ${match * 2 + 1}`, `Racer ${match * 2 + 2}`],
      })),
    }));
    return ladder;
  };

  const handleGenerateLadder = () => {
    if (entryCount > 0) {
      const newLadder = generateLadder(ladderType, entryCount);
      setLadder(newLadder);
    }
  };

  const handleResetLadder = () => {
    setLadder(null);
    setSelectedCategory(null);
    setLadderType('Sportsman');
    setEntryCount(0);
  };

  return (
    <PageContainer theme={theme}>
      <LadderContainer>
        <Header>Ladder Manager</Header>
        <FormSection>
          <FormLabel htmlFor="category">Select Category</FormLabel>
          <StyledSelect
            id="category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            theme={theme}
          >
            <option value="" disabled>Select Category</option>
            {/* Map through categories to create options */}
            {/* Example: categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>) */}
          </StyledSelect>
        </FormSection>
        <FormSection>
          <FormLabel htmlFor="ladderType">Ladder Type</FormLabel>
          <StyledSelect
            id="ladderType"
            value={ladderType}
            onChange={(e) => setLadderType(e.target.value)}
            theme={theme}
          >
            <option value="Sportsman">Sportsman</option>
            <option value="Pro">Pro</option>
          </StyledSelect>
        </FormSection>
        <FormSection>
          <FormLabel htmlFor="entryCount">Entry Count</FormLabel>
          <StyledSelect
            id="entryCount"
            value={entryCount}
            onChange={(e) => setEntryCount(e.target.value)}
            theme={theme}
          >
            <option value="0" disabled>Select Entry Count</option>
            {[3, 4, 8, 16, 32, 64, 128].map(count => (
              <option key={count} value={count}>{count} Entries</option>
            ))}
          </StyledSelect>
        </FormSection>
        <StyledButton onClick={handleGenerateLadder} theme={theme}>Generate Ladder</StyledButton>
        {ladder && (
          <>
            <LadderDisplay>
              {ladder.map(round => (
                <Round key={round.round}>
                  <h3>Round {round.round}</h3>
                  {round.matches.map(match => (
                    <Match key={match.match}>
                      <span>{match.racers[0]}</span>
                      <span>vs</span>
                      <span>{match.racers[1]}</span>
                    </Match>
                  ))}
                </Round>
              ))}
            </LadderDisplay>
            <ResetButton onClick={handleResetLadder} theme={theme}>Reset Ladder</ResetButton>
          </>
        )}
      </LadderContainer>
    </PageContainer>
  );
};

export default LadderManager;
