import React from 'react';
import { useNavigate } from 'react-router-dom';

function Onboarding() {
  const navigate = useNavigate();

  const handleComplete = () => {
    // Save onboarding completion status
    navigate('/dashboard');
  };

  return (
    <div>
      <h1>Welcome to RaceGrid Master!</h1>
      {/* Add onboarding steps here */}
      <button onClick={handleComplete}>Complete Setup</button>
    </div>
  );
}

export default Onboarding;